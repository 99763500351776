import store from '@/store';
import {
  VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import {
  professionItems,
  jobListItems,
  jobListItemsSearch,
  professionStateFilter,
  FederalState, professionStateFilterCompany,
} from '@/api/Profession';
// eslint-disable-next-line import/named
import {
  CountType, ICountType, JobTags, JobType,
} from '@/types/ProfessionType';
import { jobListMap } from '@/api/Map';
import {
  createObjectFromAnString,
  createStringFromAnObject,
  updateObject,
} from '@/helpers/urlFilter';

type SelectedFederalState = Omit<FederalState, 'jobs'> & {
  jobs?: number
}

export interface IProfessionState {
  professions: any
  professionTitle: string
  jobs: any
  jobParams: any
  jobFilter: any
  jobPagination: any
  isJobs: boolean
  shortView: string
  jobId: number
  jobSearch: any
  jobType: JobType
  jobFirm: string
  searchJobProfession: any
  searchJobLocation: any
  filterFederalStateItems: FederalState[]
  selectedFederalStateItems: SelectedFederalState[]
  jobLocation: any
  searchTags: JobTags
  urlFilter: any
  employmentCount: ICountType
}

interface IJobs {
  amt: number | undefined
  firms?: []
  jobs: []
}

@Module({ dynamic: true, store, name: 'ProfessionModule' })
class Profession extends VuexModule implements IProfessionState {
  professions = {} as any;

  professionTitle = '';

  filterFederalStateItems: IProfessionState['filterFederalStateItems'] = [];

  filterFederalStateItemsCompany: IProfessionState['filterFederalStateItems'] = [];

  employmentCount = {
    fulltime: null,
    parttime: null,
    praktikum: null,
    edujob: null,
    skill_level2: null,
    skill_level3: null,
  }

  jobs:IJobs = {
    amt: undefined,
    firms: [],
    jobs: [],
  } as any;

  jobParams = {
    id: 0,
    jobnews_type: 1,
  } as any;

  jobFilter = {
    dejob: 0,
    recruit: 0,
    tmpjob: 0,
    workjob: 0,
    edujob: 0,
    praktikum: 0,
    fulltime: 0,
    parttime: 0,
    minijob: 0,
    seasonal: 0,
    holiday: 0,
    time_factor: 0,
    specialjob: 0,
    disability_friendly: 0,
    skill_level1: 0,
    skill_level2: 0,
    skill_level3: 0,
    partner_jobs_only: 0,
    other_jobs: 0,
    state_id: 0,
    cluster_id: 0,
  } as any;

  jobPagination = {
    per_page: 20,
    page: 1,
  } as any;

  jobSort = {
    sort: 0,
  } as any;

  jobSearch = {
    profession_id: null,
    location_id: null,
    idcomp: null,
    radius: 0,
    jobnews_type: null,
    fulltext: 0,
    q: '',
    mode: 'AND',
    qmode: 1,
  } as any;

  isJobs = false;

  jobId = 0;

  jobFirm = ''

  jobLocation = ''

  shortView = '';

  jobType = JobType.items;

  searchJobProfession: IProfessionState['searchJobProfession'] = null;

  searchJobLocation: IProfessionState['searchJobLocation'] = null;

  searchJobProfessionTitle: IProfessionState['searchJobProfession'] = null;

  searchJobLocationTitle: IProfessionState['searchJobLocation'] = null;

  selectedFederalStateItems: IProfessionState['selectedFederalStateItems'] = [];

  searchTags = {
    textTag: null,
    fullTimeTag: false,
    partTimeTag: false,
    practiceTag: false,
    skillLevel2Tag: false,
    skillLevel3Tag: false,
    clusterTag: null,
  } as JobTags

  urlFilter = {} as any;

  private lastRequestTime = 0;

  @Mutation
  SET_PARAM_TO_URL() {
    const filteredObject = {
      ...this.jobParams,
      ...this.jobFilter,
      ...this.jobPagination,
      ...this.jobSort,
      ...this.jobSearch,
      ...{ profession_name: this.searchJobProfessionTitle?.map((v: any) => v.title).join(', ') || '' },
      ...{ state_name: this.selectedFederalStateItems?.map((v) => v.location_name).join(', ') || '' },
      ...{ location_name: this.searchJobLocationTitle?.name || '' },
      // ...{ text_name: this.searchTags.textTag },
      // ...{ full_time_name: this.searchTags.fullTimeTag },
      // ...{ part_time_name: this.searchTags.partTimeTag },
      // ...{ practice_name: this.searchTags.practiceTag },
    } as any;
    this.urlFilter = createStringFromAnObject(filteredObject);
  }

  @Mutation
  SET_URL_TO_PARAMS(payload: string) {
    const resultObject: any = createObjectFromAnString(payload);
    this.jobParams = updateObject(this.jobParams, resultObject);
    this.jobFilter = updateObject(this.jobFilter, resultObject);
    this.jobPagination = updateObject(this.jobPagination, resultObject);
    this.jobSort = updateObject(this.jobSort, resultObject);
    this.jobSearch = updateObject(this.jobSearch, resultObject);
    if (resultObject.profession_id) {
      const ids = resultObject.profession_id.split(',').map(Number);
      const names = resultObject.profession_name.split(',');

      const result = ids.map((id: number, index: number) => ({
        id,
        name: names[index],
        title: names[index],
      }));
      this.searchJobProfession = result;
      this.searchJobProfessionTitle = result;
    }
    if (resultObject.state_id) {
      const ids = resultObject.state_id.split(',');
      const names = resultObject.state_name?.split(', ');

      const result = ids.map((id: string, index: number) => ({
        id,
        location_name: names[index],
      }));

      this.selectedFederalStateItems = result;
    }
    if (resultObject.location_id) {
      const ids = resultObject.location_id;
      const names = resultObject.location_name;

      const result = {
        id: ids,
        name: names,
      };
      this.searchJobLocation = result;
      this.searchJobLocationTitle = result;
    }
    // if (resultObject.state_id) {
    //   const ids = resultObject.state_id;
    //   const names = resultObject.state_name;
    //
    //   const result = {
    //     id: ids,
    //     location_name: names,
    //   };
    //   this.searchTags.stateTag = result;
    // }
  }

  @Mutation
  SET_TAGS_DEFAULT() {
    Object.keys(this.searchTags).forEach((key) => {
      this.searchTags[key as keyof JobTags] = null;
    });
  }

  @Mutation
  UPDATE_FILTER_TAGS(payload: {tag: keyof JobTags, value: any}) {
    this.searchTags[payload.tag] = payload.value;
  }

  @Mutation
  UPDATE_FILTER_FEDERAL_STATE(payload: FederalState[]) {
    this.filterFederalStateItems = payload;
  }

  @Mutation
  UPDATE_FILTER_FEDERAL_STATE_COMPANY(payload: FederalState[]) {
    this.filterFederalStateItemsCompany = payload;
  }

  @Mutation
  SET_SELECTED_FEDERAL_STATE(payload: IProfessionState['selectedFederalStateItems']) {
    this.selectedFederalStateItems = payload;
  }

  @Mutation
  SET_SEARCH_JOB_PROFESSION(payload: any) {
    this.searchJobProfession = payload;
  }

  @Mutation
  SET_SEARCH_JOB_PROFESSION_TITLE(payload: any) {
    this.searchJobProfessionTitle = payload;
  }

  @Mutation
  SET_SEARCH_JOB_LOCATION(payload: any) {
    this.searchJobLocation = payload;
  }

  @Mutation
  SET_SEARCH_JOB_LOCATION_TITLE(payload: any) {
    this.searchJobLocationTitle = payload;
  }

  @Mutation
  UPDATE_JOB_SHORT(payload: string) {
    this.shortView = payload;
  }

  @Mutation
  UPDATE_PROFESSION_TITLE(payload: string) {
    this.professionTitle = payload;
  }

  @Mutation
  UPDATE_JOB_TYPE(payload: any) {
    this.jobType = payload;
  }

  @Mutation
  UPDATE_JOB_ID(payload: number) {
    this.jobId = payload;
  }

  @Mutation
  UPDATE_JOB_FIRM(payload: string) {
    this.jobFirm = payload;
  }

  @Mutation
  UPDATE_JOB_LOCATION(payload: string) {
    this.jobLocation = payload;
  }

  @Mutation
  UPDATE_SHOW_JOBS(payload: boolean) {
    this.isJobs = payload;
  }

  @Mutation
  UPDATE_PAGINATION(payload: any) {
    const { obj, value } = payload;
    this.jobPagination[obj] = value;
  }

  @Mutation
  UPDATE_SEARCH(payload: any) {
    const { obj, value } = payload;
    this.jobSearch[obj] = value;
  }

  @Mutation
  UPDATE_FILTER(payload: any) {
    const { obj, value } = payload;
    this.jobFilter[obj] = value;
  }

  @Mutation
  UPDATE_SORT(payload: any) {
    const { obj, value } = payload;
    this.jobSort[obj] = value;
  }

  @Mutation
  UPDATE_PROFESSION(payload: any) {
    this.professions = payload;
  }

  @Mutation
  UPDATE_JOBS(payload: any) {
    this.jobs = payload;
  }

  @Mutation
  UPDATE_PROFESSION_ID(payload: any) {
    this.jobParams.id = payload;
  }

  @Mutation
  SET_FILTER_DEFAULT() {
    Object.keys(this.jobFilter).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(this.jobFilter, key)) {
        this.jobFilter[key] = 0;
      }
    });
  }

  @Mutation
  SET_PARAMS_DEFAULT() {
    this.jobPagination.page = 1;
    this.jobPagination.per_page = 20;
    this.jobSort.sort = 0;
    this.jobParams.id = 0;
    this.jobParams.jobnews_type = 1;
  }

  @Mutation
  SET_PAGINATION_DEFAULT() {
    this.jobPagination.page = 1;
    this.jobPagination.per_page = 20;
  }

  @Mutation
  SET_SEARCH_DEFAULT() {
    this.jobSearch.radius = 0;
    this.jobSearch.jobnews_type = null;
    this.jobSearch.fulltext = 0;
    this.jobSearch.q = '';
    this.jobSearch.mode = 'AND';
    this.jobSearch.qmode = 1;
  }

  @Mutation
  SET_QUERY_SEARCH_DEFAULT() {
    this.selectedFederalStateItems = [];
    this.searchJobProfession = null;
    this.searchJobLocation = null;
    this.jobSearch.profession_id = null;
    this.jobSearch.location_id = null;
    this.jobSearch.idcomp = null;
  }

  @Mutation
  UPDATE_JOBS_COUNT(payload: { filter: CountType, count: any }) {
    const { filter, count } = payload;
    this.employmentCount[filter] = count;
  }

  @Mutation
  UPDATE_TIME(payload: any) {
    this.lastRequestTime = payload;
  }

  @Action
  async SetSearchJobProfession(payload: any) {
    this.SET_SEARCH_JOB_PROFESSION(payload);
  }

  @Action
  async SetSearchJobProfessionTitle(payload: any) {
    this.SET_SEARCH_JOB_PROFESSION_TITLE(payload);
    // last element
    this.SET_PARAM_TO_URL();
  }

  @Action
  async SetSearchJobLocation(payload: any) {
    this.SET_SEARCH_JOB_LOCATION(payload);
  }

  @Action
  async SetSearchJobLocationTitle(payload: any) {
    this.SET_SEARCH_JOB_LOCATION_TITLE(payload);
  }

  @Action({ rawError: true })
  async GetProfession(payload: any) {
    const request = await professionItems(payload);
    this.UPDATE_PROFESSION(request);
  }

  @Action({ rawError: true })
  async GetJobs(payload: any) {
    const params = {
      ...payload,
      ...this.jobSort,
      ...this.jobParams,
      ...this.jobFilter,
      ...this.jobPagination,
    };
    const request = await jobListItems(params);
    this.UPDATE_JOBS(request);
  }

  @Action({ rawError: true })
  async GetJobsSearch(payload: any) {
    const currentRequestTime = Date.now();

    const params = {
      ...payload,
      ...this.jobSort,
      ...this.jobSearch,
      ...this.jobParams,
      ...this.jobFilter,
      ...this.jobPagination,
      skill_level1: this.jobFilter.skill_level2,
    };
    const request = await jobListItemsSearch(params);

    if (currentRequestTime >= this.lastRequestTime) {
      this.UPDATE_TIME(currentRequestTime);
      this.UPDATE_JOBS(request);
    }
  }

  @Action({ rawError: true })
  async GetJobsMap(payload: any) {
    const params = {
      ...payload.params,
      ...this.jobSort,
      ...this.jobSearch,
      ...this.jobParams,
      ...this.jobFilter,
      ...this.jobPagination,
    };
    const request = await jobListMap(params, {
      job_ids: payload.ids,
    });
    this.UPDATE_JOBS(request);
  }

  @Action({ rawError: true })
  async GetJobsCount(payload: { filter: CountType, params: any }) {
    const { filter, params } = payload;
    const marget = {
      ...params,
      ...this.jobSearch,
      state_id: this.jobFilter.state_id,
      cluster_id: this.jobFilter.cluster_id,
    };
    const request = await jobListItemsSearch(marget);
    this.UPDATE_JOBS_COUNT({ filter, count: request.amt || 0 });
  }

  @Action
  async SetProfessionId(payload: any) {
    this.UPDATE_PROFESSION_ID(payload);
  }

  @Action
  async SetPagination(payload: any) {
    this.UPDATE_PAGINATION(payload);
    // last element
    this.SET_PARAM_TO_URL();
  }

  @Action
  async SetSort(payload: any) {
    this.UPDATE_SORT(payload);
  }

  @Action
  async SetVisibleJobs(payload: boolean) {
    this.UPDATE_SHOW_JOBS(payload);
  }

  @Action
  async SetJobId(payload: number) {
    this.UPDATE_JOB_ID(payload);
  }

  @Action
  async SetJobFirm(payload: string) {
    this.UPDATE_JOB_FIRM(payload);
  }

  @Action
  async SetJobLocation(payload: string) {
    this.UPDATE_JOB_LOCATION(payload);
  }

  @Action
  async SetJobShort(payload: string) {
    this.UPDATE_JOB_SHORT(payload);
  }

  @Action
  async SetSearch(payload: any) {
    this.UPDATE_SEARCH(payload);
    // last element
    this.SET_PARAM_TO_URL();
  }

  @Action
  async SetFilter(payload: { obj: string, value: any }) {
    this.UPDATE_FILTER(payload);
    // last element
    this.SET_PARAM_TO_URL();
  }

  @Action
  async SetJobType(payload: any) {
    this.UPDATE_JOBS({});
    this.UPDATE_JOB_TYPE(payload);
  }

  @Action
  async SetProfessionTitle(payload: string) {
    this.UPDATE_PROFESSION_TITLE(payload);
  }

  @Action
  async SetSelectedFederalStates(payload: IProfessionState['selectedFederalStateItems']) {
    this.SET_SELECTED_FEDERAL_STATE(payload);

    const idsString = payload.map((el) => el.id).join(',') || 0;

    this.SetFilter({ obj: 'state_id', value: idsString });
  }

  @Action({ rawError: true })
  async GetFederalStateItems(payload: { sid: string }) {
    const params = {
      sid: payload.sid,
    };
    const request = await professionStateFilter(params);
    this.UPDATE_FILTER_FEDERAL_STATE(request);
  }

  @Action({ rawError: true })
  async GetFederalStateItemsCompany(payload: { sid: string }) {
    const params = {
      sid: payload.sid,
    };
    const request = await professionStateFilterCompany(params);
    this.UPDATE_FILTER_FEDERAL_STATE_COMPANY(request);
  }

  @Action
  async SetParamsDefault() {
    this.SET_FILTER_DEFAULT();
    this.SET_SEARCH_DEFAULT();
    this.SET_PARAMS_DEFAULT();
    this.SET_QUERY_SEARCH_DEFAULT();

    // last element
    this.SET_PARAM_TO_URL();
  }

  @Action
  async SetSearchTags(payload: {tag: keyof JobTags, value: any}) {
    this.UPDATE_FILTER_TAGS(payload);
  }

  @Action
  async SetTagsDefault() {
    this.SET_TAGS_DEFAULT();
  }

  @Action
  SetUrlToParams(payload: string) {
    this.SET_URL_TO_PARAMS(payload);
  }
}

export const ProfessionModule = getModule(Profession);
