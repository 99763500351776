<template>
  <b-card no-body class="position-relative bg-white mb-2 hover">
    <b-card-body class="px-2 pt-2 pb-1">
      <b-row>
        <b-col>
          <b-row align-v="center" no-gutters class="gx-2 gy-0 m-0">
            <b-col>
              <p class="lh-sm m-0">
                <slot name="title" v-bind="{ title }">
                  <span class="fw-bold link-primary stretched-link">
                    {{ title }}
                  </span>
                </slot>
              </p>
            </b-col>
          </b-row>
          <b-row align-v="center" no-gutters class="gx-2 gy-0 m-0">
            <b-col cols="auto">
              <span class="fs-6 fw-bold position-relative z-3">
                ID: {{ id }}
              </span>
            </b-col>
            <b-col v-if="description" cols="auto">
              <span class="position-relative z-3">
                <b-icon icon="geo-alt" class="fs-6 mr-1"></b-icon>
                <span class="fs-6">{{ description }}</span>
              </span>
            </b-col>
            <b-col v-if="date" cols="auto">
              <span class="position-relative z-3">
                <b-icon icon="clock-history" class="fs-6 mr-1"></b-icon>
                <span class="fs-6">{{ date }}</span>
              </span>
            </b-col>
          </b-row>
        </b-col>
        <div class="col-auto">
          <b-button @click="deleteNotepad" variant="primary" size="sm" class="position-relative z-3" v-b-tooltip.hover :title="translations[2837]">
            <b-icon icon="trash" scale="0.9"></b-icon>
          </b-button>
        </div>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NotebookItem extends Vue {
  @Prop({ default: '' }) private date!: string;

  @Prop({ default: '' }) private title!: string;

  @Prop({ default: '' }) private description!: string;

  // @Prop({ default: '' }) private descriptionCity!: string;

  // @Prop({ default: '' }) private letter!: string;

  @Prop({ default: 0 }) private id!: number;

  get translations() {
    return AppModule.translation;
  }

  async deleteNotepad() {
    this.$emit('delete');
  }
}
</script>
<style scoped lang="scss"></style>
