<template>
  <div class="ckeditor-widget">
    <ckeditor v-model="model" :config="editorConfig" />
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
// eslint-disable-next-line
import CKEditor from 'ckeditor4-vue';
import { AppModule } from '@/store/modules/app';

@Component({
  components: {
    ckeditor: CKEditor.component,
  },
})
export default class CreateCommunication extends Vue {
  @Prop({ default: 250 }) private height!: number;

  @Prop({ default: '' }) value!: string;

  get language() {
    return AppModule.language;
  }

  get model() {
    return this.value;
  }

  set model(val: string) {
    this.$emit('input', val);
  }

  editorConfig = {
    language: this.language,
    height: this.height,
    toolbarGroups: [
      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      {
        name: 'editing',
        groups: ['find', 'selection', 'spellchecker', 'editing'],
      },
      { name: 'clipboard', groups: ['clipboard', 'undo'] },
      {
        name: 'paragraph',
        groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'],
      },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
      { name: 'about', groups: ['about'] },
    ],
    removeButtons:
      'Save,Templates,NewPage,ExportPdf,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,SelectAll,Find,Replace,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Subscript,Superscript,Strike,CopyFormatting,Outdent,Indent,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Image,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,Font,FontSize,TextColor,BGColor,Maximize,ShowBlocks,About',
  };
  // editorConfig = {
  //   language: this.language,
  //   plugins: [GeneralHtmlSupport],
  // };
}
</script>
<style scoped lang="scss">
.ckeditor-widget[required='required'] {
  border: 1px solid #dc3545;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}
</style>
