import store from '@/store';
import {
  VuexModule, Module, getModule, Action, Mutation,
} from 'vuex-module-decorators';
import { jobClusterItems } from '@/api/Clusters';

export interface IClustersState {
  clusterJobs: {
    jobs: any[]
    total_count: number
  }
  jobClusterSort: {
    sort: string
    order: string
  }
  jobClusterFilter: {
    prof?: number | string
  }
  professionTitle: string

  urlIframe: string
}

const DEFAULT_PROFESSION_TITLE = '';

const DEFAULT_CLUSTER_JOBS = {
  jobs: [],
  total_count: 0,
};

const DEFAULT_PAGINATION = {
  per_page: 20,
  page: 1,
};

const DEFAULT_CLUSTER_PARAMS = {
  cluster_id: 0,
};

@Module({ dynamic: true, store, name: 'ClustersModule' })
class Clusters extends VuexModule implements IClustersState {
  professionTitle = DEFAULT_PROFESSION_TITLE;

  clusterJobs = { ...DEFAULT_CLUSTER_JOBS };

  jobClusterParams = { ...DEFAULT_CLUSTER_PARAMS } as any;

  jobClusterSort = {
    sort: 'lastdate', // "lastdate", "last_update"
    order: 'desc', // "asc", "desc"
  } as any;

  jobClusterFilter = {
    // prof: 0,
  }

  jobClusterPagination = { ...DEFAULT_PAGINATION } as any;

  urlIframe = ''

  @Mutation
  UPDATE_URL_IFRAME(payload: any) {
    this.urlIframe = payload;
  }

  @Mutation
  UPDATE_CLUSTER_SORT(payload: any) {
    const { obj, value } = payload;
    this.jobClusterSort[obj] = value;
  }

  @Mutation
  UPDATE_CLUSTER_PARAMS(payload: any) {
    const { obj, value } = payload;
    this.jobClusterParams[obj] = value;
  }

  @Mutation
  RESET_CLUSTER_PARAMS() {
    this.jobClusterParams = { ...DEFAULT_CLUSTER_PARAMS };
  }

  @Mutation
  UPDATE_CLUSTER_JOBS(payload: any) {
    this.clusterJobs = payload;
  }

  @Mutation
  RESET_CLUSTER_JOBS() {
    this.clusterJobs = { ...DEFAULT_CLUSTER_JOBS };
  }

  @Mutation
  UPDATE_PROFESSION_TITLE(payload: string) {
    this.professionTitle = payload;
  }

  @Mutation
  UPDATE_PAGINATION(payload: any) {
    const { obj, value } = payload;
    this.jobClusterPagination[obj] = value;
  }

  @Mutation
  RESET_PAGINATION() {
    this.jobClusterPagination = { ...DEFAULT_PAGINATION };
  }

  @Action
  async SetClusterSort(payload: any) {
    this.UPDATE_CLUSTER_SORT(payload);
  }

  @Action
  async SetClusterParams(payload: any) {
    this.UPDATE_CLUSTER_PARAMS(payload);
  }

  @Action({ rawError: true })
  async GetClusterJobs(payload: any) {
    const params = {
      ...payload,
      ...this.jobClusterParams,
      ...this.jobClusterSort,
      ...this.jobClusterFilter,
      ...this.jobClusterPagination,
    };
    const request = await jobClusterItems(params);
    this.UPDATE_CLUSTER_JOBS(request);
  }

  @Action
  async SetProfessionTitle(payload: string) {
    this.UPDATE_PROFESSION_TITLE(payload);
  }

  @Action
  async SetPagination(payload: any) {
    this.UPDATE_PAGINATION(payload);
  }

  @Action
  async SetUrlIframe(payload: any) {
    this.UPDATE_URL_IFRAME(payload);
  }

  @Action
  async ResetClusterData() {
    this.RESET_CLUSTER_JOBS();
    this.RESET_CLUSTER_PARAMS();
    this.RESET_PAGINATION();
  }
}

export const ClustersModule = getModule(Clusters);
