<template>
  <b-modal id="not-auth-modal" :title="translations[13627]" hide-footer centered>
    <p class="mb-2">{{ translations[13628] }}</p>
    <p class="mb-2">{{ translations[13629] }}</p>
    <b-row>
      <b-col cols="auto" class="ml-auto">
        <b-button variant="primary" @click="authEvent" v-b-modal.login-modal>{{ translations[13716] }}</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';

@Component
export default class NotAuthModal extends Vue {
  get translations() {
    return AppModule.translation;
  }

  authEvent() {
    UserModule.IS_REDIRECT_TO_CABINET(false);
    this.$bvModal.hide('not-auth-modal');
  }
}
</script>
