<template>
  <div v-show="isPopupVisible" class="info-panel">
    <div class="container">
      <h3 class="info-panel-title h2 mb-3 mb-sm-4">{{ translations[14470] }}</h3>
      <p class="info-panel-text" v-html="translations[14471]" />
      <div class="d-sm-flex justify-content-end">
        <button type="button" class="btn btn-primary mb-lg-0 mr-lg-0 order-sm-1" @click="acceptCookies">{{ translations[14473] }}</button>
        <button type="button" class="btn btn-tertiary mr-sm-2 mb-lg-0 order-sm-0" @click="closeCookies">{{ translations[14472] }}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Cookies from 'js-cookie';
import { AppModule } from '@/store/modules/app';

const COOKIE_CLOSED_NAME = 'cookiesClosed';
const COOKIE_ACCEPTED_NAME = 'cookiesAccepted';

@Component
export default class CookieBanner extends Vue {
  isPopupVisible = false;

  get translations() {
    return AppModule.translation;
  }

  get sid() {
    return AppModule.sid;
  }

  /* eslint-disable */
  addGoogleTagManager() {
    console.log(8555);
    (function (w: any, d, s, l, i) {
      w.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
        'functionality_storage': 'granted',
        'personalization_storage': 'granted',
        'security_storage': 'granted',
      });

      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.setAttribute('async', 'true');
      j.setAttribute('src', `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`);
      f.parentNode!.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KPP2RFB');
  }
  /* eslint-enable */

  handleTagManagerAdding() {
    const availableSids = ['aba'];

    if (availableSids.includes(this.sid)) {
      this.addGoogleTagManager();
    }
  }

  closeCookies() {
    Cookies.set(COOKIE_CLOSED_NAME, '1', { expires: 364 });
    this.hidePopup();
  }

  acceptCookies() {
    this.closeCookies();
    this.setAcceptedCookie();
    this.handleTagManagerAdding();
  }

  created() {
    this.checkPopupVisibility();
    this.checkAcceptedCookies();
  }

  hidePopup() {
    this.isPopupVisible = false;
  }

  setAcceptedCookie() {
    Cookies.set(COOKIE_ACCEPTED_NAME, '1', { expires: 364 });
  }

  checkPopupVisibility() {
    this.isPopupVisible = !Cookies.get(COOKIE_CLOSED_NAME);
  }

  checkAcceptedCookies() {
    if (Cookies.get(COOKIE_ACCEPTED_NAME)) {
      this.handleTagManagerAdding();
    }
  }
}
</script>

<style lang="scss" scoped>
.info-panel {
  background: #fff;
  bottom: 3rem;
  box-shadow: 0 0.1875rem 0.4375rem 0 rgba(0, 0, 0, 0.08), 0 0 1px 0 rgba(0, 0, 0, 0.07);
  color: #36434a;
  left: 2.75rem;
  max-width: 100%;
  padding: 2rem 1.25rem;
  position: fixed;
  right: 0;
  width: 39.0625rem;
  border-radius: 0.25rem;
  z-index: 1100;
}

@media (max-width: 1279.98px) {
  .info-panel {
    bottom: 12px;
    left: 12px;
  }
}

@media (max-width: 767.98px) {
  .info-panel {
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 80%;
    padding: 1.25rem 0;
    overflow: auto;
  }
}

@media (max-width: 575.98px) {
  .info-panel {
    text-align: center;
    padding-bottom: 0.625rem;
  }
}

.info-panel-title {
  color: #4d606a;
}

@media (max-width: 575.98px) {
  .info-panel-title {
    margin-bottom: 0.75rem;
    font-size: 1.25rem;
  }
}

.info-panel-text {
  margin-bottom: 2.3125rem;
}

@media (max-width: 575.98px) {
  .info-panel-text {
    font-size: 0.85rem;
  }
}

@media (max-width: 575.98px) {
  .info-panel .btn {
    font-weight: 800;
    display: block;
    width: 100%;
    font-size: 0.85rem;
  }

  .info-panel .btn + .btn {
    margin-top: 0.5rem;
  }
}
</style>
