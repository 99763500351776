<template>
  <core-sidebar sidebar-class="w-75 w-lg-25" id="notebook-sidebar" right header-class="bg-aba-primary p-3" body-class="p-3" footer-class="px-3" shadow backdrop>
    <template #header="{ hide }">
      <b-row align-v="center" class="flex-grow-1">
        <b-col class="d-flex">
          <span class="fs-5 fw-bolder text-white">
            <b-icon icon="journal-bookmark" class="mr-2"></b-icon>
            {{ translations[10966] }}
          </span>
        </b-col>
        <b-col cols="auto" class="d-flex ml-auto">
          <b-button class="bg-transparent border-0 p-1" @click="hide()">
            <b-icon icon="x" font-scale="1.5"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <template #default>
      <div class="accordion" role="tablist">
        <b-card v-if="!isNotebookJobsHidden" no-body class="mb-0 border-0">
          <b-card-header header-tag="header" class="accordion-header p-0 fw-light border-0" role="tab">
            <b-button block v-b-toggle.accordion-notebook-1 variant="light"
                      class="accordion-button text-left d-flex align-items-center shadow-none rounded-bottom-0">
              <span class="fw-bolder">{{ translations[12604] }} </span> ({{ notebookJobs.length }})
              <b-icon icon="chevron-right" class="ml-auto icon-event" scale="0.8"></b-icon>
            </b-button>
          </b-card-header>
          <b-collapse class="border-0" id="accordion-notebook-1" visible accordion="notebook-accordion" role="tabpanel">
            <b-card-body class="fs-6 px-0 py-3">
              <notebook-item
                v-for="(item, index) in notebookJobs"
                :key="index"
                :date="formatDateEuropean(item.lastdate)"
                :id="item.id"
                :title="item.position"
                :description="item.location"
                @delete="deleteNotepadJob(item.id)"
                >
                <template #title="{ title }">
                  <span class="fw-bold link-primary stretched-link" v-b-toggle.jobs-sidebar @click="handleJobSidebar(item.id, item.company_name, item.shortview)">
                    {{ title }}
                  </span>
                </template>
              </notebook-item>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card v-if="!isNotebookWorkersHidden" no-body class="mb-0 border-0">
          <b-card-header header-tag="header" class="accordion-header p-0 fw-light border-0" role="tab">
            <b-button block v-b-toggle.accordion-notebook-2 variant="light"
                      class="accordion-button text-left d-flex align-items-center shadow-none rounded-0">
              <span class="fw-bolder">{{ translations[4741] }} </span> ({{ notebookWorkers.length }})
              <b-icon icon="chevron-right" class="ml-auto icon-event" scale="0.8"></b-icon>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-notebook-2" accordion="notebook-accordion" role="tabpanel">
            <b-card-body class="fs-6 px-0 py-3">
              <notebook-item
                v-for="(item, index) in notebookWorkers"
                :key="index"
                :date="formatDateEuropean(item.last_modified)"
                :id="item.id"
                :title="item.position"
                :description="item.locations"
                @delete="deleteNotepadWorker(item.id)"
              >
                <template #title="{ title }">
                  <span class="fw-bold link-primary stretched-link" v-b-toggle.applicant-sidebar @click="handleWorkerSidebar(item.id)">
                    {{ title }}
                  </span>
                </template>
              </notebook-item>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card v-if="!isNotebookCompanyHidden" no-body class="mb-0 border-0">
          <b-card-header header-tag="header" class="accordion-header p-0 fw-light border-0" role="tab">
            <b-button block v-b-toggle.accordion-notebook-3 variant="light"
                      class="accordion-button text-left d-flex align-items-center shadow-none rounded-top-0">
              <span class="fw-bolder">{{ translations[341] }} </span> ({{ notebookCompany.length }})
              <b-icon icon="chevron-right" class="ml-auto icon-event" scale="0.8"></b-icon>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-notebook-3" accordion="notebook-accordion" role="tabpanel">
            <b-card-body class="fs-6 px-0 py-3">
              <notebook-item
                v-for="(item, index) in notebookCompany"
                :key="index"
                :id="item.company_id"
                :date="formatDateEuropean(item.last_modified)"
                :title="item.name"
                @delete="deleteNotepadCompany(item.company_id)">
                <template #title="{ title }">
                  <span class="fw-bold link-primary stretched-link" v-b-toggle.company-sidebar @click="handleCompanySidebar(item.company_id, item.name)">
                    {{ title }}
                  </span>
                </template>
              </notebook-item>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>
    <template #footer>
      <b-row>
        <b-col cols="auto" class="ml-auto py-3">
          <b-button variant="primary" size="sm" class="position-relative z-3" @click="deleteAllNotice">
            <b-icon icon="trash" scale="0.9"></b-icon>
            <span class="ms-1" v-b-tooltip.hover :title="translations[13794]">
              {{ translations[13793] }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </core-sidebar>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';
import NotebookItem from '@/components/Notebook/NotebookItem.vue';
import { NotebookModule } from '@/store/modules/notebook';
import { UserModule } from '@/store/modules/user';
import { WorkersModule } from '@/store/modules/workers';
import { ProfessionModule } from '@/store/modules/profession';
import { CompanyModule } from '@/store/modules/company';

@Component({
  components: {
    NotebookItem,
  },
})
export default class NotebookSidebar extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get notebookJobs() {
    return NotebookModule.notebookJobs;
  }

  get isNotebookJobsHidden() {
    return UserModule.accessTokenCompany;
  }

  get notebookWorkers() {
    return NotebookModule.notebookWorkers;
  }

  get isNotebookWorkersHidden() {
    return UserModule.accessTokenApplicant;
  }

  get notebookCompany() {
    return NotebookModule.notebookCompany;
  }

  get isNotebookCompanyHidden() {
    return UserModule.accessTokenCompany;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  async deleteNotepadJob(id: number) {
    if (UserModule.accessTokenConsultant || UserModule.accessTokenApplicant) {
      await NotebookModule.toogleNotepadItem({ type: 'job', item_id: id, isMarked: true });
    }
  }

  async deleteNotepadCompany(id: number) {
    if (UserModule.accessTokenConsultant || UserModule.accessTokenApplicant) {
      await NotebookModule.toogleNotepadItem({ type: 'company', item_id: id, isMarked: true });
    }
  }

  async deleteNotepadWorker(id: number) {
    if (UserModule.accessTokenCompany || UserModule.accessTokenConsultant) {
      await NotebookModule.toogleNotepadItem({ type: 'applicant', item_id: id, isMarked: true });
    }
  }

  async deleteAllNotice() {
    let userType: 'applicant' | 'company' | 'consultant' | null = null;

    if (UserModule.accessTokenApplicant) {
      userType = 'applicant';
    } else if (UserModule.accessTokenCompany) {
      userType = 'company';
    } else if (UserModule.accessTokenConsultant) {
      userType = 'consultant';
    }

    if (userType) {
      await NotebookModule.DeleteAllNotebookNotes(userType);
    }
  }

  formatDateEuropean(targetDate: string): string {
    if (targetDate) {
      const parts = targetDate.split('-');
      return `${parts[2]}.${parts[1]}.${parts[0]}`;
    }
    return '';
  }

  handleWorkerSidebar(id: number) {
    WorkersModule.SET_SELECTED_WORKER(id);
  }

  handleJobSidebar(id: number, firmName: string, shortview: 1 | 0) {
    ProfessionModule.SetJobId(id);
    ProfessionModule.SetJobFirm(firmName);
    ProfessionModule.SetJobShort(shortview ? 'shortview' : '');
    CompanyModule.SetCompanyDetailFilter({ obj: 'idjob', value: id });
  }

  handleCompanySidebar(id: number, title: string) {
    CompanyModule.HandleCompanySidebar({ id, title });
  }
}
</script>
<style scoped lang="scss">
.icon-event {
  transition: all .2s ease-in;
}

.accordion-button {
  color: #4d6069!important;
  &.not-collapsed {
    .icon-event {
      transform: rotate(90deg);
    }
  }
}
</style>
