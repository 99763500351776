<template>
  <footer class="footer footer-theme position-relative">
    <b-container style="max-width: 1340px;">
      <div class="footer-top">
        <b-row>
          <b-col lg="3" md="4" sm="12" class="footer-column">
            <div class="footer-widget links-widget logo-widget">
              <div class="text" v-if="lang === 'fr'">
                <h4>Edunet GmbH</h4>
                <p>Luisenstr. 41 <br/>10117 Berlin</p>

                <p>
                  Directeur général:<br/>
                  Jeniffer Muvai<br/>
                  E-mail: <a href="mailto:jm@edunet.works">jm@edunet.works</a>
                </p>
                <p>
                  Registre du commerce:<br>
                  HRB 264074 B<br>
                  Tribunal de district Berlin Charlottenburg
                </p>
                <p>
                  Finanzamt für Körperschaften IV Berlin<br>
                  Numéro fiscal 30/271/51633<br>
                  Numéro de TVA: DE368496173
                </p>
                <p>
                  Coordonnées bancaires:<br>
                  Berliner Sparkasse<br>
                  IBAN DE29 1005 0000 0191 3505 59
                </p>
                <p>
                  <a :href="`${siteUrl}/${lang}/privacy-policy`">
                    Politique de confidentialité
                  </a>
                </p>
              </div>
              <div class="text" v-if="lang === 'en'">
                <h4>Edunet GmbH</h4>
                <p>Luisenstr. 41 <br/>10117 Berlin</p>

                <p>
                  Managing Director:<br/>
                  Jeniffer Muvai<br/>
                  E-mail: <a href="mailto:jm@edunet.works">jm@edunet.works</a>
                </p>
                <p>
                  Company Register:<br>
                  HRB 264074 B<br>
                  Amtsgericht Berlin Charlottenburg
                </p>
                <p>
                  Finanzamt für Körperschaften IV Berlin<br>
                  VAT ID DE368496173
                </p>
                <p>
                  <a :href="`${siteUrl}/${lang}/privacy-policy`">
                    Privacy policy
                  </a>
                </p>
              </div>
              <div class="text" v-if="lang === 'de'">
                <h4>Edunet GmbH</h4>
                <p>Luisenstr. 41 <br/>10117 Berlin</p>

                <p>
                  Geschäftsführerin:<br/>
                  Jeniffer Muvai<br/>
                  E-mail: <a href="mailto:jm@edunet.works">jm@edunet.works</a>
                </p>
                <p>
                  Handelsregister:<br>
                  HRB 264074 B<br>
                  Amtsgericht Berlin Charlottenburg
                </p>
                <p>
                  Finanzamt für Körperschaften IV Berlin<br>
                  Steuernummer 30/271/51633<br>
                  USt-IdNr. DE368496173
                </p>
                <p>
                  Bankverbindung:<br>
                  Berliner Sparkasse<br>
                  IBAN DE29 1005 0000 0191 3505 59
                </p>
                <p>
                  <a :href="`${siteUrl}/${lang}/privacy-policy`">
                    Datenschutzerklärung
                  </a>
                </p>
              </div>
              <div class="text" v-if="lang === 'ar'">
                <h4>Edunet GmbH</h4>
                <p>شارع لوسيان شتراس 41 (Luisenstr. 41)<br/>برلين 10117 (10117 Berlin) </p>

                <p>
                  المدير العام:<br/>
                  جينيفر موفاي<br/>
                  البريد الالكتروني: <a href="mailto:jm@edunet.works">jm@edunet.works</a>
                </p>
                <p>
                  سجل الشركة: <br>
                  HRB 264074 B<br>
                  محكمة المدينة في برلين
                </p>
                <p>
                  مديرية جباية الضرائب في برلين<br>
                  معرف ضريبة القيمة المضافة DE368496173
                </p>
                <p>
                  الربط البنكي: <br>
                  Berliner Sparkasse <br>
                  IBAN DE29 1005 0000 0191 3505 59
                </p>
                <p>
                  <a :href="`${siteUrl}/${lang}/privacy-policy`">
                    سياسة الخصوصية
                  </a>
                </p>
              </div>
            </div>
          </b-col>
          <b-col lg="9" md="7" sm="12" class="footer-column">
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li>
                    <a :href="`${siteUrl}/${lang}/welcome`" target="_blank">
                      {{ translations[13801] }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li>
                    <a :href="`${siteUrl}/${lang}/educational-network`" target="_blank">
                      {{ translations[15260] }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li>
                    <a :href="`${siteUrl}/${lang}/job-placement`" target="_blank">
                      {{ translations[6229] }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li>
                    <a :href="`${siteUrl}/${lang}/german-language`" target="_blank">
                      {{ translations[15261] }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li>
                    <router-link to="/">
                      {{ translations[13569] }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li>
                    <a :href="`${siteUrl}/${lang}/about-us`" target="_blank">
                      {{ translations[14998] }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li>
                    <a :href="`${siteUrl}/${lang}/contact`" target="_blank">
                      {{ translations[5661] }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="footer-bottom">
        <b-row>
          <b-col>
            <div class="clearfix">
              <div class="copyright pull-left">
                <p>
                  © {{ new Date().getFullYear() }} Edunet GmbH
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </footer>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';
import { getByDomain } from '@/config/sites';

interface LinkItem {
  text: {}
  link?: string
  hasIcon?: boolean
}

@Component
export default class FooterComponent extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get lang() {
    return AppModule.language;
  }

  get siteUrl() {
    const ThemeDetection = getByDomain();
    if (ThemeDetection && ThemeDetection.site) {
      return ThemeDetection.site;
    }
    return window.location.origin;
  }
}
</script>
<style lang="scss" scoped>
[theme="default"] {
  .footer-theme {
    background: #000000;
    padding: 2.35rem 0 0;
    .pull-left {
      float: left;
    }
    .pull-right {
      float: right;
    }
    a {
      text-decoration: none;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      color: #ffffff;

      &:hover {
        color: var(--aba-primary);
      }
    }
    ul {
      padding: 0;
    }
    h4 {
      color: #ffffff;
      font-size: 20px;
    }
    .footer-top {
      position: relative;
      display: block;
      padding: 0px 0px 30px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      p {
        color: #ffffff;
        font-size: 17px;
        line-height: 24px;
        margin-bottom: 26px;
      }
      .widget-content li {
        a {
          position: relative;
          display: inline-block;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          color: #ffffff;

          &:hover {
            color: var(--aba-primary);
          }
        }
      }
      .footer-column .links-widget {
        margin-right: 30px;
        &:last-child {
          //margin-right: 0;
        }
      }
    }
    .footer-bottom {
      position: relative;
      padding-top: 15px;
      .copyright p {
        color: #ffffff;
        line-height: 26px;
        font-size: 16px;
        a {
          display: inline-block;
          color: #ffffff;
          text-transform: uppercase;
          &:hover {
            color:  var(--aba-primary);
          }
        }
      }
      .footer-nav li {
        position: relative;
        display: inline-block;
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
          &:before {
            display: none;
          }
        }
        a {
          position: relative;
          display: inline-block;
          font-size: 16px;
          line-height: 26px;
          font-weight: 300;
          color: #ffffff;
          &:hover {
            color: var(--aba-primary);
          }
        }
        &:before {
          position: absolute;
          content: '.';
          color: #ffffff;
          width: 2px;
          height: 16px;
          top: -3px;
          right: -12px;
        }
      }
    }
  }
}
</style>
