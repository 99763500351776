<template>
  <div class="position-relative" id="subheader">
    <b-container>
      <b-row align-v="center" no-gutters>
        <b-col cols="auto">
          <!-- Logo -->
          <a href="https://jig.world/">
            <div class="p-0 h-100 mr-3">
              <img :src="require('@/assets/img/logo/header-logo-jig.png')" alt="JIG" class="img img-fluid logo-header" />
            </div>
          </a>
          <!-- /Logo -->
        </b-col>
        <b-col cols="auto" class="position-static">
          <nav class="main-nav d-none d-md-inline-block">
            <ul class="main-nav-list list-unstyled">
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" href="https://jig.world/" class="main-nav-link">
                    Home
                  </a>
                </div>
              </li>
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jig.world/${lang}/corporate/about`" class="main-nav-link">
                    Corporate
                  </a>
                </div>
              </li>
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jig.world/${lang}/services`" class="main-nav-link">
                    Services
                  </a>
                </div>
              </li>
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jig.world/${lang}/subjects`" class="main-nav-link">
                    Information
                  </a>
                </div>
              </li>
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jig.world/${lang}/blog`" class="main-nav-link">
                    Blog
                  </a>
                </div>
              </li>
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jig.world/${lang}/contact`" class="main-nav-link">
                    Contact
                  </a>
                </div>
              </li>
            </ul>
          </nav>
        </b-col>
        <b-col cols="auto" class="d-none d-md-flex ml-auto">
          <router-link
            to="/"
            class="btn btn-dark btn-sm d-none d-lg-block m-0 ml-half"
          >
            <svg class="mr-2" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.11875 1.59375L6.80625 0.90625C7.11875 0.625 7.5875 0.625 7.86875 0.90625L13.9625 6.96875C14.2438 7.28125 14.2438 7.75 13.9625 8.03125L7.86875 14.125C7.5875 14.4062 7.11875 14.4062 6.80625 14.125L6.11875 13.4375C5.8375 13.125 5.8375 12.6562 6.11875 12.3438L9.9 8.75H0.93125C0.49375 8.75 0.18125 8.4375 0.18125 8V7C0.18125 6.59375 0.49375 6.25 0.93125 6.25H9.9L6.11875 2.6875C5.8375 2.375 5.80625 1.90625 6.11875 1.59375Z" fill="white"/>
            </svg>
            {{ translations[13569] }}
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LanguageHeader from '@/components/Header/LanguageHeader.vue';
import { AppModule } from '@/store/modules/app';

interface GeneralLink {
  text: string
  link_en?: string
  link_de: string
  link?: () => string
}

@Component({
  components: {
    LanguageHeader,
  },
})
export default class HeaderInformationNavigationJig extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get lang() {
    return AppModule.language;
  }
}
</script>
<style scoped lang="scss">
[theme="jig"] {
  .logo-header {
    max-width: 133px;
  }
  .main-nav-link {
    color: #222222;
    font-weight: 500;
  }
}

.main-nav-list {
  background: #f6f9fb;
  padding: 0;
  margin: 0;
}

.main-nav-link-wrapper {
  >a, >a:visited, >a:active, >a:hover {
    color: rgb(var(--aba-secondary-600));
    text-decoration: none;
  }
}
</style>
