<template>
  <b-row class="g-2" v-if="sidThemes">
    <b-col v-for="item in landingBars" :key="`bars-${item.id}`">
      <b-card no-body class="h-100">
        <b-card-body>
          <h4 v-html="item.label" />
          <p v-html="item.info_text"></p>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
  <b-row class="g-2" v-else>
    <b-col>
      <b-card no-body class="h-100">
        <b-card-body>
          <h4 v-html="translations[13943]" />
          <p v-html="translations[13944]"></p>
        </b-card-body>
      </b-card>
    </b-col>
    <b-col>
      <b-card no-body class="h-100">
        <b-card-body>
          <h4 v-html="translations[13945]" />
          <p v-html="translations[13946]" />
        </b-card-body>
      </b-card>
    </b-col>
    <b-col>
      <b-card no-body class="h-100">
        <b-card-body>
          <h4 v-html="translations[13947]"></h4>
          <p v-html="translations[13948]"></p>
          <!-- <b-collapse id="collapse-text" v-model="visibleBox3">
            <p>Die Aufgabe der Abteilung „WORK in AUSTRIA“ ist es, internationale Fachkräfte mit heimischen Unternehmen zu verbinden, beide Zielgruppen zu rechtlichen Aspekten einer Beschäftigung in Österreich, in einem österreichischen Unternehmen sowie zum Thema Leben &amp; Arbeiten in Österreich zu informieren und zu beraten sowie Österreich als Arbeitsstandort bei Fachkräften international zu bewerben.</p>
          </b-collapse>
          <b-button v-if="!visibleBox3" variant="link" size="sm" @click="visibleBox3 = true">
            mehr lesen
          </b-button>
          <b-button v-else variant="link" size="sm" @click="visibleBox3 = false">
            weniger lesen
          </b-button> -->
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { group } from '@/config/configuration';

@Component
export default class TextInformationBox extends Vue {
  visibleBox3 = false;

  get translations() {
    return AppModule.translation;
  }

  get sid() {
    return AppModule.sid;
  }

  get sidThemes() {
    return group(this.sid);
  }

  get landingBars() {
    return AppModule.settingPortal.landing_bars;
  }
}
</script>
