<template>
  <core-sidebar
    sidebar-class="w-100 w-xl-75 w-xxl-50"
    footer-class="py-2"
    no-close-on-route-change
    backdrop
    shadow
    lazy
    id="applicant-sidebar-new"
    title=""
    right
    @shown="handleShowSidebar"
    @hidden="handleHideSidebar"
  >
    <template #header="{ hide }">
      <b-button variant="link" v-b-tooltip.hover.bottom :title="translations[10908]" @click="hide">
        <b-icon icon="arrow-right" />
      </b-button>
    </template>
    <b-tabs class="h-90" nav-class="mb-0 bg-aba-secondary" no-nav-style content-class="h-100">
      <b-tab active title-link-class="text-white" class="h-100">
        <template #title>
          <div class="d-flex align-items-center">
            <b-icon icon="person-circle" />
            <span class="d-none d-md-inline ml-1">{{ translations[13867] }}</span>
          </div>
        </template>
        <b-overlay :show="isFetching" class="h-100">
          <div v-if="Object.keys(workerData).length" class="p-3">
            <b-row align-v="center" class="mb-3">
              <b-col cols="auto"
                ><span class="fs-6 fw-bold pe-4">{{ translations[13867] }} {{ applicantId }}</span>
              </b-col>
              <b-col cols="auto" class="d-flex flex-wrap justify-content-end ml-auto">
                <span v-b-tooltip.hover.bottom :title="translations[13779]" class="d-block fs-6">
                  <b-icon icon="clock-history" class="mr-1"></b-icon>{{ datePeriodText(workerData.activation_date) }}</span
                >
                <span v-b-tooltip.hover.bottom :title="translations[13780]" class="d-block fs-6 ml-2"
                  ><b-icon icon="arrow-clockwise" class="mr-1"></b-icon>{{ datePeriodText(workerData.last_modified) }}</span
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" xl="9">
                <h2 class="mb-4">
                  <span class="fw-bold">{{ workerData.position }} </span><span class="fw-light ms-2">({{ workerData.age }})</span>
                </h2>
                <h4 class="fw-bold mb-3">
                  {{ translations[14694] }}
                  <span
                    class="text-black-50 fs-5 ml-2 cursor-pointer"
                    v-b-tooltip.hover
                    :title="translations[14949]"
                  >
                    <b-icon icon="info-circle"></b-icon>
                  </span>
                </h4>
                <div class="mb-4">
                  <span class="fw-bold lh-sm d-flex align-items-top"
                    ><b-icon icon="chat-left-quote" class="mr-1"></b-icon>{{ workerData.position_keywords
                    }}<b-icon icon="chat-right-quote" class="align-self-end ml-1"></b-icon
                  ></span>
                </div>
                <!-- <hr /> -->
                <!-- <b-row>
                  <b-col cols="12">
                    <h5 class="fw-bold mb-1">Staatsbürgerschaft</h5>
                    <p class="fs-6">ukrainisch</p>
                  </b-col>
                  <b-col cols="12">
                    <h5 class="fw-bold mb-1">Aufenthaltstitel</h5>
                    <p class="fs-6">Aufenthaltsbewilligung, Sonderfälle unselbständiger Erwerbstätigkeit (§ 62 NAG)</p></b-col
                  >
                </b-row> -->
              </b-col>
              <b-col cols="12" md="6" xl="3">
                <img v-if="applicantPhoto" :src="applicantPhoto" alt="applicant-photo" class="w-100 img-fluid" />
                <img v-else :src="require(`@/assets/img/${placeholderPhoto}`)" alt="applicant-photo" class="w-100 img-fluid" />
              </b-col>
            </b-row>
            <!-- <hr />
            <div>
              <h4 class="fw-bold">
                Angestrebte(r) Beruf(e)
                <span
                  v-b-tooltip.hover
                  title="Der Wunschberuf wird von der Person frei eingegeben und formuliert. Er entspricht daher nicht unbedingt einem internationalen Standard der Berufsbezeichnung, gibt jedoch gut wieder, welche Vorstellung die Fachkraft hat."
                  class="text-black-50 fs-5 ml-2 cursor-pointer"
                >
                  <b-icon icon="info-circle"></b-icon>
                </span>
              </h4>
              <div>
                <h5 class="d-inline-block mr-1 mb-1"><b-badge class="bg-aba-secondary-100 text-primary whitespace-normal">Cloud-Ingenieur</b-badge></h5>
              </div>
            </div> -->
            <template v-if="workerData.profession_groups?.isco?.length">
              <hr />
              <div>
                <h4 class="fw-bold">
                  {{ translations[13422] }}
                  <span
                    v-b-tooltip.hover
                    :title="translations[14950]"
                    class="text-black-50 fs-5 ml-2 cursor-pointer"
                  >
                    <b-icon icon="info-circle"></b-icon>
                  </span>
                </h4>
                <div>
                  <h5 v-for="group in workerData.profession_groups?.isco" :key="group.group" class="d-inline-block mr-1 mb-1">
                    <b-badge class="bg-aba-secondary-100 text-primary whitespace-normal">{{ group.name }}</b-badge>
                  </h5>
                </div>
              </div>
            </template>
            <template v-if="workerData.location_groups?.length">
              <hr />
              <div>
                <h4 class="fw-bold">
                  {{ translations[305] }}
                </h4>
                <div>
                  <h5 v-for="group in workerData.location_groups" :key="group.code" class="d-inline-block mr-1 mb-1">
                    <b-badge class="bg-aba-secondary-100 text-primary whitespace-normal">{{ group.name }}</b-badge>
                  </h5>
                </div>
              </div>
            </template>
            <template v-if="isAuth && workerData.career_background?.length">
              <hr />
              <div>
                <h4 class="fw-bold">{{ translations[1324] }}</h4>
                <template v-for="(careerSection, index) in userCareerList">
                  <div v-if="careerSection.list.length" :key="index" :class="{ 'mt-3': index !== 0 }">
                    <p class="fw-bold">{{ careerSection.title }}</p>
                    <ul class="lifeline fs-6 lh-sm">
                      <li v-for="careerItem in careerSection.list" :key="careerItem.id">
                        {{ careerItem.date_from }} — {{ careerItem.date_to }} <br />
                        <span class="fw-bold">{{ careerItem.position }}</span
                        >, {{ careerItem.firmname }}, {{ careerItem.location }}
                      </li>
                    </ul>
                  </div>
                </template>
              </div>
            </template>
            <template v-else-if="!isAuth">
              <hr />
              <div class="position-relative">
                <div class="worker-card__auth-warning-wrapper position-absolute z-3" v-b-modal.not-auth-modal>
                  <p class="worker-card__auth-warning position-relative text-center bg-white border border-dark p-2 rounded m-auto">{{ translations[14474] }}</p>
                </div>
                <div class="blur-5">
                  <h4 class="fw-bold">{{ translations[1324] }}</h4>
                  <template v-for="index in 4">
                    <div :key="index" :class="{ 'mt-3': index !== 0 }">
                      <p class="fw-bold">Lorem, ipsum dolor.</p>
                      <ul class="lifeline fs-6 lh-sm">
                        <li v-for="innerIndex in 2" :key="innerIndex">
                          05-05-2023 — 05-05-2023 <br />
                          <span class="fw-bold">Lorem, ipsum.</span>, Lorem ipsum dolor sit amet, Lorem, ipsum.
                        </li>
                      </ul>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <template v-if="workerData.zusqual">
              <hr />
              <div>
                <h4 class="fw-bold">{{ translations[1326] }}</h4>
                <div class="alert alert-light border p-2 mb-4 fs-6">
                  {{ workerData.zusqual }}
                </div>
              </div>
            </template>
          </div>
        </b-overlay>
      </b-tab>
      <b-tab v-if="!isAuth || workerData.cv_qualification?.length" title-link-class="text-white" class="h-100">
        <template #title>
          <div class="d-flex align-items-center">
            <b-icon icon="star-fill" />
            <span class="d-none d-md-inline ml-1">{{ translations[2887] }}</span>
          </div>
        </template>
        <div class="p-3">
          <b-row align-v="center" class="mb-3">
            <b-col cols="auto"
              ><span class="fs-6 fw-bold pe-4">{{ translations[13867] }} {{ applicantId }}</span>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <span v-b-tooltip.hover.bottom :title="translations[13779]" class="d-block fs-6">
                <b-icon icon="clock-history" class="mr-1"></b-icon>{{ datePeriodText(workerData.activation_date) }}</span
              >
            </b-col>
          </b-row>
          <h4 class="fw-bold mb-3">Fachliche Kompetenzen nach Kompetenzgruppen</h4>
          <b-row v-if="isAuth && workerData.cv_qualification?.length" class="g-3" align-v="stretch">
            <template v-for="(qual, index) in workerData.cv_qualification">
              <b-col :key="index" cols="12" md="6" class="mb-3">
                <b-card class="h-100 fs-6" body-class="p-2">
                  <h6>{{ qual.name }}</h6>
                  <div>
                    <template v-if="qual.specific_values">
                      <div v-for="(skill, i) in qual.skills" :key="i" class="mb-1">
                        <strong>{{ qual.specific_values[i] || '' }}</strong>
                        <span class="ml-2">{{ skill.join(' // ') }}</span>
                      </div>
                    </template>
                    <div v-else v-for="(skill, i) in qual.skills" :key="i" class="d-flex mb-1">
                      <b-form-rating
                        v-if="i >= 1"
                        inline
                        disabled
                        no-border
                        class="skill-stars bg-transparent text-warning px-0 pt-1"
                        size="sm"
                        style="font-size: 13.5px"
                        :value="i"
                        stars="4"
                        sta
                      /><b-icon v-else icon="check-circle-fill" style="min-width: 54px; font-size: 1rem" variant="warning" class="mt-1" />
                      <p class="ml-2 mb-0">{{ skill.join(' // ') }}</p>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </template>
          </b-row>
          <div v-else-if="!isAuth" class="position-relative">
            <div class="worker-card__auth-warning-wrapper position-absolute z-3" v-b-modal.not-auth-modal>
              <p class="worker-card__auth-warning position-relative text-center bg-white border border-dark p-2 rounded m-auto">{{ translations[14474] }}</p>
            </div>
            <div class="blur-5">
              <b-row class="g-3" align-v="stretch">
                  <template v-for="(qual, index) in 4">
                    <b-col :key="index" cols="12" md="6" class="mb-3">
                      <b-card class="h-100 fs-6" body-class="p-2">
                        <h6>Lorem ipsum dolor sit.</h6>
                        <div v-for="i in 3" :key="i" class="d-flex mb-1">
                          <b-form-rating
                            inline
                            disabled
                            no-border
                            class="skill-stars bg-transparent text-warning px-0 pt-1"
                            size="sm"
                            style="font-size: 13.5px"
                            :value="4"
                            stars="4"
                          />
                          <p class="ml-2 mb-0">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                        </div>
                      </b-card>
                    </b-col>
                  </template>
              </b-row>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <template #footer>
      <div v-if="!isFetching" class="d-flex justify-content-center">
        <b-button v-if="workerData.can_export" variant="primary" size="sm" @click="getPdf">
          <b-icon icon="printer-fill" />
          {{ translations[1983] }}
        </b-button>
        <b-button
          v-if="isActionsAllowed"
          v-b-modal="!isAuth ? 'not-auth-modal' : ''"
          variant="primary"
          size="sm"
          class="ml-1"
          @click="handleToggleNotepad"
        >
          <b-icon :icon="isNotebookItem ? 'check' : 'paperclip'" />
          {{ translations[isNotebookItem ? 10983 : 505] }}
        </b-button>
        <b-button
          v-if="isActionsAllowed"
          v-b-modal="!isAuth ? 'not-auth-modal' : 'modal-contact-profile'"
          variant="primary"
          size="sm"
          class="ml-1"
          @click="handleContactModal"
        >
          <b-icon icon="bullseye" />
          {{ translations[3575] }}
        </b-button>
        <b-dropdown text="Primary" no-caret right variant="primary" size="sm" menu-class="share-dropdown__menu" class="share-dropdown ml-1">
          <template #button-content>
            <b-icon icon="share" />
            {{ translations[14462] }}
          </template>
          <template #default>
            <div class="px-2">
              <div class="share-dropdown__input-wrapper position-relative mb-2">
                <b-form-input
                  id="share-applicant-link-input"
                  type="text"
                  class="bg-aba-secondary-100 pl-4"
                  :value="shareApplicantLink"
                  readonly
                  disabled
                />
                <b-icon icon="share" class="share-dropdown__input-icon position-absolute" />
              </div>
              <b-button variant="primary" size="sm" class="w-100 mb-2" @click="copySharingLink">
                {{ translations[14765] }}
              </b-button>
              <b-button :href="shareApplicantLinkByEmail" variant="link" link size="sm" class="text-aba-secondary py-0 pl-0">
                <b-icon icon="envelope" class="mr-2" />
                {{ translations[10473] }}
              </b-button>
            </div>
          </template>
        </b-dropdown>
        <b-dropdown text="Primary" no-caret right variant="primary" size="sm" menu-class="share-dropdown__menu" class="share-dropdown ml-1">
          <template #button-content>
            <b-icon icon="translate" />
            {{ translations[11441] }}
          </template>
          <template #default>
            <div class="px-2">
              <div id="google_translate_element" ref="google_translate_element"></div>
            </div>
          </template>
        </b-dropdown>
      </div>
      <WorkerContactModal />
    </template>
  </core-sidebar>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { AppModule, AuthTab } from '@/store/modules/app';
import { WorkersModule } from '@/store/modules/workers';
import { UserModule } from '@/store/modules/user';
import { NotebookModule } from '@/store/modules/notebook';
import { logEvent } from '@/api/EventLogger';
import { EVENT_APPLICANT_PROFILE_VIEW } from '@/config/constant';
import {
  ApplicantCV, getWorkerCV, getWorkerPdf, getWorkerPhoto,
} from '@/api/Workers';
import DateHelpMixin from '@/mixins/DateHelpMixin';
import WorkerContactModal from '@/components/Workers/WorkerContactModal.vue';
import { mixins } from 'vue-class-component';
import { saveAs } from 'file-saver';

@Component({
  components: {
    WorkerContactModal,
  },
})
export default class WorkerSidebar extends mixins(DateHelpMixin) {
  isFetching = true;

  workerData: ApplicantCV = {} as ApplicantCV;

  get translations() {
    return AppModule.translation;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  googleTranslateElementInit() {
    // console.log(google);
    // new (google as any) .translate!.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get applicantId() {
    return WorkersModule.selectedWorkerId;
  }

  get sid() {
    return AppModule.sid;
  }

  // TokenApplicant
  get accessTokenApplicant() {
    return UserModule.accessTokenApplicant;
  }

  // TokenConsultant
  get accessTokenConsultant() {
    return UserModule.accessTokenConsultant;
  }

  // TokenCompany
  get accessTokenCompany() {
    return UserModule.accessTokenCompany;
  }

  get placeholderPhoto() {
    switch (this.workerData.salutation) {
      case 1:
        return 'photo-placeholder-men.png';
      case 2:
        return 'photo-placeholder-woman.png';
      default:
        return 'photo-placeholder.jpg';
    }
  }

  get shareApplicantLink() {
    return `${window.location.origin}/workers?lang=${this.$route.query.lang}&workerViewId=${this.applicantId}`;
  }

  get shareApplicantLinkByEmail() {
    let body = this.translations[14787];
    const subject = this.translations[14786];
    body = encodeURIComponent(`${body}\n${this.workerData.position || ''}\n${this.shareApplicantLink}`);
    return `mailto:?subject=${subject}&body=${body}`;
  }

  copySharingLink() {
    const copyText = document.getElementById('share-applicant-link-input') as HTMLInputElement;

    copyText.select();
    copyText.setSelectionRange(0, 99999);

    navigator.clipboard.writeText(copyText.value);
  }

  get userCareerList() {
    const arr: any = [
      { title: this.translations[2842], list: [] }, // section 1 Berufliche Tätigkeit
      { title: this.translations[2843], list: [] }, // section 2 Berufsausbildung/ Studium
      { title: this.translations[2844], list: [] }, // section 3 Fortbildung/ Praktikum
      { title: this.translations[2845], list: [] }, // section 4 Schulausbildung
      { title: this.translations[2851], list: [] }, // section 0 Sonstiges
    ];
    this.workerData.career_background?.forEach((el: any) => {
      if (el.section === 1) {
        arr[0].list.push(el);
      }
      if (el.section === 2) {
        arr[1].list.push(el);
      }
      if (el.section === 3) {
        arr[2].list.push(el);
      }
      if (el.section === 4) {
        arr[3].list.push(el);
      }
      if (el.section === 0) {
        arr[4].list.push(el);
      }
    });

    return arr;
  }

  get isActionsAllowed() {
    return !this.isAuth || this.accessTokenCompany || this.accessTokenConsultant;
  }

  get notebookWorkers() {
    return NotebookModule.notebookWorkers;
  }

  get isNotebookItem() {
    return !!this.notebookWorkers.find((x: { id: number }) => x.id === this.applicantId);
  }

  async handleToggleNotepad() {
    if (this.isAuth) {
      if (this.accessTokenCompany || this.accessTokenConsultant) {
        await NotebookModule.toogleNotepadItem({ type: 'applicant', item_id: this.applicantId!, isMarked: this.isNotebookItem });
      }
    }
  }

  async handleMessage(event: MessageEvent) {
    switch (event.data.message) {
      case 'login_required':
        this.$bvModal.show('not-auth-modal');
        break;
      case 'login_required_company':
        AppModule.SET_SELECTED_AUTH_TAB(AuthTab.firm);
        this.$bvModal.show('login-modal');
        break;
      case 'note_applicant':
        // await this.handleToggleNotepad(event.data);
        break;
      default:
        break;
    }
  }

  handleContactModal() {
    if ((this.accessTokenCompany || this.accessTokenConsultant) && this.applicantId) {
      WorkersModule.SET_SELECTED_APPLICANT_CONTACT(this.applicantId);
    }
  }

  addMessageListener() {
    window.addEventListener('message', this.handleMessage, false);
  }

  hideMessageListener() {
    window.removeEventListener('message', this.handleMessage, false);
  }

  async logApplicantView() {
    if (!this.isAuth || !this.applicantId) return;
    await logEvent({
      event_id: EVENT_APPLICANT_PROFILE_VIEW,
      object_id: this.applicantId,
    });
  }

  applicantPhoto = '';

  async getUserDataPhoto() {
    try {
      const response = await getWorkerPhoto(this.applicantId!, this.languageSid);
      if (response.data.size < 100) {
        return;
      }
      const url = URL.createObjectURL(response.data);
      const img = new Image();
      // prepare base 64 photo for printing
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      img.src = url;
      this.applicantPhoto = img.src;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async handleShowSidebar() {
    this.isFetching = true;
    this.addMessageListener();
    this.logApplicantView();
    if (this.applicantId) {
      this.workerData = await getWorkerCV(this.applicantId, this.languageSid, this.lang);
      await this.getUserDataPhoto();
    }
    this.loadGoogleTranslateScript();
    this.toggleTranslateAttributes(true);
    this.isFetching = false;
  }

  handleHideSidebar() {
    this.workerData = {} as ApplicantCV;
    this.applicantPhoto = '';
    this.hideMessageListener();
    this.toggleTranslateAttributes(false);
    this.resetToDefaultLanguage();
  }

  async getPdf() {
    if (this.applicantId) {
      const data = await getWorkerPdf(this.applicantId);
      saveAs(data.data, `${this.translations[13867]}_${this.applicantId}.pdf`);
    }
  }

  get lang() {
    return AppModule.language;
  }

  resetToDefaultLanguage() {
    const closeElement = (document.getElementById(':1.container') as HTMLIFrameElement)?.contentDocument?.getElementById(':1.close');
    if (closeElement) {
      closeElement.click();
    }
  }

  loadGoogleTranslateScript() {
    // Load Google Translate Element script dynamically
    const script = document.createElement('script');
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.head.appendChild(script);

    // Initialize Google Translate Element
    // eslint-disable-next-line
    (window as any).googleTranslateElementInit = () => {
      // eslint-disable-next-line
      new (window as any).google.translate.TranslateElement(
        { pageLanguage: this.lang }, // Set the default language here
        'google_translate_element',
      );
    };
  }

  toggleTranslateAttributes(init: boolean) {
    document.body.setAttribute('translate', init ? 'no' : '');
    document.querySelector('#applicant-sidebar-new')?.setAttribute('translate', init ? 'yes' : '');
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .skill-stars {
    height: min-content;

    .b-rating-star {
      padding: 0;
    }
  }

  .share-dropdown {
    &__menu {
      min-width: 13rem;
    }

    &__input-icon {
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
    }
  }
}

.worker-card__auth-warning-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.worker-card__auth-warning {
  top: 20%;
  max-width: 560px;
  width: 100%;
}
</style>
