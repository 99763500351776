<template>
  <b-overlay :show="loading" opacity="1">
    <b-row class="g-4 mt-0">
      <b-col v-show="info.address" cols="12" class="w-100">
        <b-overlay :show="loading" variant="primary" opacity="1" rounded="sm" style="min-height: 200px">
          <div class="text-center" v-if="info.company_header">
            <b-img-lazy :src="headerImg" class="img-fluid" />
          </div>
          <div v-else>
            <div class="google-map" ref="googleMapGeocoder" style="height: 300px"></div>
          </div>
        </b-overlay>
      </b-col>
      <b-col sm="5" md="4" lg="3">
        <b-img-lazy v-if="info.logo_url" :src="logoImg" class="img img-fluid w-100 mb-4" />
        <p class="mb-2">
          <span class="fw-bold break-word" v-html="info.firmname"></span><br />
          <span v-if="info.address">{{ info.address }}</span> <br />
          <span v-if="info.plz">{{ info.plz }}</span
          >&nbsp;
          <span v-if="info.ort">{{ info.ort }}</span>
        </p>
        <p class="mb-0" v-if="info.phone">
          <b-icon icon="telephone" class="mr-2" />
          <a :href="`tel:${info.phone}`">{{ info.phone }}</a>
        </p>
        <p class="mb-0" v-if="info.fax">
          <b-icon icon="printer" class="mr-2" />
          <a :href="`tel:${info.fax}`">{{ info.fax }}</a>
        </p>
        <p v-if="info.email">
          <b-icon icon="envelope" class="mr-2"></b-icon>
          <a :href="`mailto:${info.email}`">{{ info.email }}</a>
        </p>
        <!-- <p>
          <b-icon icon="globe2" class="mr-2"></b-icon>
          <a
            href="#"
            @click.prevent
            target="_blank"
            >-</a
          >
        </p> -->
        <template v-if="info.contact_person_firstname">
          <p class="mb-0">
            <span class="fw-bold">Ihre Ansprechpartnerin:</span>
            <br />{{ info.contact_person_salutation }} {{ info.contact_person_firstname }} {{ info.contact_person_lastname }}
          </p>
          <p v-if="info.contact_person_email">
            <b-icon icon="envelope" class="mr-2"></b-icon>
            <a :href="`mailto:${info.contact_person_email}`">{{ info.contact_person_email }}</a>
          </p>
        </template>
      </b-col>
      <b-col sm="7" md="8" lg="9">
        <h1 class="break-word display-4">{{ info.firmname }}</h1>
        <div v-if="info.description" v-html="info.description" />
        <div v-if="info.description" v-html="info.description_long" class="mt-3" />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { ICompanyInfo } from '@/types/CompanyType';
import { Loader } from '@googlemaps/js-api-loader';
import { EVENT_COMPANY_PROFILE_VIEW, GOOGLE_MAP_KEY } from '@/config/constant';
import { CompanyModule } from '@/store/modules/company';
import { UserModule } from '@/store/modules/user';
import { logEvent } from '@/api/EventLogger';

@Component
export default class CompanyInfo extends Vue {
  get info(): ICompanyInfo {
    return CompanyModule.companyItem;
  }

  google: any = null;

  map: any;

  geocoder: any = null;

  marker: any = null;

  loading = true;

  get translations() {
    return AppModule.translation;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get companyId() {
    return CompanyModule.companyDetailFilter.idcomp;
  }

  get headerImg() {
    return `https://job-server.net${this.info.company_header}`;
  }

  get logoImg() {
    const img = this.info.logo_url;
    if (img?.includes('https://job-server.net')) {
      return img;
    }
    return `https://job-server.net${img}`;
  }

  clearMarker() {
    this.marker.setMap(null);
  }

  set_direction() {
    if (this.marker !== null) {
      this.clearMarker();
    }
    this.geocoder.geocode({ address: `${this.info.ort}, ${this.info.plz}, ${this.info.address}` }, (results: any[], status: any) => {
      if (status === this.google.maps.GeocoderStatus.OK) {
        this.map.setCenter(results[0].geometry.location);
        // eslint-disable-next-line
        this.marker.setPosition(results[0].geometry.location);
        this.marker.setMap(this.map);
      } else {
        console.error(`${status}`);
      }
    });
  }

  initializeMap() {
    this.geocoder = new this.google.maps.Geocoder();
    this.map = new this.google.maps.Map(this.$refs.googleMapGeocoder, {
      scrollwheel: false,
      zoom: 15,
      mapTypeId: this.google.maps.MapTypeId.ROADMAP,
      // center: { lat: 13.14, lng: 47.66 },
    });
    this.marker = new this.google.maps.Marker({
      map: this.map,
    });
    this.set_direction();
  }

  async getCompanyInfo() {
    this.loading = true;
    await CompanyModule.GetCompanyItem({
      sid: this.languageSid,
    });
    this.loading = false;
    this.googleCreate();
  }

  googleCreate() {
    if (this.info.address) {
      const loader = new Loader({
        apiKey: GOOGLE_MAP_KEY,
      });
      loader.load().then((google) => {
        this.google = google;
        this.initializeMap();
      });
    }
  }

  async logCompanyView() {
    if (!this.isAuth || !this.companyId) return;
    await logEvent({
      event_id: EVENT_COMPANY_PROFILE_VIEW,
      object_id: this.companyId,
    });
  }

  created() {
    this.getCompanyInfo();
    this.logCompanyView();
  }

  beforeDestroy() {
    // CompanyModule.RESET_COMPANIES_LIST_QUERY_FILTER();
    // CompanyModule.SET_COMPANY_ITEM({});
  }
}
</script>
