import HTTP from '@/plugins/axios';

export interface Academic {
  applicant_count: number
  isco_code: string
  isco_name: string
  skill_level: number
}

export interface ProfessionGroups {
  'non-academic': Academic[]
  academic: Academic[]
}
export interface WorkersGroupResponse {
  profession_groups: ProfessionGroups
}

export type Applicant = {
  id: number
  position: string
  position_keywords: string
  locations: string
  professions: string
  availability: string
  last_modified: string
  reg_date: string
  salary_hour: number
  salary_year: number
}

export type Params = {
  sid: string
  'isco[]': string[]
  sort: string
  limit: string
  NumRows: string
  total_rows: number
  page: number
}

type WorkersListResponse = {
  applicants: Applicant[]
  params: Params
}

export type SearchBlockRow = {
  name: string
  value: string
  type: string
  count: number
}

export type SearchBlock = {
  name: string
  block_type: string
  rows: SearchBlockRow[]
}

export type SearchLanguage = {
  value: string
  name: string
  cumulative_count: number
}

export type LanguageOption = {
  value: number
  name: string
}

export type SalaryOption = {
  name: string
  id: number
}

export type LocationOption = {
  location_id: string
  location_name: string
}

export type ClustersOption = {
  applicants_count: number
  cluster_id: number
  cluster_name: string
  picture: string
}

export type WorkersFilterSettingsResponse = {
  search_blocks: SearchBlock[]
  search_languages: SearchLanguage[]
  salary_options: SalaryOption[]
  location_options: LocationOption[]
  clusters: ClustersOption[]
  overall_applicants_count: number
}

export interface LocationGroupsEntity {
  name: string
  code: string
}
export interface IscoEntity {
  group: string
  rating: number
  name: string
}
export interface ApplicantProfessionGroups {
  isco: IscoEntity[]
}
export interface Availability {
  value: number
}
export interface CareerBackgroundEntity {
  id: number
  date_from: string
  date_to: string
  position: string
  section: number
  location: string
  firmname: string
  description: string
}
export interface Skills {
  1?: string[]
  5?: string[]
  6?: string[]
  3?: string[]
  4?: string[]
  2?: string[]
}
export interface CvQualificationEntity {
  name: string
  skills: Skills
  specific_values?: { [key: number]: string }
}
export interface ApplicantCV {
  applicant_id: number
  registration_date: string
  last_modified: string
  age: number
  location_groups: LocationGroupsEntity[]
  profession_groups: ApplicantProfessionGroups
  availability: Availability
  position: string
  position_keywords: string
  citizenship: string
  salutation: number
  title: number
  zusqual: string | null
  activation_date: string
  career_background?: CareerBackgroundEntity[]
  cv_qualification?: CvQualificationEntity[]
  can_export: boolean
}

export const getWorkersGroups = async(params: any): Promise<WorkersGroupResponse> => {
  const response = await HTTP.get('/jobnews/applicant_isco_groups', {
    params,
  });
  return response.data;
};

export const getWorkersList = async(params: any): Promise<WorkersListResponse> => {
  const response = await HTTP.get('/jobnews/applicant_list', {
    params,
  });
  return response.data;
};

export const getWorkersFiltersSettings = async(params: any): Promise<WorkersFilterSettingsResponse> => {
  const response = await HTTP.get('/jobnews/applicant_search_data', {
    params,
  });
  return response.data;
};

export const getWorkerCV = async(id: number | string, sid: string, lang: string): Promise<ApplicantCV> => {
  const response = await HTTP.get(`/cv/${id}?sid=${sid}&lang=${lang}`);
  return response.data;
};

export const getWorkerPhoto = async(id: number | string, sid: string) => {
  const response = await HTTP.get(`/cv/${id}/photo?sid=${sid}`, {
    responseType: 'blob',
  });
  return response;
};

export const getWorkerPdf = async(id: number | string) => {
  const response = await HTTP.get(`/cv/${id}/export`, {
    responseType: 'blob',
  });
  return response;
};

export const postDirectContactApi = async(data: any) => {
  const response = await HTTP.post('/direct_contact', data);
  return response.data;
};
