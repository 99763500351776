<template>
  <b-dropdown
    variant="tertiary"
    size="sm"
    text=""
    class="m-md-2 w-100 text-start nav ml-1"
    right
    :dropleft="isRtl !== 'ltr'"
    :no-caret="isRtl !== 'ltr'">
    <template v-slot:button-content>
      <span :class="`fi fis fi-${currentLang.code} rounded-circle`"></span>
      <span class="d-none d-md-inline-block ml-2">{{ currentLang.title }}</span>
    </template>
    <b-dropdown-item
      v-for="(item, index) in languages"
      @click="setLanguage(item.code)"
      :key="`language_item_${index}`"
      v-show="item.code !== lang && item.visible">
      <span :class="`fi fis fi-${item.code} rounded-circle`"></span>
      <span class="ml-2">{{ item.title }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { ThemesModule } from '@/store/modules/themes';

@Component
export default class LanguageHeader extends Vue {
  private languages = [
    { code: 'de', title: 'Deutsch', visible: true },
    { code: 'en', title: 'English', visible: true },
    { code: 'tr', title: 'Türkçe', visible: this.isGroupSid1 || this.isGroupSid2 },
    { code: 'ar', title: 'العربية', visible: this.isGroupSid1 || this.isGroupSid2 },
    { code: 'fr', title: 'Français', visible: this.isGroupSid1 || this.isGroupSid2 },
    { code: 'it', title: 'Italiano', visible: this.isGroupSid2 },
    { code: 'es', title: 'Español', visible: this.isGroupSid1 || this.isGroupSid2 },
    { code: 'pt', title: 'Portugal', visible: this.isGroupSid2 },
    { code: 'pl', title: 'Polska', visible: this.isGroupSid2 },
    { code: 'ro', title: 'Română', visible: this.isGroupSid2 },
    { code: 'uk', title: 'Українська', visible: this.isGroupSid2 },
  ];

  get isRtl() {
    return AppModule.rtl;
  }

  get sid() {
    return AppModule.sid;
  }

  get isGroupSid1() {
    return ['jig'].includes(this.sid);
  }

  get isGroupSid2() {
    return ['talentpool', 'edunet', 'kh', 'rhh', 'he'].includes(this.sid);
  }

  get lang() {
    return AppModule.language;
  }

  get currentLang() {
    return this.languages.find((lang) => lang.code === this.lang);
  }

  setLanguage(code: string) {
    AppModule.ActionLanguage(code);
    this.$router.push({
      path: this.$route.fullPath,
      query: {
        lang: code,
      },
    });
    document.location.reload();
  }
}
</script>
<style lang="scss" scoped>
::v-deep(.custom-dropdown) {
  .p-dropdown-trigger {
    display: none;
  }
  .p-dropdown-label {
    background: #ffffff;
  }
  &.rtl-dropdown {
    .dropdown-menu {
      left: 0!important;
    }
  }
}
::v-deep(.rtl-dropdown) {
  left: 0!important;
  .dropdown-menu {
    left: 0!important;
  }
}
</style>
