import HTTP from '@/plugins/axios';

export const getCaptchaGenerate = async() => {
  const response = await HTTP.get('/captcha/generate', {
    responseType: 'blob',
  });
  return {
    data: response.data,
    disposition: response.headers['content-disposition'],
  };
};

export const getCaptchaVerify = async(params: {token: string, text: string}) => {
  const response = await HTTP.get('/captcha/verify', {
    params,
  });
  return response.data;
};
