<template>
  <b-modal id="terms-modal"
           title=""
           scrollable
           hide-header
           size="xl"
           centered>
    <b-overlay :show="iframeLoad" rounded="sm" variant="primary" :opacity="0.2" style="min-height: 200px">
      <iframe
        title="Cabinet Applicant"
        width="100%"
        frameborder="0"
        marginwidth="0"
        marginheight="0"
        scrolling="auto"
        id="applicantIframe"
        @load="iframeLoad = false"
        v-resize="{
              log: false,
              heightCalculationMethod: 'lowestElement'
            }"
        :src="`https://job-server.net/jobnet/terms_of_use_applicant?sid=${languageSid}`"
      ></iframe>
    </b-overlay>
    <template #modal-footer="{hide}">
      <b-button variant="primary" @click="hide">
        {{ translations[1208] }}
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import iframeResize from 'iframe-resizer/js/iframeResizer';

@Component({
  directives: {
    resize: {
      bind(el, { value = {} }) {
        el.addEventListener('load', () => iframeResize(value, el));
      },
      unbind(el: any) {
        el.iFrameResizer.removeListeners();
      },
    },
  },
})
export default class TermsModal extends Vue {
  get sid() {
    return AppModule.sid;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get translations() {
    return AppModule.translation;
  }

  iframeLoad = true
}
</script>
