import HTTP from '@/plugins/axios';

export const jobClusterItems = async(params: any) => {
  const response = await HTTP.get('/portal/cluster/joblist', {
    params,
  });
  return response.data;
};

export const getCompanyClustersCount = async(params: any) => {
  const response = await HTTP.get('/portal/company_count', {
    params,
  });
  return response.data;
};
