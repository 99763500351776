export const createStringFromAnObject = (obj: any) => {
  const filteredObjectResult: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== '' && obj[key] !== 0) {
      filteredObjectResult[key] = obj[key];
    }
  });
  return Object.entries(filteredObjectResult)
    .map(([key, value]) => (value ? `${key}=${value}` : key))
    .join(';');
};
export const createObjectFromAnString = (obj: any) => {
  const paramsArray = obj.split(';');
  const resultObject = {} as any;
  paramsArray.forEach((param: any) => {
    const [key, value] = param.split('=');
    const paramKey = key || paramsArray.indexOf(param);
    resultObject[paramKey] = value;
  });
  return resultObject;
};

export const updateObject = (targetObject: any, sourceObject: any) => {
  const updatedObject = { ...targetObject };

  Object.keys(sourceObject).forEach((key) => {
    if (key in targetObject) {
      updatedObject[key] = sourceObject[key];
    }
  });
  return updatedObject;
};
