<template>
  <h6 class="font-weight-bold">
    {{ titleText }}
<!--    <span v-b-tooltip.hover :title="tooltipText" class="ml-2">-->
<!--      <b-icon class="text-black-50" icon="info-circle" />-->
<!--    </span>-->
    <slot />
  </h6>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FilterSectionTitle extends Vue {
  @Prop({ default: '' }) titleText?: string;

  @Prop({ default: '' }) tooltipText?: string;
}
</script>
