import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import App from '@/App.vue';
import CoreSidebar from '@/components/Core/CoreSidebar.vue';
import router from '@/router';
import store from '@/store';
import VueMeta from 'vue-meta';
import vSelect from 'vue-select';
import VueTheMask from 'vue-the-mask';
import VueScrollTo from 'vue-scrollto';
// Import Bootstrap and BootstrapVue CSS files
import '@/assets/scss/bootstrap-settings.scss';
import 'vue-select/dist/vue-select.css';
// Import Main CSS files
import '@/assets/scss/main.scss';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueScrollTo);
Vue.component('v-select', vSelect);
Vue.component('core-sidebar', CoreSidebar);

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(VueTheMask);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
