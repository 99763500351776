import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { AppModule } from '@/store/modules/app';
import { Languages } from '@/types/languagesType';
import DefaultTemplate from '@/layouts/DefaultTemplate.vue';
import CabinetTemplate from '@/layouts/CabinetTemplate.vue';
import Welcome from '@/views/WelcomeView.vue';
import { UserModule } from '@/store/modules/user';
import { ThemesModule } from '@/store/modules/themes';
import { getByDomain } from '@/config/sites';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    component: DefaultTemplate,
    children: [
      {
        path: '/',
        name: 'Welcome',
        component: Welcome,
      },
      {
        path: '/jobs',
        name: 'Jobs',
        component: () => import('../views/HomeView.vue'),
      },
      {
        path: '/workers',
        name: 'Workers',
        component: () => import('../views/SkilledView.vue'),
      },
      {
        path: '/company',
        name: 'Company',
        component: () => import('../views/CompanyView.vue'),
      },
      {
        path: '/map',
        name: 'Map',
        component: () => import('../views/MapView.vue'),
      },
      {
        path: '/impressum',
        name: 'Impressum',
        component: () => import('../views/ImpressumView.vue'),
      },
      {
        path: '/iframe-workers',
        name: 'WorkersIframe',
        component: () => import('../views/Iframe/WorkersIframeView.vue'),
      },
    ],
  },
  {
    path: '',
    component: CabinetTemplate,
    children: [
      {
        path: '/cabinet',
        name: 'Cabinet',
        component: () => import('../views/Profile/CabinetView.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const ThemeDetection = getByDomain();

  if (ThemeDetection) {
    AppModule.ActionSid(ThemeDetection.sid);

    // add theme
    ThemesModule.SetTheme(ThemeDetection);
  }

  // language query redirect
  if (to.query.lang && Object.values(Languages).includes(to.query.lang as Languages)) {
    AppModule.ActionLanguage(String(to.query.lang) as Languages);
  } else {
    // if (ThemeDetection && ThemeDetection.language) {
    //   AppModule.ActionLanguage(ThemeDetection.language);
    // }
    // @ts-ignore
    next({ ...to, query: { lang: AppModule.language, auth: to.query.auth || undefined }, replace: true });
    return;
  }

  if (!UserModule.accessTokenApplicant && !UserModule.accessTokenConsultant && !UserModule.accessTokenCompany) {
    if (to.path === '/cabinet') {
      next({ path: '/' });
    }
  }

  if (to.query.iframe) {
    AppModule.SetIsIframe(true);
  }

  if (to.query.sid) {
    AppModule.ActionLanguageSid(String(to.query.sid));
  }
  next();
});

export default router;
