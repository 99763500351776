<template>
  <footer class="footer footer-theme position-relative">
    <b-container style="max-width: 1340px;">
      <div class="footer-top">
        <b-row>
          <b-col lg="3" md="4" sm="12" class="footer-column">
            <div class="footer-widget links-widget logo-widget">
              <div class="text" v-if="lang === 'uk'">
                <h4>Edunet GmbH</h4>
                <p>вул. Луїзенштрасе, 41 (Luisenstr. 41) <br/>10117 Берлін</p>

                <p>
                  Директорка:<br/>
                  Дженніфер Мувай (Jeniffer Muvai)<br/>
                  E-mail: <a href="mailto:jm@edunet.works">jm@edunet.works</a>
                </p>
                <p>
                  Реєстр компаній:<br>
                  HRB 264074 B<br>
                  Місцевий суд Берлін Шарлоттенбург
                </p>
                <p>
                  Банківські реквізити:<br>
                  Berliner Sparkasse<br>
                  IBAN DE29 1005 0000 0191 3505 59
                </p>
                <p>
                  <a :href="`https://nursingcareer.ph/${lang}/privacy-policy`">
                    Політика конфіденційності
                  </a>
                </p>
              </div>
              <div class="text" v-if="lang === 'en'">
                <h4>Edunet GmbH</h4>
                <p>Luisenstr. 41 <br/>10117 Berlin</p>

                <p>
                  Managing Director:<br/>
                  Jeniffer Muvai<br/>
                  E-mail: <a href="mailto:jm@edunet.works">jm@edunet.works</a>
                </p>
                <p>
                  Company Register:<br>
                  HRB 264074 B<br>
                  Amtsgericht Berlin Charlottenburg
                </p>
                <p>
                  Bank details:<br>
                  Berliner Sparkasse<br>
                  IBAN DE29 1005 0000 0191 3505 59
                </p>
                <p>
                  <a :href="`https://edunet.works/${lang}/privacy-policy`">
                    Privacy policy
                  </a>
                </p>
              </div>
              <div class="text" v-if="lang === 'de'">
                <h4>Edunet GmbH</h4>
                <p>Luisenstr. 41 <br/>10117 Berlin</p>

                <p>
                  Geschäftsführerin:<br/>
                  Jeniffer Muvai<br/>
                  E-mail: <a href="mailto:jm@edunet.works">jm@edunet.works</a>
                </p>
                <p>
                  Handelsregister:<br>
                  HRB 264074 B<br>
                  Amtsgericht Berlin Charlottenburg
                </p>
                <p>
                  Bankverbindung:<br>
                  Berliner Sparkasse<br>
                  IBAN DE29 1005 0000 0191 3505 59
                </p>
                <p>
                  <a :href="`https://nursingcareer.ph/${lang}/privacy-policy`">
                    Datenschutzerklärung
                  </a>
                </p>
              </div>
            </div>
          </b-col>
          <b-col lg="9" md="7" sm="12" class="footer-column">
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links1" :key="`links1-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text[lang] || item.text.de }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links2" :key="`links2-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text[lang] || item.text.de }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links3" :key="`links3-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text[lang] || item.text.de }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links4" :key="`links4-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text[lang] || item.text.de }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links5" :key="`links5-${index}`">
                    <router-link :to="item.link">
                      {{ item.text[lang] || item.text.de }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>

            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links6" :key="`links6-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text[lang] || item.text.de }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="footer-widget links-widget d-inline-block">
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in links7" :key="`links7-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text[lang] || item.text.de }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="footer-bottom">
        <b-row>
          <b-col>
            <div class="clearfix">
              <div class="copyright pull-left">
                <p>
                  © {{ new Date().getFullYear() }} Edunet GmbH
                </p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </footer>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';

interface LinkItem {
  text: {}
  link?: string
  hasIcon?: boolean
}

@Component
export default class FooterComponent extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get lang() {
    return AppModule.language;
  }

  get links1() {
    const resultArr: LinkItem[] = [
      {
        text: {
          uk: 'Ласкаво просимо',
          en: 'Welcome',
          de: 'Willkommen',
        },
        link: `https://nursingcareer.ph/${this.lang}/welcome`,
      },
    ];
    return resultArr;
  }

  get links2() {
    const resultArr: LinkItem[] = [
      {
        text: {
          uk: 'Освітня мережа',
          en: 'Educational Network',
          de: 'Bildungsnetzwerk',
        },
        link: `https://nursingcareer.ph/${this.lang}/educational-network`,
      },
    ];
    return resultArr;
  }

  get links3() {
    const resultArr: LinkItem[] = [
      {
        text: {
          uk: 'Працевлаштування',
          en: 'Job Placement',
          de: 'Vermittlung',
        },
        link: `https://nursingcareer.ph/${this.lang}/job-placement`,
      },
    ];
    return resultArr;
  }

  get links4() {
    const resultArr: LinkItem[] = [
      {
        text: {
          uk: 'DaF/DaZ',
          en: 'German Language',
          de: 'DaF/DaZ',
        },
        link: `https://nursingcareer.ph/${this.lang}/german-language`,
      },
    ];
    return resultArr;
  }

  get links5() {
    const resultArr: LinkItem[] = [
      {
        text: {
          uk: 'Talent Pool',
          en: 'Talent Pool',
          de: 'Talentpool',
        },
        link: '/',
      },
    ];
    return resultArr;
  }

  get links6() {
    const resultArr: LinkItem[] = [
      {
        text: {
          uk: 'Про нас',
          en: 'About Us',
          de: 'Über uns',
        },
        link: `https://nursingcareer.ph/${this.lang}/about-us`,
      },
    ];
    return resultArr;
  }

  get links7() {
    const resultArr: LinkItem[] = [
      {
        text: {
          uk: 'Контакти',
          en: 'Contact',
          de: 'Kontakt',
        },
        link: `https://nursingcareer.ph/${this.lang}/contact`,
      },
    ];
    return resultArr;
  }
}
</script>
<style lang="scss" scoped>
[theme="default"] {
  .footer-theme {
    background: #69726A;
    padding: 2.35rem 0 0;
    .pull-left {
      float: left;
    }
    .pull-right {
      float: right;
    }
    a {
      text-decoration: none;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      color: #ffffff;

      &:hover {
        color: var(--aba-primary);
      }
    }
    ul {
      padding: 0;
    }
    h4 {
      color: #ffffff;
      font-size: 20px;
    }
    .footer-top {
      position: relative;
      display: block;
      padding: 0px 0px 30px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      p {
        color: #ffffff;
        font-size: 17px;
        line-height: 24px;
        margin-bottom: 26px;
      }
      .widget-content li {
        a {
          position: relative;
          display: inline-block;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          color: #ffffff;

          &:hover {
            color: var(--aba-primary);
          }
        }
      }
      .footer-column .links-widget {
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .footer-bottom {
      position: relative;
      padding-top: 15px;
      .copyright p {
        color: #ffffff;
        line-height: 26px;
        font-size: 16px;
        a {
          display: inline-block;
          color: #ffffff;
          text-transform: uppercase;
          &:hover {
            color:  var(--aba-primary);
          }
        }
      }
      .footer-nav li {
        position: relative;
        display: inline-block;
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
          &:before {
            display: none;
          }
        }
        a {
          position: relative;
          display: inline-block;
          font-size: 16px;
          line-height: 26px;
          font-weight: 300;
          color: #ffffff;
          &:hover {
            color: var(--aba-primary);
          }
        }
        &:before {
          position: absolute;
          content: '.';
          color: #ffffff;
          width: 2px;
          height: 16px;
          top: -3px;
          right: -12px;
        }
      }
    }
  }
}
</style>
