import {
  Applicant, LocationOption, ProfessionGroups, SearchLanguage, WorkersFilterSettingsResponse, getWorkersFiltersSettings, getWorkersGroups, getWorkersList,
} from '@/api/Workers';
import store from '@/store';
import { ProfessionQuery } from '@/types/ProfessionType';
import {
  VuexModule, Module, getModule, Action, Mutation,
} from 'vuex-module-decorators';

function isObject(object: any) {
  return object != null && typeof object === 'object';
}

function deepEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

export type SelectedCluster = {
  id: number
  title: string
}

export type FiltersState = {
  isImmediately: boolean
  orderDays: number | string
  orderMonth: number | string
  orderYear: number | string
  // selectedLand: LocationOption['location_id'] | null // DON'T REMOVE IT
  selectedLevelOfStudy: string[]
  selectedTypeOfActivity: string[]
  // selectedRequirementLevel: string[] // DON'T REMOVE IT
  selectedLanguagesLevel: SearchLanguage[]
}

type UpdateFilterPayload<T extends keyof FiltersState> = {
  propertyName: T
  value: FiltersState[T]
};

type WorkersListPagination = {
  page: number
  perPage: number
  total: number
}

export interface IWorkersState {
  selectedWorkersTab: number
  searchWorkerProfession: ProfessionQuery[]
  professionGroups: ProfessionGroups | null
  workersList: Applicant[]
  selectedApplicantContact: Applicant['id']
  showWorkers: { [key in 'firstTab' | 'secondTab']: boolean }
  workersFilters: WorkersFilterSettingsResponse | null
  selectedWorkerId: Applicant['id'] | null
  selectedCluster: SelectedCluster | null
  selectedFilterType: 'profession' | 'cluster'
  filtersState: FiltersState
  workersListPagination: WorkersListPagination
}

type WorkersUrlParams = {
  filtersState: IWorkersState['filtersState']
  pagination: IWorkersState['workersListPagination']
  profession?: IWorkersState['searchWorkerProfession']
  cluster?: IWorkersState['selectedCluster']
}

const DEFAULT_SHOW_WORKERS = {
  firstTab: false,
  secondTab: false,
};

const DEFAULT_PAGINATION = {
  page: 1,
  perPage: 20,
  total: 0,
};

const DEFAULT_FILTERS_STATE: FiltersState = {
  isImmediately: false,
  orderDays: '',
  orderMonth: '',
  orderYear: '',
  // selectedLand: null, // DON'T REMOVE IT
  selectedLevelOfStudy: [],
  selectedTypeOfActivity: [],
  // selectedRequirementLevel: [], // DON'T REMOVE IT
  selectedLanguagesLevel: [],
};

@Module({ dynamic: true, store, name: 'WorkersModule' })
class Workers extends VuexModule implements IWorkersState {
  selectedWorkersTab: IWorkersState['selectedWorkersTab'] = 0;

  searchWorkerProfession: IWorkersState['searchWorkerProfession'] = [];

  professionGroups: IWorkersState['professionGroups'] = null;

  workersList: Applicant[] = [];

  selectedApplicantContact: Applicant['id'] = -1;

  showWorkers: IWorkersState['showWorkers'] = { ...DEFAULT_SHOW_WORKERS };

  workersListPagination: IWorkersState['workersListPagination'] = { ...DEFAULT_PAGINATION };

  workersFilters: IWorkersState['workersFilters'] = null;

  selectedWorkerId: IWorkersState['selectedWorkerId'] = null;

  selectedCluster: IWorkersState['selectedCluster'] = null;

  selectedFilterType: IWorkersState['selectedFilterType'] = 'profession';

  filtersState: IWorkersState['filtersState'] = JSON.parse(JSON.stringify(DEFAULT_FILTERS_STATE));

  get isFiltersStateInDefaultState() {
    return deepEqual(this.filtersState, DEFAULT_FILTERS_STATE);
  }

  get filterByLanguageQuality() {
    return this.filtersState.selectedLanguagesLevel
      .map((item) => item.value)
      .join(',');
  }

  get filterByProfessionId() {
    return this.searchWorkerProfession
      .map((item) => item.id);
  }

  get filterByLevelOfStudy() {
    return this.filtersState.selectedLevelOfStudy.reduce((obj: { [key: string]: string }, key) => ({ ...obj, [key]: 'on' }), {});
  }

  get filterByTypeOfActivity() {
    return this.filtersState.selectedTypeOfActivity.reduce((obj: { [key: string]: string }, key) => ({ ...obj, [key]: 'on' }), {});
  }

  // DON'T REMOVE IT
  // get filterByRequirementLevel() {
  //   return this.filtersState.selectedRequirementLevel.reduce((obj: { [key: string]: string }, key) => ({ ...obj, [key]: 'on' }), {});
  // }

  get stringifiedFilters() {
    const resultFilters: WorkersUrlParams = {
      filtersState: this.filtersState,
      pagination: this.workersListPagination,
      ...(this.searchWorkerProfession.length && { profession: this.searchWorkerProfession }),
      ...(this.selectedCluster && { cluster: this.selectedCluster }),
    };
    return JSON.stringify(resultFilters);
  }

  @Mutation
  SET_SELECTED_TAB(payload: IWorkersState['selectedWorkersTab']) {
    this.selectedWorkersTab = payload;
  }

  // Filters

  @Mutation
  SET_FILTERS_STATE<T extends keyof FiltersState>({ propertyName, value }: UpdateFilterPayload<T>) {
    this.filtersState[propertyName] = value;
  }

  // End filters

  @Mutation
  SET_SELECTED_FILTER_TYPE(payload: IWorkersState['selectedFilterType']) {
    this.selectedFilterType = payload;
  }

  @Mutation
  SET_SELECTED_WORKER(payload: IWorkersState['selectedWorkerId']) {
    this.selectedWorkerId = payload;
  }

  @Mutation
  SET_SHOW_WORKERS(payload: { tab: keyof IWorkersState['showWorkers'], value: IWorkersState['showWorkers'][keyof IWorkersState['showWorkers']] }) {
    this.showWorkers[payload.tab] = payload.value;
  }

  @Mutation
  RESET_SHOW_WORKERS() {
    this.showWorkers = { ...DEFAULT_SHOW_WORKERS };
  }

  @Mutation
  SET_SELECTED_CLUSTER(payload: IWorkersState['selectedCluster']) {
    this.selectedCluster = payload;
  }

  @Mutation
  SET_SEARCH_WORKER_PROFESSION(payload: IWorkersState['searchWorkerProfession']) {
    this.searchWorkerProfession = payload;
  }

  @Mutation
  SET_WORKERS_GROUP(payload: IWorkersState['professionGroups']) {
    this.professionGroups = payload;
  }

  @Mutation
  SET_WORKERS_LIST(payload: IWorkersState['workersList']) {
    this.workersList = payload;
  }

  @Mutation
  SET_SELECTED_APPLICANT_CONTACT(payload: IWorkersState['selectedApplicantContact']) {
    this.selectedApplicantContact = payload;
  }

  @Mutation
  SET_WORKERS_PAGINATION_PAGE(payload: number) {
    this.workersListPagination.page = payload;
  }

  @Mutation
  SET_WORKERS_PAGINATION_PER_PAGE(payload: number) {
    this.workersListPagination.perPage = payload;
  }

  @Mutation
  SET_WORKERS_PAGINATION_TOTAL(payload: number) {
    this.workersListPagination.total = payload;
  }

  @Mutation
  RESET_WORKERS_PAGINATION() {
    this.workersListPagination = { ...DEFAULT_PAGINATION };
  }

  @Mutation
  SET_WORKERS_FILTER(payload: IWorkersState['workersFilters']) {
    this.workersFilters = payload;
  }

  @Mutation
  RESET_WORKERS_FILTER_STATE() {
    this.filtersState = JSON.parse(JSON.stringify(DEFAULT_FILTERS_STATE));
  }

  @Mutation
  SET_URL_FILTERS_TO_PARAMS(payload: string) {
    try {
      const parsedFilters: WorkersUrlParams = JSON.parse(payload);
      this.filtersState = parsedFilters.filtersState;
      this.workersListPagination = parsedFilters.pagination;
      this.searchWorkerProfession = parsedFilters.profession ? parsedFilters.profession : this.searchWorkerProfession;
      this.selectedCluster = parsedFilters.cluster ? parsedFilters.cluster : this.selectedCluster;
    } catch (err) {
      throw new Error(err as string);
    }
  }

  @Action
  setSelectedCluster(payload: IWorkersState['selectedCluster']) {
    this.SET_SELECTED_FILTER_TYPE('cluster');
    this.SET_SELECTED_CLUSTER(payload);
  }

  @Action
  setSearchWorkerProfession(payload: IWorkersState['searchWorkerProfession']) {
    this.SET_SELECTED_FILTER_TYPE('profession');
    this.SET_SEARCH_WORKER_PROFESSION(payload);
  }

  @Action
  resetAllWorkersFilters() {
    this.SET_SELECTED_TAB(0);
    this.SET_WORKERS_LIST([]);
    this.RESET_WORKERS_FILTER_STATE();
    this.setSearchWorkerProfession([]);
    this.RESET_SHOW_WORKERS();
    this.SET_SELECTED_CLUSTER(null);
  }

  @Action
  async fetchWorkersProfessionsGroups(payload: any) {
    const data = await getWorkersGroups({
      ...payload,
      ...(this.selectedCluster && { cluster_id: this.selectedCluster.id }),
      // selected prof deleted when we switched to multiselect of prof groups - api don't work with it
      // ...(this.filterByProfessionId.length && { profession_id: this.filterByProfessionId }),
    });
    this.SET_WORKERS_GROUP(data.profession_groups);
  }

  @Action({ rawError: true })
  async fetchWorkersList(payload: any) {
    this.SET_WORKERS_LIST([]);
    const data = await getWorkersList({
      page: this.workersListPagination.page,
      limit: this.workersListPagination.perPage,
      ...payload,
      ...(this.filterByProfessionId.length && { isco: this.filterByProfessionId }),
      ...((this.selectedCluster && this.selectedWorkersTab === 0) && { filter_cluster: this.selectedCluster.id }),
      ...(this.filtersState.isImmediately && { immediately_available: 'on' }),
      ...(this.filterByLanguageQuality && { languages: this.filterByLanguageQuality }),
      // ...(this.filtersState.selectedLand && { location: this.filtersState.selectedLand }), // DON'T REMOVE IT
      ...this.filterByLevelOfStudy,
      ...this.filterByTypeOfActivity,
      // ...this.filterByRequirementLevel, // DON'T REMOVE IT
      ...((this.filtersState.orderDays && this.filtersState.orderMonth && this.filtersState.orderYear)
        && { availability: `${this.filtersState.orderYear}-${this.filtersState.orderMonth}-${this.filtersState.orderDays}` }),
    });
    this.SET_WORKERS_LIST(data.applicants);
    this.SET_WORKERS_PAGINATION_TOTAL(data.params.total_rows);
  }

  @Action
  async fetchWorkersFiltersSettings(payload: any) {
    const data = await getWorkersFiltersSettings(payload);
    this.SET_WORKERS_FILTER(data);
  }
}

export const WorkersModule = getModule(Workers);
