export enum Languages {
  DE = 'de',
  EN = 'en',
  FR = 'fr',
  AR = 'ar',
  TR = 'tr',
  IT = 'it',
  ES = 'es',
  PT = 'pt',
  PL = 'pl',
  RO = 'ro',
  UK = 'uk',
  RU = 'ru',
}
