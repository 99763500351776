<template>
  <b-row class="right-sidebar" no-gutters>
    <b-col cols="12" md="4" xl="12" v-for="(item, index) in infoBar" :key="`bar-${index}`">
      <b-card no-body class="bg-white border mb-3 right-sidebar--item">
        <div class="card-img-top bg-aba-primary h-100">
          <b-img-lazy v-if="item.picture" :src="`${SERVER_URL}${item.picture}`" class="img img-fluid w-100" />
          <div v-else>
            <b-img-lazy v-if="sid === 'jig'" :src="require(`@/assets/img/themes/logo-work-talent.svg`)" class="img img-fluid w-100" />
            <b-img-lazy v-else-if="sid === 'talentpool'" :src="require(`@/assets/img/themes/logo-work-talentpool.svg`)" class="img img-fluid w-100" />
            <b-img-lazy v-else :src="require(`@/assets/img/logo-work-talent.svg`)" class="img img-fluid w-100" />
          </div>
        </div>
        <b-card-body class="p-2">
          <b-card-title title-tag="h5" v-html="item.label" />
          <b-card-text class="fs-6" v-html="item.info_text" />
        </b-card-body>
        <b-card-footer v-if="item.showButton" class="p-0">
          <b-button variant="primary" size="sm" class="w-100 rounded-top-0" @click="handleRegisterModal(index)">
            {{ translations[13782] }}
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { SERVER_URL } from '@/config/constant';

const INDEXES_FOR_AUTH_MODAL = [0];

interface iBar {
  id: number
  info_text: string
  label: string
  picture: null | string
}

@Component
export default class RightSidebar extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get infoBar(): iBar[] {
    return AppModule.settingPortal.info_bars.map((el: any, index: number) => {
      if (index === 0) {
        return { ...el, showButton: true };
      }
      return { ...el };
    });
  }

  get sid() {
    return AppModule.sid;
  }

  SERVER_URL = SERVER_URL;

  handleRegisterModal(index: number) {
    if (INDEXES_FOR_AUTH_MODAL.includes(index)) {
      this.$bvModal.show('register-modal');
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep {
  .card-title {
    @media (min-width: 1200px) and (max-width: 1400px) {
      font-size: 1.1rem;
    }
  }
}
</style>
