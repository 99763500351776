<template>
  <b-form autocomplete="off" @keydown="handleKeydown">
    <b-alert :show="isErrors" variant="danger">
      {{ translations[2016] }}
    </b-alert>
    <b-form-group class="mb-2" id="input-group-1" label-for="applicant-username">
      <b-form-input
        autocomplete="off"
        size="lg"
        class="border-0"
        id="applicant-username"
        v-model="login.username"
        type="text"
        :placeholder="translations[227]"
        required
      ></b-form-input>
    </b-form-group>
    <b-input-group class="mb-2" id="input-group-2" label-for="applicant-password">
      <b-form-input
        autocomplete="off"
        size="lg"
        class="border-0"
        id="applicant-password"
        v-model="login.password"
        :type="passwordFieldType"
        :placeholder="translations[228]"
        required
      >
      </b-form-input>
      <b-input-group-append>
        <b-button variant="custom-light"  @click="togglePasswordVisibility">
          <b-icon :icon="passwordFieldIcon"></b-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <p class="fs-6 m-0">
      <a v-b-modal.applicant-reset-password href="#" class="text-aba-primary-800" @click.prevent="$emit('close')">{{ translations[10945] }}</a>
    </p>
  </b-form>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';

@Component
export default class LoginApplicantForm extends Vue {
  isPasswordVisible = false;

  get translations() {
    return AppModule.translation;
  }

  get sid() {
    return AppModule.sid;
  }

  get lang() {
    return AppModule.language;
  }

  get isRedirectToCabinet() {
    return UserModule.isRedirectToCabinet;
  }

  // TokenConsultant
  get accessTokenConsultant() {
    return UserModule.accessTokenConsultant;
  }

  // TokenConsultant
  get accessTokenCompany() {
    return UserModule.accessTokenCompany;
  }

  get passwordFieldType() {
    return this.isPasswordVisible ? 'text' : 'password';
  }

  get passwordFieldIcon() {
    return this.isPasswordVisible ? 'eye-slash' : 'eye';
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.$emit('submit');
    }
  }

  isErrors = false;

  login = {
    username: '',
    password: '',
    sid: this.sid,
  };

  async onLogin() {
    try {
      if (this.accessTokenConsultant) {
        UserModule.LogOutConsultant(this.sid);
      }

      if (this.accessTokenCompany) {
        UserModule.LogOutCompany(this.sid);
      }

      this.isErrors = false;
      await UserModule.LoginApplicant(this.login).catch(() => {
        this.isErrors = true;
      });

      this.login.username = '';
      this.login.password = '';

      if (this.isRedirectToCabinet) {
        await this.$router.push({
          name: 'Cabinet',
          query: {
            lang: this.lang,
            ...(UserModule.openAgentPageOnCabinet && { agent: '1' }),
          },
        });
      } else {
        this.$emit('close');
      }
    } catch (error: any) {
      // this.$bvToast.toast(error.response.data.message, {
      //   title: 'Warning',
      //   variant: 'warning',
      //   autoHideDelay: 5000,
      //   solid: true,
      // });
    }
  }

  beforeDestroy() {
    UserModule.SET_OPEN_AGENT_PAGE(false);
  }
}
</script>
