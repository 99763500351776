import HTTP from '@/plugins/axios';

export const getTranslationData = async(params: any) => {
  const response = await HTTP.post('/translation/strings', params);
  return response.data;
};

export const getTranslationTextData = async(params: any) => {
  const response = await HTTP.post('/translation/texts', params);
  return response.data;
};

export const settingsItems = async(params: any) => {
  const response = await HTTP.get('/jobnews/settings', {
    params,
  });
  return response.data;
};
