<template>
  <b-modal
    :id="modalId"
    :title="modalTitle"
    centered
    dialog-class="modal-dialog-md-center"
    body-class="bg-primary-100"
  >
    <p class="fs-6 lh-sm mb-2">
      {{ translations[14409] }}
    </p>
    <b-form class="mt-3">
      <b-alert :show="isRecoverErrors" variant="danger">
        {{ translations[11849] }}
      </b-alert>
      <b-alert :show="isRecoverSuccess" variant="success">
        {{ translations[11850] }}
      </b-alert>
      <b-form-group class="mb-2" label-for="recover-email">
        <b-form-input
          size="lg"
          class="border-0"
          id="recover-email"
          v-model="recover.email"
          type="email"
          :placeholder="translations[5549]"
          required
        ></b-form-input>
      </b-form-group>
    </b-form>
    <template #modal-footer="{ hide }">
      <b-row class="gx-2 w-100" align-v="center">
        <b-col cols="auto">
          <b-button variant="primary" @click="onRecover">{{ translations[1165] }}</b-button>
        </b-col>
        <b-col cols="auto" class="ml-auto">
          <p class="fs-6 m-0">
            {{ translations[13733] }} <a v-b-modal.login-modal href="#" class="text-aba-primary-800" @click.prevent="hide">{{ translations[13726] }}</a>
          </p>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { passwordRecovery, passwordRecoveryCompany } from '@/api/Auth';

@Component
export default class ResetPasswordModal extends Vue {
  @Prop({ type: String, required: true }) modalId!: string;

  @Prop({ type: String, default: 'applicant' }) userType!: 'applicant' | 'company';

  get modalTitle() {
    return {
      applicant: this.translations[14436],
      company: this.translations[14437],
    }[this.userType] || '';
  }

  get resetPasswordCallback() {
    return {
      applicant: passwordRecovery,
      company: passwordRecoveryCompany,
    }[this.userType];
  }

  isRecoverSuccess = false;

  isRecoverErrors = false;

  recover = {
    email: '',
    sid: this.sid,
  }

  get sid() {
    return AppModule.sid;
  }

  get translations() {
    return AppModule.translation;
  }

  async onRecover() {
    try {
      this.isRecoverErrors = false;
      this.isRecoverSuccess = false;
      await this.resetPasswordCallback(this.recover).then(() => {
        this.isRecoverSuccess = true;
      });

      this.recover.email = '';
    } catch (error: any) {
      this.isRecoverErrors = true;
      this.$bvToast.toast(error.response.data.message, {
        title: 'Warning',
        variant: 'warning',
        autoHideDelay: 5000,
        solid: true,
      });
    }
  }
}
</script>
