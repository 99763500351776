import store from '@/store';
import {
  VuexModule, Module, Mutation, Action, getModule,
} from 'vuex-module-decorators';
import { Applicant } from '@/api/Workers';
import {
  NotepadCompany,
  NotepadItemType,
  NotepadJobs,
  addNotepadItem,
  cleanNotepad,
  getNotepadApplicantsItems,
  getNotepadCompanyItems,
  getNotepadJobsItems,
  removeNotepadItem,
} from '@/api/Notepad';

export interface INotebookState {
  notebookJobs: NotepadJobs[]
  notebookWorkers: Applicant[]
  notebookCompany: NotepadCompany[]
}

@Module({ dynamic: true, store, name: 'NotebookModule' })
class Notebook extends VuexModule implements INotebookState {
  notebookJobs: NotepadJobs[] = [];

  notebookWorkers: Applicant[] = [];

  notebookCompany: NotepadCompany[] = [];

  @Mutation
  SET_NOTEBOOK_JOBS(payload: NotepadJobs[]) {
    this.notebookJobs = payload;
  }

  @Mutation
  SET_NOTEBOOK_WORKERS(payload: Applicant[]) {
    this.notebookWorkers = payload;
  }

  @Mutation
  SET_NOTEBOOK_COMPANY(payload: NotepadCompany[]) {
    this.notebookCompany = payload;
  }

  @Mutation
  DELETE_ALL_NOTEBOOK_NOTES() {
    this.notebookJobs = [];
    this.notebookWorkers = [];
    this.notebookCompany = [];
  }

  // Applicant Notepad
  @Action({ rawError: true })
  async getApplicantNotebookData(): Promise<void> {
    try {
      await this.getNotebookJobsData();
      await this.getNotebookCompaniesData();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }
  // Applicant Notepad end

  // Company Notepad
  @Action({ rawError: true })
  async getCompanyNotebookData(): Promise<void> {
    try {
      await this.getNotebookApplicantsData();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }
  // Company Notepad end

  // Consultant Notepad
  @Action({ rawError: true })
  async getConsultantNotebookData(): Promise<void> {
    try {
      await this.getNotebookJobsData();
      await this.getNotebookApplicantsData();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }
  // Consultant Notepad end

  @Action({ rawError: true })
  async getNotebookJobsData(): Promise<void> {
    try {
      const request = await getNotepadJobsItems();
      this.SET_NOTEBOOK_JOBS(request.records);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async getNotebookApplicantsData(): Promise<void> {
    try {
      const request = await getNotepadApplicantsItems();
      this.SET_NOTEBOOK_WORKERS(request.records);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async getNotebookCompaniesData(): Promise<void> {
    try {
      const request = await getNotepadCompanyItems();
      this.SET_NOTEBOOK_COMPANY(request.records);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  @Action({ rawError: true })
  async toogleNotepadItem(data: { item_id: number, isMarked: boolean, type: NotepadItemType }): Promise<void> {
    try {
      if (data.isMarked) {
        await removeNotepadItem(data.type, data.item_id);
      } else {
        await addNotepadItem(data.type, data.item_id);
      }
      switch (data.type) {
        case 'job':
          await this.getNotebookJobsData();
          break;
        case 'applicant':
          await this.getNotebookApplicantsData();
          break;
        case 'company':
          await this.getNotebookCompaniesData();
          break;
        default:
          break;
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }

  // All
  @Action({ rawError: true })
  async DeleteAllNotebookNotes(userType: 'applicant' | 'company' | 'consultant') {
    try {
      switch (userType) {
        case 'applicant':
          await cleanNotepad('job');
          await cleanNotepad('company');
          break;
        case 'company':
          await cleanNotepad('applicant');
          break;
        case 'consultant':
          await cleanNotepad('job');
          await cleanNotepad('applicant');
          break;
        default:
          break;
      }
      this.DELETE_ALL_NOTEBOOK_NOTES();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }
}

export const NotebookModule = getModule(Notebook);
