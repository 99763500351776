import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';

@Component({
  name: 'localeString',
})
export default class extends Vue {
  get localeLang() {
    return AppModule.language;
  }

  toLocaleLangString(number: number) {
    return number.toLocaleString(this.localeLang);
  }
}
