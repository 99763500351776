<template>
  <core-sidebar
    sidebar-class="w-100 w-xl-75 w-xxl-50"
    no-close-on-route-change
    lazy backdrop shadow
    id="applicant-sidebar"
    title=""
    right
    body-class="overflow-y-hidden"
    header-class="bg-primary"
    @shown="handleShowSidebar"
    @hidden="hideMessageListener"
  >
    <template #header="{ hide }">
      <b-button class="text-white" variant="link" v-b-tooltip.hover.bottom :title="translations[10908]" @click="hide">
        <b-icon icon="x" scale="1.5" />
      </b-button>
      <div class="d-flex align-items-center justify-content-between mx-auto w-100 pr-4"  style="max-width: 1050px;">
        <b-button class="text-white" variant="link" @click="prevIterator">
          <b-icon icon="arrow-left-circle" scale="1.4" />
        </b-button>
        <div class="mx-auto">
          <span class="h5 h3-sm text-white text-center m-0 d-inline-block">{{ translations[13867] }} {{ applicantId }}</span>
        </div>
        <b-button class="text-white" variant="link" @click="nextIterator">
          <b-icon icon="arrow-right-circle" scale="1.4" />
        </b-button>
      </div>
    </template>
<!--    <b-tabs class="h-90" nav-class="mb-0 bg-aba-secondary" no-nav-style content-class="h-100">-->
<!--      <b-tab active title-link-class="text-white" class="h-100">-->
<!--        <template #title>-->
<!--          <span class="d-none d-md-inline">{{ translations[13867] }} {{ applicantId }}</span>-->
<!--        </template>-->
        <b-overlay :show="iframeLoad" rounded="sm" class="h-100">
          <div class="bg-light h-100 d-flex flex-column">
            <div class="flex-grow-1">
              <div class="h-100 mx-auto" style="max-width: 1050px;">
                <iframe
                  title="job-iframe"
                  :src="
              `https://job-server.net/jobnet/cv/${applicantId}?sid=${languageSid}&portal=1&token=${accessTokenApplicant ||
                accessTokenConsultant ||
                accessTokenCompany}&share=${shareApplicantLink}`
            "
                  width="100%"
                  height="100%"
                  @load="iframeLoad = false"
                  frameborder="0"
                  id="job-iframe"
                  ref="job-iframe"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </b-overlay>
<!--      </b-tab>-->
<!--    </b-tabs>-->
  </core-sidebar>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule, AuthTab } from '@/store/modules/app';
import { WorkersModule } from '@/store/modules/workers';
import { UserModule } from '@/store/modules/user';
import { NotebookModule } from '@/store/modules/notebook';
import { logEvent } from '@/api/EventLogger';
import { EVENT_APPLICANT_PROFILE_VIEW } from '@/config/constant';
import { getWorkerCV } from '@/api/Workers';

@Component
export default class WorkerSidebar extends Vue {
  iframeLoad = true;

  get translations() {
    return AppModule.translation;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get applicantId() {
    return WorkersModule.selectedWorkerId;
  }

  get applicantListIds() {
    return WorkersModule.workersList.map((value: any) => value.id);
  }

  get sid() {
    return AppModule.sid;
  }

  // TokenApplicant
  get accessTokenApplicant() {
    return UserModule.accessTokenApplicant;
  }

  // TokenConsultant
  get accessTokenConsultant() {
    return UserModule.accessTokenConsultant;
  }

  // TokenCompany
  get accessTokenCompany() {
    return UserModule.accessTokenCompany;
  }

  get shareApplicantLink() {
    return window.btoa(`${window.location.origin}/workers?lang=${this.$route.query.lang}&workerViewId=${this.applicantId}`);
  }

  async handleMessage(event: MessageEvent) {
    switch (event.data.message) {
      case 'login_required':
        this.$bvModal.show('not-auth-modal');
        break;
      case 'login_required_company':
        AppModule.SET_SELECTED_AUTH_TAB(AuthTab.firm);
        this.$bvModal.show('login-modal');
        break;
      case 'note_applicant':
        await this.handleToggleNotepad(event.data);
        break;
      default:
        break;
    }
  }

  async handleToggleNotepad(data: {id: number, saved: boolean}) {
    if (this.accessTokenCompany || this.accessTokenConsultant) {
      await NotebookModule.toogleNotepadItem({ type: 'applicant', item_id: data.id, isMarked: data.saved });
    }
  }

  addMessageListener() {
    window.addEventListener('message', this.handleMessage, false);
  }

  hideMessageListener() {
    window.removeEventListener('message', this.handleMessage, false);
  }

  async logApplicantView() {
    if (!this.isAuth || !this.applicantId) return;
    await logEvent({
      event_id: EVENT_APPLICANT_PROFILE_VIEW,
      object_id: this.applicantId,
    });
  }

  handleShowSidebar() {
    this.addMessageListener();
    this.logApplicantView();
    this.activeIterator();
  }

  // sidebar listing
  applicantCurrentIndex = 0;

  activeIterator() {
    this.applicantListIds.forEach((x: any, index: number) => {
      if (x === this.applicantId) {
        this.applicantCurrentIndex = index;
      }
    });
  }

  nextIterator() {
    this.iframeLoad = true;
    if (this.applicantCurrentIndex >= this.applicantListIds.length - 1) {
      this.applicantCurrentIndex = 0;
    } else {
      this.applicantCurrentIndex += 1;
    }
    WorkersModule.SET_SELECTED_WORKER(this.applicantListIds[this.applicantCurrentIndex]);
  }

  prevIterator() {
    this.iframeLoad = true;
    if (this.applicantCurrentIndex <= 0) {
      this.applicantCurrentIndex = this.applicantListIds.length - 1;
    } else {
      this.applicantCurrentIndex -= 1;
    }
    WorkersModule.SET_SELECTED_WORKER(this.applicantListIds[this.applicantCurrentIndex]);
  }
}
</script>
