<template>
  <div v-if="isShowInfoBanner" :style="{ background: background }" class="p-2 position-sticky top-0 z-3">
    <p class="mb-0" :style="{ color: fontColor }" v-html="text" />
  </div>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class InfoBanner extends Vue {
  get background() {
    return AppModule.setting.jc_info_banner_bg_color;
  }

  get fontColor() {
    return AppModule.setting.jc_info_banner_font_color;
  }

  get text() {
    return AppModule.setting.jc_info_banner_text;
  }

  get isShowInfoBanner() {
    return AppModule.setting.jc_enable_info_banner;
  }
}
</script>
