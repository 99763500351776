import { render, staticRenderFns } from "./SelectProfessionUpdate.vue?vue&type=template&id=aa8b4724&scoped=true"
import script from "./SelectProfessionUpdate.vue?vue&type=script&lang=ts"
export * from "./SelectProfessionUpdate.vue?vue&type=script&lang=ts"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./SelectProfessionUpdate.vue?vue&type=style&index=1&id=aa8b4724&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa8b4724",
  null
  
)

export default component.exports