import HTTP from '@/plugins/axios';

export const settingsMap = async(params: any) => {
  const response = await HTTP.get('/jobnews/jobmap_settings', {
    params,
  });
  return response.data;
};
export const markerMap = async(params: any) => {
  const response = await HTTP.get('/jobnews/jobmap_points', {
    params,
  });
  return response.data;
};
export const jobListMap = async(params: any, ids: any) => {
  const response = await HTTP.post('/jobnews/map_job_list', ids, {
    params,
  });
  return response.data;
};

export const jobListMapExcel = async(params: any, ids: any) => {
  const response = await HTTP.post('/jobnews/map_job_list', ids, {
    responseType: 'blob',
    params,
  });
  return response.data;
};

export const infoMarkerMap = async(params: any) => {
  const response = await HTTP.get('/jobnews/jobinfo', {
    params,
  });
  return response.data;
};
export const filterMarkerMap = async(params: any) => {
  const response = await HTTP.get('/jobnews/jobmap_filter', {
    params,
  });
  return response.data;
};

export const jobGeoData = async(params: any) => {
  const response = await HTTP.get('/jobnews/geo_data', {
    params,
  });
  return response.data;
};
