const sids = [
  'jig',
  'talentpool',
  'edunet',
  'wconat',
  'ncph',
  't4a',
  'edunetke',
  'edunetasia',
  'edunetma',
  'kh',
  'rhh',
  'he',
];

const sidsIndividualBanner = [
  'edunetke',
  'edunetasia',
  'edunetma',
];

export const group = (sid: string) => sids.includes(sid);
export const individualBannerGroup = (sid: string) => sidsIndividualBanner.includes(sid);
