<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    variant="primary"
    :opacity="0.2"
    style="min-height: 200px"
  >
    <b-card
      no-body
      class="border-0 mb-4 mt-3"
      v-show="professionsList.length">
      <b-card-body class="px-2 py-0">
        <h3 class="mb-0">
          {{ jobsTitle }}
        </h3>
      </b-card-body>
      <professions-list :is-auth="isAuth" :language-sid="languageSid" :items="professionsList" />
    </b-card>
  </b-overlay>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { ProfessionModule } from '@/store/modules/profession';
import ProfessionsList from '@/components/Home/ProfessionsList.vue';
import { UserModule } from '@/store/modules/user';
import { ClustersModule } from '@/store/modules/clusters';
import { mixins } from 'vue-class-component';
import LocaleStringMixin from '@/mixins/LocaleStringMixin';

@Component({
  components: {
    ProfessionsList,
  },
})
export default class ProfessionsComponent extends mixins(LocaleStringMixin) {
  @Prop({ default: false }) private isCluster!: boolean;

  get translations() {
    return AppModule.translation;
  }

  loading = false;

  get professions() {
    return ProfessionModule.professions;
  }

  get lang() {
    return AppModule.language;
  }

  get selectProfession() {
    return ProfessionModule.searchJobProfession;
  }

  get selectLocation() {
    return ProfessionModule.searchJobLocation;
  }

  get professionTitle() {
    return ProfessionModule.professionTitle || ClustersModule.professionTitle;
  }

  get jobsTitle() {
    if (this.selectProfession || this.selectLocation) {
      return `${this.toLocaleLangString(this.jobsCount || 0)} ${this.jobsCount === 1 ? this.translations[13772] : this.translations[13773]} ${this.selectProfession?.name || ''}${
        (this.selectProfession?.name && this.selectLocation?.name) ? ',' : ''
      } ${this.selectLocation?.name || ''}`;
    }
    return `${this.toLocaleLangString(this.jobsCount || 0)} ${this.jobsCount === 1 ? this.translations[13785] : this.translations[13774]}${this.isCluster ? `: ${this.professionTitle}` : ''}`;
  }

  get professionsList() {
    return this.professions.professions?.flat().sort((a: any, b: any) => b.amt - a.amt) || [];
  }

  get allCount(): number {
    return Number(AppModule.setting?.all_count || 0);
  }

  get clustersCount(): any {
    const id = ClustersModule.jobClusterParams.cluster_id;
    return AppModule.jobCountPortal.clusters.find((x: any) => x.id === id);
  }

  get jobsCount() {
    if (this.isCluster) {
      return this.clustersCount.count || 0;
    }
    return this.allCount;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  async loadProfessionItems() {
    this.loading = true;
    const params = {
      sid: this.languageSid,
      lang: this.lang,
      cluster_id: ClustersModule.jobClusterParams.cluster_id,
      ...(this.selectProfession && { profession_id: this.selectProfession.id }),
    };
    if (!this.isCluster) {
      delete params.cluster_id;
    }
    await ProfessionModule.GetProfession(params);
    this.loading = false;
  }

  created() {
    this.loadProfessionItems();
  }
}
</script>
