<template>
  <div class="position-relative" id="subheader">
    <b-container>
      <b-row align-v="center" no-gutters>
        <b-col cols="auto" lg="9" xl="auto" class="position-static">
          <nav class="main-nav d-none d-md-inline-block">
            <ul class="main-nav-list list-unstyled">
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jobmatch.gmbh/${lang}/index.php`" class="main-nav-link">
                    Willkommen
                  </a>
                </div>
              </li>
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jobmatch.gmbh/${lang}/Plattformen/index.php`" class="main-nav-link">
                    Plattformen
                  </a>
                </div>
              </li>
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jobmatch.gmbh/${lang}/Projektsupport/index.php`" class="main-nav-link">
                    Projektunterstützung
                  </a>
                </div>
              </li>
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jobmatch.gmbh/${lang}/DaF-DaZ/index.php`" class="main-nav-link">
                    DaF/DaZ
                  </a>
                </div>
              </li>
              <li class="main-nav-item d-none d-lg-inline-block">
                <div class="main-nav-link-wrapper">
                  <router-link
                    tabindex="0"
                    to="/"
                    class="btn btn-primary d-none d-lg-block m-0 ml-half text-white fw-bold main-nav-link-btn"
                    aria-current="page"
                  >
                    Talentpool
                  </router-link>
                  <ul>
                    <li>
                      <a :href="`https://jobmatch.gmbh/${lang}/Talentpool/FAQ.php`" title="FAQ" target="_blank" lang="de" hreflang="de">
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jobmatch.gmbh/${lang}/Ueber-uns/index.php`" class="main-nav-link">
                    Über uns
                  </a>
                </div>
              </li>
              <li class="main-nav-item">
                <div class="main-nav-link-wrapper">
                  <a target="_blank" :href="`https://jobmatch.gmbh/${lang}/Projektsupport/Kontakt/index.php`" class="main-nav-link">
                    Kontakt
                  </a>
                </div>
              </li>

            </ul>
          </nav>
        </b-col>
        <b-col cols="auto" class="d-flex ml-auto align-items-center">
          <!-- Logo -->
          <router-link to="/" class="d-block ml-2">
            <div class="py-1 py-sm-3 h-100">
              <img :src="require('@/assets/img/logo/logo-rhh.png')" alt="rhh" class="img img-fluid logo-header" />
            </div>
          </router-link>
          <!-- /Logo -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LanguageHeader from '@/components/Header/LanguageHeader.vue';
import { AppModule } from '@/store/modules/app';

@Component({
  components: {
    LanguageHeader,
  },
})
export default class HeaderInformationNavigationJig extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get lang() {
    return 'de';// AppModule.language;
  }
}
</script>
<style scoped lang="scss">
[theme="default"] {
  #subheader {
    background-color: white !important;
  }
  .logo-header {
    max-width: 150px;
    @media (min-width: 786px) {
      max-width: 200px;
    }
    @media (min-width: 1400px) {
      max-width: 230px;
    }
  }
  .main-nav-item {
    margin: 0 3px 0 0;
  }
  .main-nav-link {
    color: #68726A;
    font-weight: 500;
    background-color: #EEF1F0;
    padding: 5px 15px;
    margin: 5px 0;
    border-radius: 5px;
    transition: none;
    font-size: 14px;
    @media (min-width: 1280px) {
      font-size: 15px;
    }
    &:hover, &:active {
      background-color: var(--aba-primary);
      color: #ffffff!important;
    }
  }
  .main-nav-link-btn {
    font-weight: 500;
    padding: 5px 15px;
    margin: 5px 0;
    font-size: 14px;
    @media (min-width: 1280px) {
      font-size: 15px;
    }
  }
  .auth-cabinet .logo-header {
    height: auto;
  }
}

.main-nav-list {
  background: #ffffff;
  padding: 0;
  margin: 0;
}
.main-nav-link-wrapper {
  >a, >a:visited, >a:active, >a:hover {
    color: rgb(var(--aba-secondary-600));
    text-decoration: none;
  }
}

//sub-nav
ul.main-nav-list li:hover div > ul {
  visibility: visible;
  opacity: 1;
}
ul.main-nav-list ul {
  list-style: none;
  margin: 5px 0 0;
  padding: 10px 0 0;
  visibility: hidden;
  position: absolute;
  z-index: 99999;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear, visibility 0.2s linear;
  -moz-transition: opacity 0.2s linear, visibility 0.2s linear;
  -o-transition: opacity 0.2s linear, visibility 0.2s linear;
  transition: opacity 0.2s linear, visibility 0.2s linear;
  top: 100%;
  left: 0px;
  text-transform: none;
  font-weight: 600;
  line-height: 1.4em;
  -moz-box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 250px;
  overflow: hidden;
  background: var(--aba-primary);
}

ul.main-nav-list ul li {
  clear: both;
  width: 100%;
  background: none transparent;
}
ul.main-nav-list ul li {
  display: inline;
  float: left;
  margin: 0;
  padding: 0;
  position: relative;
  background-image: none;
  border-radius: 5px;
  margin-right: 2px;
}
ul.main-nav-list ul li a {
  padding: 3px 10px 3px 12px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  display: block;
  border: 0 none;
  clear: both;
  border-radius: 0px;
  background-color: transparent;
}
ul.main-nav-list ul li a:hover,
ul.main-nav-list ul li.active a {
  color: white;
}
ul.main-nav-list ul li:last-child a {
  padding: 3px 10px 12px 12px;
}
</style>
