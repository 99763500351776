import store from '@/store';
// eslint-disable-next-line import/named
import { ThemeColors, ThemeSids, ThemeSite } from '@/types/ThemesType';
import {
  VuexModule, Module, getModule, Action, Mutation,
} from 'vuex-module-decorators';
import { IRegister } from '@/types/Login';
import { registerCompany } from '@/api/Auth';
import { getStyles } from '@/api/Styles';

export interface IThemesState {
  sample: ThemeSite
  colors: ThemeColors
}

@Module({ dynamic: true, store, name: 'ThemesModule' })
class Themes extends VuexModule implements IThemesState {
  sample: ThemeSite = {
    sid: ThemeSids.aba,
    colors: { primary: '#d83818', secondary: '#d83818' },
    title: null,
    languageDropdown: true,
  };

  colors: ThemeColors = {} as ThemeColors;

  @Mutation
  SET_THEME(payload: ThemeSite) {
    this.sample = payload;
  }

  @Mutation
  SET_COLORS(payload: ThemeColors) {
    this.sample.colors.primary = payload.jc_color;
    this.sample.colors.secondary = payload.jc_head_color;
  }

  @Action
  public SetTheme(payload: ThemeSite) {
    this.SET_THEME(payload);
  }

  @Action({ rawError: true })
  async getThemeColors(params: {sid: string}): Promise<void> {
    if (this.colors.jc_color) {
      this.SET_COLORS(this.colors);
      return;
    }

    try {
      const request = await getStyles(params);
      if (request && request.jc_color) {
        this.SET_COLORS(request);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
      throw error;
    }
  }
}

export const ThemesModule = getModule(Themes);
