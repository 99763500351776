<template>
  <div>
    <v-select
      label="name"
      v-model="selectedProfession"
      :filterable="false"
      :options="listProfession"
      @search="searchProfession"
      :placeholder="placeholder ? placeholder : `${translations[13588]}...`"
      class="form-control"
      :class="[{'form-control-lg': lg, 'border-0': border}]"
      :autoscroll="false"
    >
      <template slot="no-options">
        {{ translations[10430] }}
      </template>
      <template #selected-option="{ name }">
        {{ name }}
<!--        <span class="d-inline-block ml-1"> ("{{ searchTextProfession }}")</span>-->
      </template>
      <template slot="option" slot-scope="listProfession">
        <div class="text-left">
          {{ listProfession.name || listProfession.description }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<script lang="ts">
import {
  Component, Vue, Prop,
} from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import axios from 'axios';

@Component
export default class SelectProfession extends Vue {
  @Prop({ default: () => ({} as any) }) value!: any;

  @Prop({ default: false }) lg!: boolean;

  @Prop({ default: false }) border!: boolean;

  @Prop({ default: '' }) placeholder!: string;

  get translations() {
    return AppModule.translation;
  }

  listProfession = [];

  private load: any;

  cancel: any = null;

  searchText: string | undefined;

  searchTextProfession: string | undefined;

  get languageSid() {
    return AppModule.languageSid;
  }

  get selectedProfession() {
    return this.value;
  }

  set selectedProfession(value: any) {
    this.$emit('input', value);
    this.$emit('change');
  }

  async loadProfessionOptions(query: string) {
    try {
      const { CancelToken } = axios;
      // let cancel;
      await axios
        .get(
          `${
            process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '/v1'
          }/jobnews/profession_query`,
          {
            params: {
              sid: this.languageSid,
              q: query,
              jobnews_type: 1,
            },
            cancelToken: new CancelToken((c) => {
              this.cancel = c;
            }),
          },
        )
        .then((res) => {
          this.listProfession = res.data;
          this.load(false);
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            this.load(true);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  searchProfession(searchText: string, loading: any) {
    this.load = loading;
    if (searchText.trim().length > 2) {
      this.searchText = searchText;
      this.searchTextProfession = searchText;
      this.load(true);
      if (this.cancel) {
        this.cancel();
      }
      this.loadProfessionOptions(searchText).then((r) => {
        if (this.searchText === searchText) {
          this.load(false);
        }
      });
    } else {
      if (this.cancel) this.cancel();
      this.listProfession = [];
      this.load(false);
    }
  }
}
</script>
