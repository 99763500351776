<template>
  <job-item-content v-bind="$attrs" v-on="$listeners" @search="searchJob" />
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import GoogleTranslate from '@/mixins/GoogleTranslate';
import JobItemContent from '@/components/Home/JobItemContent.vue';
import { ProfessionModule } from '@/store/modules/profession';
import { ClustersModule } from '@/store/modules/clusters';
import { CompanyModule } from '@/store/modules/company';

@Component({
  components: { JobItemContent },
})
export default class JobItem extends mixins(GoogleTranslate) {
  searchJob(data: { id: number, firmName: string, description: string, shortview: string }) {
    ProfessionModule.SetJobId(data.id);
    ProfessionModule.SetJobFirm(data.firmName);
    ProfessionModule.SetJobShort(data.shortview);
    CompanyModule.RESET_COMPANY_DETAIL_FILTER();
    CompanyModule.SetCompanyDetailFilter({ obj: 'idjob', value: data.id });
    ClustersModule.SetUrlIframe('');
    this.$emit('activeTab', 0);
  }
}
</script>
