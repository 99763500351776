import store from '@/store';
import {
  VuexModule, Module, getModule, Action, Mutation,
} from 'vuex-module-decorators';
import { getCompanies, getCompanyInfo, getCompanySearch } from '@/api/Company';
import { jobListItemsSearch } from '@/api/Profession';
import { JobType } from '@/types/ProfessionType';

type SelectedCompany = {
  name: string
  id: number
}

export interface ICompanyState {
  company: []
  companyJobs: any
  companyJobParams: any
  companiesListFilter: any
  companiesListQueryFilter: any
  companyJobPagination: any
  companyJobSort: any
  companyDetailFilter: any
  companyDetailJobsFilter: any
  companyItem: any
  companyClusterParams: any
  searchCompanyName: any
  selectedCompaniesInFilter: SelectedCompany[]
}

const DEFAULT_COMPANY_CLUSTER_PARAMS = {
  cluster_id: null,
  cluster_name: null,
};

@Module({ dynamic: true, store, name: 'CompanyModule' })
class Company extends VuexModule implements ICompanyState {
  public company = [] as any;

  public companyItem = {} as any;

  public companyJobs = {
    amt: 0,
    firms: [],
    jobs: [],
  } as any;

  companyClusterParams = { ...DEFAULT_COMPANY_CLUSTER_PARAMS } as any;

  companyJobParams = {
    id: 0,
    jobnews_type: 1,
  } as any;

  companiesListFilter = {
    workjob: 0,
    edujob: 0,
    praktikum: 0,
    fulltime: 0,
    parttime: 0,
    skill_level2: 0,
    skill_level3: 0,
    state_id: 0,
    cluster_id: 0,
  } as any;

  companyJobPagination = {
    per_page: 20,
    page: 1,
  } as any;

  companyJobSort = {
    date: null,
    name: 1,
  } as any;

  companiesListQueryFilter = {
    q: '',
    mode: 'AND',
    qmode: 1,
  } as any;

  companyDetailFilter = {
    // profession_id: null,
    location_id: null,
    idcomp: null,
    idjob: null,
    job_location: null,
    jobnews_type: null,
    q: '',
    mode: 'AND',
    qmode: 1,
  } as any;

  // filter for job list of selected company (in sidebar)
  companyDetailJobsFilter = {
    workjob: 0,
    edujob: 0,
    praktikum: 0,
    fulltime: 0,
    parttime: 0,
    skill_level2: 0,
    skill_level3: 0,
    state_id: 0,
    cluster_id: 0,
  } as any;

  companyType = JobType.items

  searchCompanyName = '';

  selectedCompaniesInFilter: ICompanyState['selectedCompaniesInFilter'] = [];

  get selectedCompaniesIds() {
    return this.selectedCompaniesInFilter.map((item) => item.id)
      .join(',');
  }

  @Mutation
  SET_SELECTED_COMPANIES(payload: ICompanyState['selectedCompaniesInFilter']) {
    this.selectedCompaniesInFilter = payload;
  }

  @Mutation
  SET_SEARCH_COMPANY_NAME(payload: any) {
    this.searchCompanyName = payload;
  }

  @Mutation
  RESET_COMPANIES_LIST_FILTER() {
    Object.keys(this.companiesListFilter).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(this.companiesListFilter, key)) {
        this.companiesListFilter[key] = 0;
      }
    });
  }

  @Mutation
  RESET_COMPANY_DETAIL_JOB_FILTER() {
    Object.keys(this.companyDetailJobsFilter).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(this.companyDetailJobsFilter, key)) {
        this.companyDetailJobsFilter[key] = 0;
      }
    });
  }

  @Mutation
  RESET_COMPANY_JOBS_PARAMS() {
    this.companyJobPagination.page = 1;
    this.companyJobPagination.per_page = 20;
    this.companyJobSort.date = null;
    this.companyJobSort.name = 1;
    this.companyJobParams.jobnews_type = 1;
  }

  @Mutation
  RESET_COMPANY_DETAIL_FILTER() {
    this.companyDetailFilter.profession_id = null;
    this.companyDetailFilter.location_id = null;
    this.companyDetailFilter.idcomp = null;
    this.companyDetailFilter.idjob = null;
    this.companyDetailFilter.jobnews_type = null;
  }

  @Mutation
  RESET_COMPANIES_LIST_QUERY_FILTER() {
    this.companiesListQueryFilter.q = '';
    this.companiesListQueryFilter.mode = 'AND';
    this.companiesListQueryFilter.qmode = 1;
  }

  @Mutation
  SET_QUERY_SEARCH_DEFAULT_COMPANY() {
    this.searchCompanyName = '';
  }

  @Mutation
  SET_COMPANY_CLUSTER(payload: any) {
    this.companyClusterParams = { ...payload };
  }

  @Mutation
  RESET_COMPANY_CLUSTER() {
    this.companyClusterParams = { ...DEFAULT_COMPANY_CLUSTER_PARAMS };
  }

  @Mutation
  SET_COMPANY_ITEM(item: any) {
    this.companyItem = item;
  }

  @Mutation
  private UPDATE_COMPANY_TYPE(payload: number) {
    this.companyType = payload;
  }

  @Mutation
  private UPDATE_PAGINATION_COMPANY(payload: any) {
    const { obj, value } = payload;
    this.companyJobPagination[obj] = value;
  }

  @Mutation
  private UPDATE_COMPANIES_QUERY_FILTER(payload: any) {
    const { obj, value } = payload;
    this.companiesListQueryFilter[obj] = value;
  }

  @Mutation
  private UPDATE_FULL_COMPANY_DETAIL_FILTER(payload: any) {
    this.companyDetailFilter = { ...payload };
  }

  @Mutation
  private UPDATE_COMPANY_DETAIL_FILTER(payload: any) {
    const { obj, value } = payload;
    this.companyDetailFilter[obj] = value;
  }

  @Mutation
  private UPDATE_FULL_COMPANIES_DETAIL_JOB_FILTER(payload: any) {
    this.companyDetailJobsFilter = { ...payload };
  }

  @Mutation
  private UPDATE_COMPANIES_DETAIL_JOB_FILTER(payload: any) {
    const { obj, value } = payload;
    this.companyDetailJobsFilter[obj] = value;
  }

  @Mutation
  private UPDATE_COMPANIES_LIST_FILTER(payload: any) {
    const { obj, value } = payload;
    this.companiesListFilter[obj] = value;
  }

  @Mutation
  private UPDATE_SORT_COMPANY(payload: any) {
    const { obj, value } = payload;
    this.companyJobSort[obj] = value;
  }

  @Mutation
  SET_COMPANY(items: any) {
    this.company = items;
  }

  @Mutation
  SET_COMPANY_JOBS(item: any) {
    this.companyJobs = item;
  }

  @Action({ rawError: true })
  async GetCompanyJobsSearch(payload: any) {
    const params = {
      ...payload,
      ...this.companyDetailFilter,
      ...this.companyJobParams,
      ...this.companyDetailJobsFilter,
      ...this.companyJobPagination,
    };
    const request = await jobListItemsSearch(params);
    this.SET_COMPANY_JOBS(request);
  }

  @Action({ rawError: true })
  async GetCompaniesList(payload: any) {
    const params = {
      ...this.companiesListFilter,
      ...this.companiesListQueryFilter,
      ...payload,
    };
    const request = await getCompanies(params);
    this.SET_COMPANY(request);
  }

  @Action({ rawError: true })
  async GetCompanySearch(payload: any) {
    const params = {
      ...payload,
      ...this.companiesListFilter,
      ...this.companiesListQueryFilter,
      ...(this.selectedCompaniesIds && { ids: this.selectedCompaniesIds }),
    };
    const request = await getCompanySearch(params);
    this.SET_COMPANY(request);
  }

  @Action({ rawError: true })
  async GetCompanyItem(payload: any) {
    const params = {
      ...payload,
      idcomp: this.companyDetailFilter.idcomp,
      job_id: this.companyDetailFilter.idjob,
    };
    const request = await getCompanyInfo(params);
    this.SET_COMPANY_ITEM(request);
  }

  @Action
  async SetPaginationCompany(payload: any) {
    this.UPDATE_PAGINATION_COMPANY(payload);
  }

  @Action
  async SetSortCompany(payload: any) {
    this.UPDATE_SORT_COMPANY(payload);
  }

  @Action
  async SetCompaniesQueryFilter(payload: any) {
    this.UPDATE_COMPANIES_QUERY_FILTER(payload);
  }

  @Action
  async SetFullCompanyDetailFilter(payload: any) {
    this.UPDATE_FULL_COMPANY_DETAIL_FILTER(payload);
  }

  @Action
  async SetCompanyDetailFilter(payload: any) {
    this.UPDATE_COMPANY_DETAIL_FILTER(payload);
  }

  @Action
  async SetCompaniesListFilter(payload: any) {
    this.UPDATE_COMPANIES_LIST_FILTER(payload);
  }

  @Action
  async SetCompanyDetailJobFilter(payload: any) {
    this.UPDATE_COMPANIES_DETAIL_JOB_FILTER(payload);
  }

  @Action
  async SetFullCompanyDetailJobFilter(payload: any) {
    this.UPDATE_FULL_COMPANIES_DETAIL_JOB_FILTER(payload);
  }

  @Action
  async SetCompanyType(payload: number) {
    this.UPDATE_COMPANY_TYPE(payload);
  }

  @Action
  async SetParamsDefaultCompany() {
    this.RESET_COMPANIES_LIST_FILTER();
    this.RESET_COMPANIES_LIST_QUERY_FILTER();
    this.RESET_COMPANY_JOBS_PARAMS();
    this.SET_QUERY_SEARCH_DEFAULT_COMPANY();
    this.RESET_COMPANY_CLUSTER();
  }

  @Action
  async SetDefaultCompanyItems() {
    this.SET_COMPANY([]);
  }

  @Action
  async SetDefaultCompanyJobsItems() {
    this.SET_COMPANY_JOBS({
      amt: 0,
      firms: [],
      jobs: [],
    });
  }

  @Action
  HandleCompanySidebar(payload: { id: number, title: string, location?: string }) {
    this.SET_COMPANY_JOBS([]);
    this.RESET_COMPANY_DETAIL_JOB_FILTER();
    this.RESET_COMPANY_DETAIL_FILTER();
    this.SET_SEARCH_COMPANY_NAME(payload.title);
    this.SetCompanyDetailFilter({ obj: 'idcomp', value: payload.id });
    this.SetCompanyDetailFilter({ obj: 'job_location', value: payload.location || null });
  }
}

export const CompanyModule = getModule(Company);
