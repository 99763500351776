<template>
  <information-box :is-welcome="isWelcome" :clusters-list="clusters" :category-name="translations[13439]" @search-cluster="searchCluster" />
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProfessionModule } from '@/store/modules/profession';
import { JobType } from '@/types/ProfessionType';
import { ClustersModule } from '@/store/modules/clusters';
import InformationBox from '@/components/Core/InformationBox.vue';

@Component({
  components: {
    InformationBox,
  },
})
export default class JobsInformationBox extends Vue {
  @Prop({ default: false }) private isWelcome!: boolean;

  get translations() {
    return AppModule.translation;
  }

  get clusters() {
    return AppModule.settingPortal.clusters.map((el) => ({
      ...el,
      count: this.findCount(el.id),
    }));
  }

  get jobCount(): any[] {
    return AppModule.jobCountPortal.clusters;
  }

  findCount(id: number) {
    return this.jobCount.find((x: any) => x.id === id)?.count || 0;
  }

  searchCluster(data: { id: number, title: string }) {
    ProfessionModule.SetParamsDefault();
    ProfessionModule.SetJobType(JobType.search);
    ClustersModule.SetClusterParams({ obj: 'cluster_id', value: data.id });
    ClustersModule.SetProfessionTitle(data.title);
    AppModule.SetVisibleJobs(false);
    AppModule.SetVisibleClusterJobs(true);
    if (this.isWelcome) {
      AppModule.SET_WELCOME(true);
      this.$router.push({ name: 'Jobs', query: { lang: AppModule.language } });
    }
  }
}
</script>
