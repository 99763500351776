<template>
  <footer class="footer footer-theme position-relative">
    <b-container style="max-width: 1340px">
      <div class="footer-top">
        <b-row>
          <b-col lg="3" md="4" sm="12" class="footer-column">
            <div class="footer-widget links-widget logo-widget">
              <div class="text" v-if="lang === 'uk'">
                <h4>Workconsult RTP GmbH</h4>
                <p>Petzoldstr. 14 <br />4020 Linz Austria</p>

                <p>E-mail: <a href="mailto:info@talents4austria.com">info@talents4austria.com</a><br /></p>
                <p>
                  Реєстр компаній:<br />
                  FN 615047 g<br />
                  Austrian Tax No.: 06 412/4688<br />
                  Tax ID: ATU80755602<br />
                </p>
                <p>
                  <a :href="`https://talents4austria.com/${lang}/privacy-policy`"> Політика конфіденційності </a>
                </p>
              </div>
              <div class="text" v-if="lang === 'en'">
                <h4>Workconsult RTP GmbH</h4>
                <p>Petzoldstr. 14 <br />4020 Linz Austria</p>

                <p>E-mail: <a href="mailto:info@talents4austria.com">info@talents4austria.com</a></p>
                <p>
                  Company Register:<br />
                  FN 615047 g<br />
                  Austrian Tax No.: 06 412/4688<br />
                  Tax ID: ATU80755602<br />
                </p>
                <p>
                  <a :href="`https://talents4austria.com/${lang}/privacy-policy`"> Privacy policy </a>
                </p>
              </div>
              <div class="text" v-if="lang === 'de'">
                <h4>Workconsult RTP GmbH</h4>
                <p>Petzoldstr. 14 <br />4020 Linz Austria</p>

                <p>E-mail: <a href="mailto:info@talents4austria.com">info@talents4austria.com</a></p>
                <p>
                  Firmenbuchnummer:<br />
                  FN 615047 g<br />
                  Steuernummer: 06 412/4688<br />
                  UID-Nummer: ATU80755602<br />
                </p>
                <p>
                  <a :href="`https://talents4austria.com/${lang}/privacy-policy`"> Datenschutzerklärung </a>
                </p>
              </div>
            </div>
          </b-col>
          <b-col lg="9" md="7" sm="12" class="footer-column">
            <div
              v-for="(item, index) in links" :key="`link-${index}`"
              class="footer-widget links-widget d-inline-block"
            >
              <div class="widget-content">
                <ul class="list clearfix">
                  <li>
                    <a v-if="!item.isLocalLink" :href="item.link" target="_blank">
                      {{ item.text }}
                    </a>
                    <router-link v-else :to="item.link">
                      {{ item.text }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="footer-bottom">
        <b-row>
          <b-col>
            <div class="clearfix">
              <div class="copyright pull-left">
                <p>© {{ new Date().getFullYear() }} Workconsult RTP GmbH</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </footer>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';

interface LinkItem {
  text: string
  link?: string
  hasIcon?: boolean
  isLocalLink?: boolean
}

@Component
export default class FooterComponent extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get lang() {
    return AppModule.language;
  }

  get links(): LinkItem[] {
    return [
      {
        text: this.translations[13801] || 'Willkommen',
        link: `https://talents4austria.com/${this.lang}/welcome`,
      },
      {
        text: this.translations[14996] || 'Bildungsnetzwerk',
        link: `https://talents4austria.com/${this.lang}/educational-network`,
      },
      {
        text: this.translations[2053] || 'Qualifizierung',
        link: `https://talents4austria.com/${this.lang}/german-language`,
      },
      {
        text: this.translations[6229] || 'Vermittlung',
        link: `https://talents4austria.com/${this.lang}/job-placement`,
      },
      {
        text: this.translations[14997] || 'Talentpool',
        link: '/',
        isLocalLink: true,
      },
      {
        text: this.translations[14998] || 'Über uns',
        link: `https://talents4austria.com/${this.lang}/about-us`,
      },
      {
        text: this.translations[5661] || 'Kontakt',
        link: `https://talents4austria.com/${this.lang}/contact`,
      },
    ];
  }
}
</script>
<style lang="scss" scoped>
[theme='default'] {
  .footer-theme {
    background: #69726a;
    padding: 2.35rem 0 0;
    .pull-left {
      float: left;
    }
    .pull-right {
      float: right;
    }
    a {
      text-decoration: none;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      color: #ffffff;

      &:hover {
        color: var(--aba-primary);
      }
    }
    ul {
      padding: 0;
    }
    h4 {
      color: #ffffff;
      font-size: 20px;
    }
    .footer-top {
      position: relative;
      display: block;
      padding: 0px 0px 30px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      p {
        color: #ffffff;
        font-size: 17px;
        line-height: 24px;
        margin-bottom: 26px;
      }
      .widget-content li {
        a {
          position: relative;
          display: inline-block;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          color: #ffffff;

          &:hover {
            color: var(--aba-primary);
          }
        }
      }
      .footer-column .links-widget {
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .footer-bottom {
      position: relative;
      padding-top: 15px;
      .copyright p {
        color: #ffffff;
        line-height: 26px;
        font-size: 16px;
        a {
          display: inline-block;
          color: #ffffff;
          text-transform: uppercase;
          &:hover {
            color: var(--aba-primary);
          }
        }
      }
      .footer-nav li {
        position: relative;
        display: inline-block;
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
          &:before {
            display: none;
          }
        }
        a {
          position: relative;
          display: inline-block;
          font-size: 16px;
          line-height: 26px;
          font-weight: 300;
          color: #ffffff;
          &:hover {
            color: var(--aba-primary);
          }
        }
        &:before {
          position: absolute;
          content: '.';
          color: #ffffff;
          width: 2px;
          height: 16px;
          top: -3px;
          right: -12px;
        }
      }
    }
  }
}
</style>
