<template>
  <footer class="footer">
    <b-container>
    <b-row>
      <b-col lg="auto" class="text-center text-lg-start">
        <router-link to="/" class="footer-logo nuxt-link-active">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195 70" width="130" height="46" class="logo-secondary">
            <polygon
              points="109.44 2.71 89.66 41.55 98.36 41.55 113.26 12.9 128.16 41.55 137.07 41.55 117.3 2.71 109.44 2.71"
              style="fill: #e6320f; fill-rule: evenodd"
            ></polygon>
            <polygon
              points="9.36 41.55 0.66 41.55 20.43 2.71 28.3 2.71 48.07 41.55 39.15 41.55 24.25 12.9 9.36 41.55"
              style="fill: #e6320f; fill-rule: evenodd"
            ></polygon>
            <path
              d="M72.42,34.05H62.23V25.51h9.91c4.38,0,6.32,1.61,6.32,4.22,0,3-2.27,4.32-6,4.32ZM54,41.55H72.42c8.75,0,14.51-3.56,14.51-10.66,0-5.32-2.77-7.93-7.26-9.65a9.05,9.05,0,0,0,5.1-8.38,9,9,0,0,0-2.66-6.71C79.9,3.93,76.41,2.7,72,2.7H54v7.5H70.7c3.6,0,5.6,1.44,5.6,4,0,3-2.38,4.22-6.15,4.22H54Z"
              style="fill: #e6320f; fill-rule: evenodd"
            ></path>
            <polygon points="136.77 13.13 138.15 13.13 138.15 2.72 136.77 2.72 136.77 13.13" style="fill: #e6320f; fill-rule: evenodd"></polygon>
            <path
              d="M141.21,13.13V2.72h1.3l4.38,5.78c.61.79,1.22,1.61,1.81,2.43l0,0c0-.9,0-1.95,0-3V2.72H150V13.13h-1.3l-4.38-5.78c-.61-.79-1.22-1.61-1.81-2.43h0c0,.91,0,2,0,3v5.24Z"
              style="fill: #e6320f; fill-rule: evenodd"
            ></path>
            <path
              d="M152,2.72h1.48l2.22,5.83c.35.91.68,1.8,1,2.77h0c.35-1,.69-1.87,1.05-2.81L160,2.72h1.41l-4,10.41H156Z"
              style="fill: #e6320f; fill-rule: evenodd"
            ></path>
            <polygon
              points="163.41 2.72 169.45 2.72 169.45 3.95 164.78 3.95 164.78 7.12 168.88 7.12 168.88 8.31 164.78 8.31 164.78 11.89 169.58 11.89 169.58 13.13 163.41 13.13 163.41 2.72"
              style="fill: #e6320f; fill-rule: evenodd"
            ></polygon>
            <path
              d="M172.07,11A5.46,5.46,0,0,0,175.23,12c1.25,0,2.13-.56,2.13-1.69,0-1.34-1.23-1.57-2.6-2s-2.91-1-2.91-2.92,1.35-2.9,3.38-2.9a5.86,5.86,0,0,1,3,.78V4.77a5.61,5.61,0,0,0-3-1c-1.17,0-2,.5-2,1.57,0,1.29,1.21,1.51,2.49,1.87,1.51.43,3,1,3,3s-1.4,3-3.5,3a6.54,6.54,0,0,1-3.15-.81Z"
              style="fill: #e6320f; fill-rule: evenodd"
            ></path>
            <polygon
              points="183.3 3.95 179.79 3.95 179.79 2.72 188.18 2.72 188.18 3.95 184.67 3.95 184.67 13.13 183.3 13.13 183.3 3.95"
              style="fill: #e6320f; fill-rule: evenodd"
            ></polygon>
            <path
              d="M141.51,16.94H143l1.74,5.64c.28.88.57,1.83.83,2.72h0c.26-.89.55-1.81.84-2.73l1.77-5.63h1L151,22.58c.29.92.57,1.8.84,2.72h0c.26-.89.55-1.84.82-2.73l1.75-5.63h1.39l-3.25,10.41h-1.46l-1.68-5.29c-.26-.82-.5-1.62-.74-2.41h0c-.24.79-.48,1.61-.74,2.43l-1.66,5.27h-1.49Z"
              style="fill: #e6320f; fill-rule: evenodd"
            ></path>
            <path
              d="M162.27,26.25a4,4,0,0,0,3.95-4.1,3.95,3.95,0,1,0-7.89,0,4,4,0,0,0,3.94,4.1m0-9.46a5.24,5.24,0,0,1,5.32,5.36,5.32,5.32,0,1,1-10.63,0,5.24,5.24,0,0,1,5.31-5.36"
              style="fill: #e6320f; fill-rule: evenodd"
            ></path>
            <path
              d="M173.22,21.77a3.32,3.32,0,0,0,2.05-.47,1.53,1.53,0,0,0,.62-1.29,1.59,1.59,0,0,0-.85-1.48,4.58,4.58,0,0,0-2.24-.35h-1.36v3.59Zm-3.15-4.83h2.83a6,6,0,0,1,2.92.52,2.66,2.66,0,0,1,1.44,2.43,2.78,2.78,0,0,1-1.34,2.45,3.46,3.46,0,0,1-1.2.43l3.35,4.58h-1.66l-3.19-4.45h-1.78v4.45h-1.37Z"
              style="fill: #e6320f; fill-rule: evenodd"
            ></path>
            <polygon
              points="180.58 16.94 181.95 16.94 181.95 21.66 181.98 21.67 186.55 16.94 188.26 16.94 183.56 21.77 188.66 27.35 186.87 27.35 181.98 21.98 181.95 21.99 181.95 27.35 180.58 27.35 180.58 16.94"
              style="fill: #e6320f; fill-rule: evenodd"
            ></polygon>
            <polygon
              points="154.79 31.14 160.98 31.14 160.98 32.37 156.17 32.37 156.17 35.74 160.4 35.74 160.4 36.93 156.17 36.93 156.17 41.55 154.79 41.55 154.79 31.14"
              style="fill: #e6320f; fill-rule: evenodd"
            ></polygon>
            <polygon points="163.86 41.55 165.23 41.55 165.23 31.14 163.86 31.14 163.86 41.55" style="fill: #e6320f; fill-rule: evenodd"></polygon>
            <polygon
              points="168.6 31.14 169.97 31.14 169.97 40.31 174.91 40.31 174.91 41.55 168.6 41.55 168.6 31.14"
              style="fill: #e6320f; fill-rule: evenodd"
            ></polygon>
            <path
              d="M180,35.46c-.48-.67-.94-1.36-1.4-2.05h0c0,.62,0,1.41,0,2.26v5.87h-1.33V31.14h1.33l3.71,5.29L186,31.14h1.31V41.55h-1.37V35.68c0-.85,0-1.64,0-2.26h0c-.46.69-.93,1.38-1.4,2.05l-2.29,3.25Z"
              style="fill: #e6320f; fill-rule: evenodd"
            ></path>
            <polygon
              points="5.87 63.17 4.18 63.17 4.18 59.3 0.81 53.35 2.67 53.35 5.08 57.74 7.49 53.35 9.23 53.35 5.87 59.3 5.87 63.17"
              style="fill-rule: evenodd"
            ></polygon>
            <path
              d="M12.75,61.61a1.63,1.63,0,0,0,.58-.42,1.82,1.82,0,0,0,.37-.61,2,2,0,0,0,.13-.74,2.06,2.06,0,0,0-.13-.74,1.72,1.72,0,0,0-.37-.61,1.74,1.74,0,0,0-.58-.41,1.93,1.93,0,0,0-1.54,0,1.74,1.74,0,0,0-.58.41,1.88,1.88,0,0,0-.37.61,2.3,2.3,0,0,0-.12.74,2.21,2.21,0,0,0,.12.74,2,2,0,0,0,.37.61,1.63,1.63,0,0,0,.58.42,2.05,2.05,0,0,0,1.54,0m.63-5a3.63,3.63,0,0,1,1.13.74,3.38,3.38,0,0,1,.76,1.11,3.49,3.49,0,0,1,0,2.74,3.18,3.18,0,0,1-.76,1.11,3.59,3.59,0,0,1-2.53,1,3.55,3.55,0,0,1-1.39-.27,3.68,3.68,0,0,1-1.14-.74,3.32,3.32,0,0,1-.76-1.11,3.61,3.61,0,0,1,0-2.74,3.54,3.54,0,0,1,.76-1.11,3.68,3.68,0,0,1,1.14-.74A3.55,3.55,0,0,1,12,56.35a3.64,3.64,0,0,1,1.4.27"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M19,63.17a2.64,2.64,0,0,1-.78-.49,2.46,2.46,0,0,1-.54-.79,2.54,2.54,0,0,1-.21-1.06V56.52h1.69v3.84a1.49,1.49,0,0,0,.34,1.08,1.37,1.37,0,0,0,1,.32,1.34,1.34,0,0,0,.58-.12,2,2,0,0,0,.41-.28,1.86,1.86,0,0,0,.33-.41V56.52h1.69v6.65H22v-1a2.42,2.42,0,0,1-.5.56,2.39,2.39,0,0,1-.61.4,2.05,2.05,0,0,1-.89.17,2.68,2.68,0,0,1-.93-.16"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M29.81,56.39l.36.06.35.11-.59,1.52L29.72,58l-.25-.05-.34,0a1.53,1.53,0,0,0-.66.14,2.25,2.25,0,0,0-.47.31,1.55,1.55,0,0,0-.36.45v4.35H26V56.52h1.6v1a2.32,2.32,0,0,1,.45-.58,2.57,2.57,0,0,1,.57-.38,1.66,1.66,0,0,1,.8-.18,2.87,2.87,0,0,1,.44,0"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M39.64,59.23a1.14,1.14,0,0,0-.11-.45,1.5,1.5,0,0,0-.32-.44A1.33,1.33,0,0,0,38.7,58a1.83,1.83,0,0,0-.73-.13,1.73,1.73,0,0,0-.71.13,1.71,1.71,0,0,0-.5.32,1.43,1.43,0,0,0-.3.44,1.59,1.59,0,0,0-.13.46Zm-.37-2.62a3.11,3.11,0,0,1,1.07.7,3.36,3.36,0,0,1,.73,1.1,3.55,3.55,0,0,1,.27,1.41v.26c0,.11,0,.24,0,.37h-5a1.4,1.4,0,0,0,.2.46,2.15,2.15,0,0,0,1,.76,2,2,0,0,0,.72.12,2.59,2.59,0,0,0,.78-.1,4,4,0,0,0,.57-.23,2.69,2.69,0,0,0,.48-.34l.84,1.26a3.84,3.84,0,0,1-.73.48,4.91,4.91,0,0,1-.84.33,4.08,4.08,0,0,1-2.52-.13,3.56,3.56,0,0,1-1.14-.74,3.32,3.32,0,0,1-.76-1.11,3.46,3.46,0,0,1-.28-1.37,3.62,3.62,0,0,1,.26-1.36,3.33,3.33,0,0,1,.7-1.11,3.21,3.21,0,0,1,1.06-.74A3.31,3.31,0,0,1,38,56.35a3.35,3.35,0,0,1,1.3.26Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M46.53,61.81a2.16,2.16,0,0,0,.48-.27,2.4,2.4,0,0,0,.4-.38v-.68a1.84,1.84,0,0,0-.43-.17,2.28,2.28,0,0,0-.47-.12,3.8,3.8,0,0,0-.62,0,2,2,0,0,0-.42,0,1.28,1.28,0,0,0-.4.15.83.83,0,0,0-.3.29.72.72,0,0,0-.12.45.71.71,0,0,0,.11.43.79.79,0,0,0,.29.25,1.47,1.47,0,0,0,.4.14l.41,0a1.78,1.78,0,0,0,.67-.12M48.3,57a2.25,2.25,0,0,1,.8,1.81v4.33H47.53v-.84a2.54,2.54,0,0,1-.53.5,2.87,2.87,0,0,1-.68.35,2.84,2.84,0,0,1-1,.15,2.79,2.79,0,0,1-.91-.14,2.34,2.34,0,0,1-.77-.44,2,2,0,0,1-.54-.7,2.16,2.16,0,0,1-.19-.94,1.91,1.91,0,0,1,.2-.89,2.22,2.22,0,0,1,.56-.7,2.61,2.61,0,0,1,.84-.45,3,3,0,0,1,1-.16,3.88,3.88,0,0,1,.81.07,3.17,3.17,0,0,1,.58.16,2.29,2.29,0,0,1,.47.24V59a1,1,0,0,0-.14-.54,1.23,1.23,0,0,0-.36-.36,1.44,1.44,0,0,0-.46-.19,2.1,2.1,0,0,0-.48-.06,3.63,3.63,0,0,0-.73.08c-.24,0-.45.11-.64.16s-.43.16-.63.25L43.42,57a5.65,5.65,0,0,1,.79-.31,8.56,8.56,0,0,1,.84-.21,5.47,5.47,0,0,1,1-.09A3.38,3.38,0,0,1,48.3,57"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M51.88,60.91a3.87,3.87,0,0,0,.59.48,3.47,3.47,0,0,0,.64.33,2.21,2.21,0,0,0,.78.14,2.66,2.66,0,0,0,.9-.14.49.49,0,0,0,.37-.46.46.46,0,0,0-.28-.45,5.78,5.78,0,0,0-1-.28,7,7,0,0,1-1-.27,3.12,3.12,0,0,1-.79-.42,2,2,0,0,1-.54-.59,1.63,1.63,0,0,1-.2-.82,1.65,1.65,0,0,1,.19-.77A2.25,2.25,0,0,1,52.1,57a2.89,2.89,0,0,1,.91-.47,3.78,3.78,0,0,1,1.22-.18,4.11,4.11,0,0,1,1,.13,5,5,0,0,1,.78.28,3.81,3.81,0,0,1,.69.41L55.9,58.4c-.16-.1-.32-.2-.5-.29a2.68,2.68,0,0,0-.53-.2,2.2,2.2,0,0,0-.64-.08,2,2,0,0,0-.84.16.5.5,0,0,0-.37.47.45.45,0,0,0,.37.43,10,10,0,0,0,1.21.31,9.2,9.2,0,0,1,.91.24,2.76,2.76,0,0,1,.72.39,1.68,1.68,0,0,1,.47.59,2,2,0,0,1,.17.87,1.66,1.66,0,0,1-.22.8,2.09,2.09,0,0,1-.61.65,3,3,0,0,1-.92.43,4,4,0,0,1-1.17.16,4.3,4.3,0,0,1-1.3-.18,5.24,5.24,0,0,1-.94-.42,3.67,3.67,0,0,1-.77-.6Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M59.1,64.55a1.63,1.63,0,0,0,.29.23,2.64,2.64,0,0,0,.29.14A.7.7,0,0,0,60,65a2.11,2.11,0,0,0,.38,0,.76.76,0,0,0,.28-.15,1,1,0,0,0,.24-.29,3.37,3.37,0,0,0,.24-.5l.27-.69-3.25-6.8H60l2.26,4.87L64,56.52h1.81l-3.24,8.13a2.92,2.92,0,0,1-1.1,1.5,2.65,2.65,0,0,1-1.4.41,2.3,2.3,0,0,1-.71-.11,2.94,2.94,0,0,1-.56-.24,2.49,2.49,0,0,1-.52-.35Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M73.94,61.81a2,2,0,0,0,.49-.27,2.4,2.4,0,0,0,.4-.38v-.68a1.84,1.84,0,0,0-.43-.17,2.47,2.47,0,0,0-.47-.12,3.83,3.83,0,0,0-.63,0,2,2,0,0,0-.41,0,1.28,1.28,0,0,0-.4.15.86.86,0,0,0-.31.29.79.79,0,0,0-.12.45.71.71,0,0,0,.12.43.79.79,0,0,0,.29.25,1.36,1.36,0,0,0,.4.14,2.83,2.83,0,0,0,.41,0,1.69,1.69,0,0,0,.66-.12M75.72,57a2.25,2.25,0,0,1,.8,1.81v4.33H75v-.84a2.54,2.54,0,0,1-.53.5,2.73,2.73,0,0,1-1.67.5,2.73,2.73,0,0,1-.9-.14,2.34,2.34,0,0,1-.77-.44,2,2,0,0,1-.54-.7,2.16,2.16,0,0,1-.2-.94,1.91,1.91,0,0,1,.21-.89,2.09,2.09,0,0,1,.56-.7,2.66,2.66,0,0,1,.83-.45,3.13,3.13,0,0,1,1-.16,3.88,3.88,0,0,1,.81.07,3.17,3.17,0,0,1,.58.16,2.58,2.58,0,0,1,.47.24V59a1,1,0,0,0-.15-.54,1.1,1.1,0,0,0-.35-.36,1.44,1.44,0,0,0-.46-.19,2.1,2.1,0,0,0-.48-.06,3.55,3.55,0,0,0-.73.08c-.24,0-.45.11-.64.16s-.44.16-.63.25L70.84,57a5.65,5.65,0,0,1,.79-.31,8.56,8.56,0,0,1,.84-.21,5.47,5.47,0,0,1,1-.09,3.38,3.38,0,0,1,2.25.68"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M82.81,61.64a2.54,2.54,0,0,0,.5-.28,1.94,1.94,0,0,0,.4-.41l1.11,1.12a3.25,3.25,0,0,1-.7.63,4.41,4.41,0,0,1-.87.44,3.34,3.34,0,0,1-1.19.19,3.6,3.6,0,0,1-1.4-.27,3.72,3.72,0,0,1-1.15-.74,3.48,3.48,0,0,1-.76-3.85,3.54,3.54,0,0,1,.76-1.11,3.72,3.72,0,0,1,1.15-.74,3.6,3.6,0,0,1,1.4-.27,3.52,3.52,0,0,1,1.18.19,4,4,0,0,1,.86.43,3.4,3.4,0,0,1,.69.62l-1.17,1a2.21,2.21,0,0,0-.39-.35,2.12,2.12,0,0,0-.5-.24,2,2,0,0,0-.67-.11,2,2,0,0,0-.78.16,1.78,1.78,0,0,0-.59.41,1.88,1.88,0,0,0-.37.61,2.07,2.07,0,0,0-.14.74,2.06,2.06,0,0,0,.51,1.35,1.67,1.67,0,0,0,.59.42,2,2,0,0,0,.78.15,2.11,2.11,0,0,0,.75-.12"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M90.36,61.64a2.54,2.54,0,0,0,.5-.28,1.94,1.94,0,0,0,.4-.41l1.11,1.12a3.25,3.25,0,0,1-.7.63,4.41,4.41,0,0,1-.87.44,3.34,3.34,0,0,1-1.19.19,3.6,3.6,0,0,1-1.4-.27,3.56,3.56,0,0,1-1.14-.74,3.35,3.35,0,0,1-.77-1.11,3.49,3.49,0,0,1,0-2.74,3.59,3.59,0,0,1,3.31-2.12,3.52,3.52,0,0,1,1.18.19,4,4,0,0,1,.86.43,2.94,2.94,0,0,1,.69.62l-1.17,1a2.21,2.21,0,0,0-.39-.35,2.24,2.24,0,0,0-.49-.24,2.06,2.06,0,0,0-.68-.11,2,2,0,0,0-.78.16,1.78,1.78,0,0,0-.59.41,1.88,1.88,0,0,0-.37.61,2.06,2.06,0,0,0-.13.74,2,2,0,0,0,.5,1.35,1.67,1.67,0,0,0,.59.42,2,2,0,0,0,.78.15,2.11,2.11,0,0,0,.75-.12"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M98.6,59.23a1.37,1.37,0,0,0-.12-.45,1.47,1.47,0,0,0-.31-.44,1.45,1.45,0,0,0-.52-.33,1.8,1.8,0,0,0-.73-.13,1.65,1.65,0,0,0-.7.13,1.48,1.48,0,0,0-.5.32,1.31,1.31,0,0,0-.31.44,2,2,0,0,0-.12.46Zm-.38-2.62a3.24,3.24,0,0,1,1.08.7,3.5,3.5,0,0,1,.72,1.1,3.55,3.55,0,0,1,.27,1.41v.26c0,.11,0,.24,0,.37h-5a1.19,1.19,0,0,0,.19.46,2,2,0,0,0,.4.43,2.07,2.07,0,0,0,.56.33,2.09,2.09,0,0,0,.72.12,2.59,2.59,0,0,0,.78-.1,4,4,0,0,0,.57-.23,2.69,2.69,0,0,0,.48-.34l.85,1.26a3.93,3.93,0,0,1-.74.48,5.12,5.12,0,0,1-.83.33,3.83,3.83,0,0,1-1.11.14,3.7,3.7,0,0,1-1.42-.27,3.8,3.8,0,0,1-1.14-.74,3.4,3.4,0,0,1-1-2.48,3.61,3.61,0,0,1,.25-1.36,3.33,3.33,0,0,1,.7-1.11,3.21,3.21,0,0,1,1.06-.74,3.35,3.35,0,0,1,1.34-.28,3.32,3.32,0,0,1,1.3.26Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M102.63,60.91a3.51,3.51,0,0,0,.6.48,3.07,3.07,0,0,0,.63.33,2.26,2.26,0,0,0,.79.14,2.6,2.6,0,0,0,.89-.14c.25-.1.38-.25.38-.46a.47.47,0,0,0-.29-.45,5.53,5.53,0,0,0-1-.28,6.83,6.83,0,0,1-.94-.27,3,3,0,0,1-.79-.42,1.78,1.78,0,0,1-.54-.59,1.54,1.54,0,0,1-.21-.82,1.79,1.79,0,0,1,.19-.77,2.15,2.15,0,0,1,.56-.66,2.73,2.73,0,0,1,.9-.47,3.88,3.88,0,0,1,1.23-.18,4,4,0,0,1,1,.13,4.33,4.33,0,0,1,.78.28,3.23,3.23,0,0,1,.69.41l-.84,1.23c-.15-.1-.32-.2-.49-.29a2.94,2.94,0,0,0-.53-.2,2.25,2.25,0,0,0-.64-.08,2.08,2.08,0,0,0-.85.16.51.51,0,0,0-.37.47.46.46,0,0,0,.37.43,10.5,10.5,0,0,0,1.21.31,9.2,9.2,0,0,1,.91.24,2.47,2.47,0,0,1,.72.39,1.68,1.68,0,0,1,.47.59,2,2,0,0,1,.17.87,1.56,1.56,0,0,1-.22.8,2,2,0,0,1-.6.65,3.07,3.07,0,0,1-.93.43,3.9,3.9,0,0,1-1.17.16,4.23,4.23,0,0,1-1.29-.18,5.37,5.37,0,0,1-1-.42,4,4,0,0,1-.77-.6Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M110.08,60.91a3.51,3.51,0,0,0,.6.48,3.36,3.36,0,0,0,.63.33,2.26,2.26,0,0,0,.79.14,2.6,2.6,0,0,0,.89-.14c.25-.1.38-.25.38-.46a.47.47,0,0,0-.28-.45,6,6,0,0,0-1-.28,6.83,6.83,0,0,1-.94-.27,3.12,3.12,0,0,1-.79-.42,1.78,1.78,0,0,1-.54-.59,1.54,1.54,0,0,1-.21-.82,1.79,1.79,0,0,1,.19-.77,2.29,2.29,0,0,1,.56-.66,2.73,2.73,0,0,1,.9-.47,3.88,3.88,0,0,1,1.23-.18,4,4,0,0,1,1,.13,4.77,4.77,0,0,1,.79.28,3.81,3.81,0,0,1,.69.41l-.85,1.23a4.53,4.53,0,0,0-.49-.29,2.94,2.94,0,0,0-.53-.2,2.25,2.25,0,0,0-.64-.08,2.08,2.08,0,0,0-.85.16.5.5,0,0,0-.36.47.45.45,0,0,0,.36.43,10.5,10.5,0,0,0,1.21.31,8.51,8.51,0,0,1,.91.24,2.47,2.47,0,0,1,.72.39,1.68,1.68,0,0,1,.47.59,1.88,1.88,0,0,1,.17.87,1.56,1.56,0,0,1-.22.8,2,2,0,0,1-.6.65,3.07,3.07,0,0,1-.93.43,3.87,3.87,0,0,1-1.16.16,4.25,4.25,0,0,1-1.3-.18,4.94,4.94,0,0,1-.94-.42,3.74,3.74,0,0,1-.78-.6Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M120.28,56.52h1.31v-2h1.69v2h2V58h-2v2.67a1,1,0,0,0,.27.83.92.92,0,0,0,.61.22,1.12,1.12,0,0,0,.27,0,.85.85,0,0,0,.22-.09l.21-.11.62,1.37a2.56,2.56,0,0,1-.44.21,2.71,2.71,0,0,1-.5.15,2.76,2.76,0,0,1-.67.07,2.44,2.44,0,0,1-.9-.16,2.11,2.11,0,0,1-.72-.48,2.06,2.06,0,0,1-.48-.74,2.5,2.5,0,0,1-.18-1V58h-1.31Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M131.05,61.61a1.63,1.63,0,0,0,.58-.42,1.82,1.82,0,0,0,.37-.61,2,2,0,0,0,.13-.74,2.06,2.06,0,0,0-.13-.74,1.72,1.72,0,0,0-.37-.61,1.74,1.74,0,0,0-.58-.41,1.93,1.93,0,0,0-1.54,0,1.74,1.74,0,0,0-.58.41,1.88,1.88,0,0,0-.37.61,2.3,2.3,0,0,0-.12.74,2.21,2.21,0,0,0,.12.74,2,2,0,0,0,.37.61,1.63,1.63,0,0,0,.58.42,2.05,2.05,0,0,0,1.54,0m.63-5a3.63,3.63,0,0,1,1.13.74,3.38,3.38,0,0,1,.76,1.11,3.49,3.49,0,0,1,0,2.74,3.18,3.18,0,0,1-.76,1.11,3.59,3.59,0,0,1-2.53,1,3.55,3.55,0,0,1-1.39-.27,3.68,3.68,0,0,1-1.14-.74,3.32,3.32,0,0,1-.76-1.11,3.61,3.61,0,0,1,0-2.74,3.54,3.54,0,0,1,.76-1.11,3.68,3.68,0,0,1,1.14-.74,3.55,3.55,0,0,1,1.39-.27,3.64,3.64,0,0,1,1.4.27"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M144,59.3l-1.48-3.83-1.48,3.83Zm-4.47,3.87h-1.7l3.83-9.82h1.72l3.83,9.82h-1.7l-.91-2.33h-4.17Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M150.27,63.17a2.4,2.4,0,0,1-.78-.49,2.31,2.31,0,0,1-.54-.79,2.54,2.54,0,0,1-.21-1.06V56.52h1.69v3.84a1.49,1.49,0,0,0,.34,1.08,1.36,1.36,0,0,0,1,.32,1.29,1.29,0,0,0,.57-.12,1.72,1.72,0,0,0,.41-.28,1.9,1.9,0,0,0,.34-.41V56.52h1.69v6.65h-1.58v-1a2.17,2.17,0,0,1-.49.56,2.39,2.39,0,0,1-.61.4,2.07,2.07,0,0,1-.9.17,2.62,2.62,0,0,1-.92-.16"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M157.63,60.91a3.44,3.44,0,0,0,.59.48,3.7,3.7,0,0,0,.63.33,2.3,2.3,0,0,0,.79.14,2.66,2.66,0,0,0,.9-.14.49.49,0,0,0,.37-.46.46.46,0,0,0-.28-.45,6,6,0,0,0-1-.28,7,7,0,0,1-1-.27,3.12,3.12,0,0,1-.79-.42,2,2,0,0,1-.54-.59,1.54,1.54,0,0,1-.2-.82,1.65,1.65,0,0,1,.19-.77,2.11,2.11,0,0,1,.55-.66,3,3,0,0,1,.9-.47,3.88,3.88,0,0,1,1.23-.18,4.11,4.11,0,0,1,1,.13,5,5,0,0,1,.78.28,3.81,3.81,0,0,1,.69.41l-.85,1.23a4.53,4.53,0,0,0-.49-.29,2.94,2.94,0,0,0-.53-.2,2.2,2.2,0,0,0-.64-.08,2.08,2.08,0,0,0-.85.16.5.5,0,0,0-.36.47.45.45,0,0,0,.36.43,10.25,10.25,0,0,0,1.22.31,9.2,9.2,0,0,1,.91.24,2.55,2.55,0,0,1,.71.39,1.71,1.71,0,0,1,.48.59,2,2,0,0,1,.17.87,1.66,1.66,0,0,1-.22.8,2.09,2.09,0,0,1-.61.65,3.13,3.13,0,0,1-.92.43,4,4,0,0,1-1.17.16,4.3,4.3,0,0,1-1.3-.18,5.24,5.24,0,0,1-.94-.42,4,4,0,0,1-.78-.6Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M164.09,56.52h1.31v-2h1.69v2h2V58h-2v2.67a1,1,0,0,0,.27.83.92.92,0,0,0,.61.22,1.12,1.12,0,0,0,.27,0,.85.85,0,0,0,.22-.09l.21-.11.62,1.37a2.56,2.56,0,0,1-.44.21,2.71,2.71,0,0,1-.5.15,2.76,2.76,0,0,1-.67.07,2.47,2.47,0,0,1-.9-.16,2.11,2.11,0,0,1-.72-.48,2.36,2.36,0,0,1-.48-.74,2.5,2.5,0,0,1-.18-1V58h-1.31Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M175,56.39l.36.06.35.11-.59,1.52-.21-.09-.25-.05-.34,0a1.49,1.49,0,0,0-.66.14,2.25,2.25,0,0,0-.47.31,1.55,1.55,0,0,0-.36.45v4.35h-1.7V56.52h1.61v1a2.32,2.32,0,0,1,.45-.58,2.57,2.57,0,0,1,.57-.38,1.66,1.66,0,0,1,.8-.18,2.87,2.87,0,0,1,.44,0"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M177.4,63.17h1.69V56.52H177.4Zm.06-9.58a1.09,1.09,0,0,1,.79-.32,1.09,1.09,0,0,1,1.11,1.11,1.09,1.09,0,0,1-1.11,1.1,1,1,0,0,1-.79-.32,1,1,0,0,1-.33-.78,1.07,1.07,0,0,1,.33-.79Z"
              style="fill-rule: evenodd"
            ></path>
            <path
              d="M184.82,61.81a2.25,2.25,0,0,0,.49-.27,2,2,0,0,0,.39-.38v-.68a1.6,1.6,0,0,0-.42-.17,2.47,2.47,0,0,0-.47-.12,3.93,3.93,0,0,0-.63,0,2,2,0,0,0-.41,0,1.44,1.44,0,0,0-.41.15.92.92,0,0,0-.3.29.79.79,0,0,0-.12.45.78.78,0,0,0,.11.43.91.91,0,0,0,.3.25,1.3,1.3,0,0,0,.39.14l.41,0a1.71,1.71,0,0,0,.67-.12M186.6,57a2.25,2.25,0,0,1,.79,1.81v4.33h-1.56v-.84a2.37,2.37,0,0,1-.54.5,2.61,2.61,0,0,1-.67.35,2.87,2.87,0,0,1-1,.15,2.73,2.73,0,0,1-.9-.14,2.5,2.5,0,0,1-.78-.44,2.06,2.06,0,0,1-.53-.7,2.16,2.16,0,0,1-.2-.94,1.91,1.91,0,0,1,.21-.89,2.09,2.09,0,0,1,.56-.7,2.66,2.66,0,0,1,.83-.45,3.11,3.11,0,0,1,1-.16,3.88,3.88,0,0,1,.81.07,3.3,3.3,0,0,1,.59.16,2.2,2.2,0,0,1,.46.24V59a1,1,0,0,0-.14-.54,1.1,1.1,0,0,0-.35-.36,1.5,1.5,0,0,0-.47-.19,2,2,0,0,0-.47-.06,3.55,3.55,0,0,0-.73.08l-.64.16c-.23.08-.44.16-.64.25L181.72,57a5.51,5.51,0,0,1,.78-.31c.25-.08.53-.14.85-.21a5.39,5.39,0,0,1,1-.09,3.38,3.38,0,0,1,2.25.68"
              style="fill-rule: evenodd"
            ></path>
          </svg>
          <span class="sr-only">WORK in AUSTRIA</span>
        </router-link>
      </b-col>
      <b-col lg="6" class="d-flex justify-content-center">
        <nav class="footer-secondary-nav">
          <ul class="list-unstyled d-flex align-items-center align-items-lg-start flex-column flex-lg-row">
            <li v-for="(link, index) in generalLinks" :key="index" class="footer-secondary-nav-item d-inline-flex">
              <a :href="link.link()" target="_blank" rel="noopener noreferrer" class="footer-secondary-nav-link d-inline-flex align-items-center">
                {{ link.text }}
                <svg
                  v-if="link.hasIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="16"
                  height="16"
                  class="icon icon-external-link"
                >
                  <path
                    d="M14.67.89a.45.45,0,0,1,.44.36V8.14a.45.45,0,0,1-.45.44.44.44,0,0,1-.44-.36V2.41L7.87,8.77a.45.45,0,0,1-.63,0,.44.44,0,0,1,0-.57l0-.06L13.6,1.78H7.87a.45.45,0,0,1-.44-.37V1.33A.44.44,0,0,1,7.79.9h6.88ZM5.77,4.44a.45.45,0,0,1,.08.89H1.77v8.89h8.89V9.78A.45.45,0,0,1,11,9.34h.08a.44.44,0,0,1,.43.37v5a.44.44,0,0,1-.36.43H1.33a.45.45,0,0,1-.44-.36V4.89a.45.45,0,0,1,.37-.44H5.77Z"
                  ></path>
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </b-col>
      <b-col cols="12" class="order-lg-1">
        <div class="footer-primary">
          <b-row align-v="center">
            <b-col lg="7" xl="6" class="order-lg-1">
              <nav class="footer-primary-nav">
                <ul class="list-inline footer-primary-nav-list">
                  <template v-for="(linkItem, index) in documentsLinks">
                    <li v-if="linkItem.visibility" :key="index" class="list-inline-item footer-primary-nav-item">
                      <a :href="linkItem.link()" target="_blank" rel="noopener noreferrer" class="footer-primary-nav-link link-hover-underlined">
                        {{ linkItem.text }}
                        <svg v-if="linkItem.hasIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" class="icon icon-external-link">
                          <path
                            d="M14.67.89a.45.45,0,0,1,.44.36V8.14a.45.45,0,0,1-.45.44.44.44,0,0,1-.44-.36V2.41L7.87,8.77a.45.45,0,0,1-.63,0,.44.44,0,0,1,0-.57l0-.06L13.6,1.78H7.87a.45.45,0,0,1-.44-.37V1.33A.44.44,0,0,1,7.79.9h6.88ZM5.77,4.44a.45.45,0,0,1,.08.89H1.77v8.89h8.89V9.78A.45.45,0,0,1,11,9.34h.08a.44.44,0,0,1,.43.37v5a.44.44,0,0,1-.36.43H1.33a.45.45,0,0,1-.44-.36V4.89a.45.45,0,0,1,.37-.44H5.77Z"
                          ></path>
                        </svg>
                      </a>
                    </li>
                  </template>
                </ul>
              </nav>
            </b-col>
            <b-col lg="2" class="order-lg-0">
              <nav class="social-nav">
                <ul class="social-nav-list list-unstyled list-inline">
                  <li class="social-nav-item list-inline-item">
                    <a
                      target="_blank"
                      rel="noopener"
                      href="https://www.facebook.com/ABAWorkinAustria/"
                      class="social-nav-link social-nav-link--facebook"
                    >
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" class="icon icon-facebook">
                          <path
                            d="M8,.5a7.55,7.55,0,0,1,1.17,15h0V10.23h1.75l.33-2.18H9.17V6.63a1.09,1.09,0,0,1,1.22-1.18h.95V3.59s-.54-.09-1.17-.13H9.66A2.65,2.65,0,0,0,6.83,6.38h0V8.05H4.92v2.18H6.83V15.5A7.55,7.55,0,0,1,8,.5Z"
                            style="fill-rule: evenodd"
                          ></path>
                        </svg>
                      </span>
                      <span class="sr-only">Facebook</span></a
                    >
                  </li>
                  <li class="social-nav-item list-inline-item">
                    <a
                      target="_blank"
                      rel="noopener"
                      href="https://www.youtube.com/user/InvestInAustria"
                      class="social-nav-link social-nav-link--youtube"
                    >
                      <span
                        ><!---->
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" class="icon icon-youtube">
                          <path d="M8,.5H8A7.5,7.5,0,0,1,15.5,8h0A7.5,7.5,0,0,1,8,15.5H8A7.5,7.5,0,0,1,.5,8h0A7.5,7.5,0,0,1,8,.5Z"></path>
                          <path
                            d="M8,4.46a30.62,30.62,0,0,0-4,.21,1.32,1.32,0,0,0-.89.9A13.21,13.21,0,0,0,2.94,8a13.15,13.15,0,0,0,.21,2.43,1.3,1.3,0,0,0,.89.9,30.62,30.62,0,0,0,4,.21,30.62,30.62,0,0,0,4-.21,1.24,1.24,0,0,0,.88-.89A12.63,12.63,0,0,0,13.06,8a12.71,12.71,0,0,0-.22-2.44A1.24,1.24,0,0,0,12,4.68,29.24,29.24,0,0,0,8,4.46Zm-1,2L9.62,8,7,9.51Z"
                            style="fill: #fff"
                          ></path>
                        </svg>
                        <!---->
                        <!---->
                        <!---->
                        <!---->
                        <!---->
                        <!----></span
                      >
                      <span class="sr-only">Youtube</span>
                    </a>
                  </li>
                  <li class="social-nav-item list-inline-item">
                    <a
                      target="_blank"
                      rel="noopener"
                      href="https://www.linkedin.com/company/aba-work-in-austria/"
                      class="social-nav-link social-nav-link--linkedin"
                    >
                      <span
                        ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" class="icon icon-linkedin">
                          <path
                            d="M8,.5A7.5,7.5,0,1,1,.5,8,7.5,7.5,0,0,1,8,.5ZM5.93,6.74H4.37v5H5.93Zm4-.12a1.61,1.61,0,0,0-1.47.81h0V6.74H6.9v5H8.46V9.16c0-.61.17-1.17.93-1.17s.8.66.81,1.22h0v2.54h1.55V8.85C11.73,7.58,11.4,6.62,9.89,6.62ZM5.15,4.25a.91.91,0,1,0,.9.9A.9.9,0,0,0,5.15,4.25Z"
                            style="fill-rule: evenodd"
                          ></path>
                        </svg>
                        <!---->
                        <!---->
                        <!---->
                        <!----></span
                      >
                      <span class="sr-only">Linkedin</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </b-col>
            <b-col lg="3" xl="4" class="d-none d-lg-block text-lg-end order-lg-3"
              ><span class="small footer-primary-copyright">© {{ new Date().getFullYear() }} by Austrian Business Agency all rights reserved</span>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12" lg="4" class="order-lg-0">
        <b-row class="justify-content-end">
          <b-col cols="12" sm="6" lg="auto">
            <address class="small footer-contact text-sm-right">
              Opernring 3<br />
              A-1010 Wien
            </address>
          </b-col>
          <b-col cols="12" sm="6" lg="auto">
            <address class="small footer-contact">
              <div><a class="footer-contact-link" href="mailTo:workinaustria@aba.gv.at">workinaustria@aba.gv.at</a></div>
              <br />
            </address>
          </b-col>
          <b-col cols="12" class="d-lg-none pt-half text-center">
            <span class="small">© {{ new Date().getFullYear() }} by Austrian Business Agency all rights reserved</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
  </footer>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';

interface LinkItem {
  text: string
  link_en?: string
  link_de: string
  hasIcon?: boolean
  link?: () => string
}

interface DocumentsLink {
  text: string
  link_en?: string
  link_de: string
  visibility: boolean
  hasIcon: boolean
  link?: () => string
}

@Component
export default class FooterComponent extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get lang() {
    return AppModule.language;
  }

  get generalLinks() {
    const resultArr: LinkItem[] = [
      {
        text: this.translations[14000],
        link_de: 'https://www.workinaustria.com/blog',
        link_en: 'https://www.workinaustria.com/en/blog',
        hasIcon: false,
      },
      {
        text: this.translations[14001],
        link_de: 'https://www.workinaustria.com/eventkalender',
        link_en: 'https://www.workinaustria.com/en/event-calendar',
        hasIcon: false,
      },
      {
        text: this.translations[14002],
        link_de: 'https://immigration-guide.workinaustria.com',
        hasIcon: true,
      },
      {
        text: this.translations[14003],
        link_de: 'https://www.workinaustria.com/downloads',
        hasIcon: false,
      },
      {
        text: 'Partner',
        link_de: 'https://www.workinaustria.com/partner',
        hasIcon: false,
      },
      {
        text: this.translations[14004],
        link_de: 'https://aba.gv.at/presse-veranstaltungen',
        link_en: 'https://aba.gv.at/en/press-events',
        hasIcon: true,
      },
    ];

    return resultArr.map(({ ...rest }) => ({
      link: () => (rest[`link_${this.lang}` as keyof LinkItem] as string) || rest.link_de,
      ...rest,
    }));
  }

  get documentsLinks() {
    const resultArr: DocumentsLink[] = [
      {
        text: this.translations[3627],
        link_de: '/impressum',
        link_en: 'https://aba.gv.at/en/imprint/',
        visibility: true,
        hasIcon: true,
      },
      {
        text: this.translations[13886],
        link_de: 'https://www.workinaustria.com/nutzungsbedingungen/',
        link_en: 'https://www.workinaustria.com/en/talenthub-terms-of-use/',
        visibility: true,
        hasIcon: true,
      },
      {
        text: this.translations[14006],
        link_de: 'https://aba.gv.at/barrierefreiheit',
        link_en: 'https://aba.gv.at/en/accessibility/',
        visibility: true,
        hasIcon: true,
      },
      {
        text: 'Stellenausschreibungen',
        link_de: 'https://www.workinaustria.com/stellenausschreibungen',
        visibility: this.lang === 'de',
        hasIcon: false,
      },
    ];

    return resultArr.map(({ ...rest }) => ({
      link: () => (rest[`link_${this.lang}` as keyof DocumentsLink] as string) || rest.link_de,
      ...rest,
    }));
  }
}
</script>
