<template>
  <div class="company-job-component">
    <b-row>
      <b-col cols="12">
        <h4 v-html="translations[13789]"></h4>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      rounded="sm"
      variant="primary"
      :opacity="0.2"
      style="min-height: 200px"
    >
      <job-company-item
        v-for="(item, index) in companyJobs"
        :key="index"
        :count="countNumber(index + 1)"
        :date="item[0]"
        :id="item[1]"
        :title="item[2]"
        :description="item[3]"
        :firm-name="item[4]"
        :letter="item[5]"
        :shortview="item[7]"
        :contact="item[8]"
        :update="item[9]"
        :translate-job="item[10]"
        :is-auth="isAuth"
        :active="isNotebookItem(item[1]).length"
        @activeTab="activeTab"
        :is-jobs-sidebar="isJobsSidebar"/>
    </b-overlay>
    <b-row class="mt-3" no-gutters>
      <b-col></b-col>
      <b-col cols="auto" class="d-flex align-items-center">
        <b-dropdown variant="primary" size="sm" class="rounded-0 rounded-start mr-1">
          <template v-slot:button-content>
            {{ paginationPerPage }}
          </template>
          <b-dropdown-item @click="[paginationPerPage = 20, jobsLoading()]">20</b-dropdown-item>
          <b-dropdown-item @click="[paginationPerPage = 50, jobsLoading()]">50</b-dropdown-item>
          <b-dropdown-item @click="[paginationPerPage = 100, jobsLoading()]" >100</b-dropdown-item>
        </b-dropdown>
        <b-pagination
          class="mb-0"
          size="sm"
          v-model="paginationPage"
          :total-rows="count"
          :per-page="paginationPerPage"
          @change="jobsLoading"/>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { ClustersModule } from '@/store/modules/clusters';
import JobCompanyItem from '@/components/Company/JobCompanyItem.vue';
import { JobType } from '@/types/ProfessionType';
// eslint-disable-next-line import/named
import { NotebookModule } from '@/store/modules/notebook';
import { CompanyModule } from '@/store/modules/company';
import { getCompanySearch } from '@/api/Company';

@Component({
  components: {
    JobCompanyItem,
  },
})
export default class JobCompanyComponent extends Vue {
  @Prop({ default: '' }) private company!: string;

  @Prop({ default: false }) private isJobsSidebar!: boolean;

  get translations() {
    return AppModule.translation;
  }

  loading = false;

  get companyJobs() {
    return CompanyModule.companyJobs.jobs;
  }

  get count() {
    return CompanyModule.companyJobs.amt;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  // pagination
  get paginationPage():number {
    return CompanyModule.companyJobPagination.page;
  }

  set paginationPage(val) {
    CompanyModule.SetPaginationCompany({ obj: 'page', value: val });
  }

  get paginationPerPage():number {
    return CompanyModule.companyJobPagination.per_page;
  }

  set paginationPerPage(val) {
    CompanyModule.SetPaginationCompany({ obj: 'per_page', value: val });
  }

  get notebookJobs() {
    return NotebookModule.notebookJobs;
  }

  get companyType() {
    return CompanyModule.companyType;
  }

  // END pagination
  // Sort
  get dateSort():string {
    return CompanyModule.companyJobSort.sort;
  }

  set dateSort(val) {
    CompanyModule.SetSortCompany({ obj: 'date', value: val });
  }

  get ascDescSort():number {
    return CompanyModule.companyJobSort.order;
  }

  set ascDescSort(val) {
    CompanyModule.SetSortCompany({ obj: 'order', value: val });
  }
  // END Sort

  countNumber(count: number) {
    return (this.paginationPage - 1) * this.paginationPerPage + count;
  }

  isNotebookItem(value: number) {
    return this.notebookJobs.filter((x: { id: number }) => x.id === value);
  }

  activeTab(tab: number) {
    this.$emit('activeTab', tab);
  }

  async loadJobListItemsCompany() {
    this.loading = true;
    let params: any;
    if (this.companyType === JobType.cluster) {
      params = {
        sid: this.languageSid,
        cluster_id: CompanyModule.companyClusterParams.cluster_id,
      };
    } else {
      params = {
        sid: this.languageSid,
      };
    }
    await CompanyModule.GetCompanyJobsSearch(params);
    this.loading = false;
  }

  async searchCompany() {
    this.loading = true;
    const params = {
      sid: this.languageSid,
      name: this.company,
      radius: 0,
    };
    const request = await getCompanySearch(params);
    if (request.length) {
      await CompanyModule.SetCompanyDetailFilter({
        obj: 'idcomp',
        value: Number(request[0].id),
      });
    }
    this.loading = false;
  }

  jobsLoading() {
    this.$nextTick(() => {
      this.loadJobListItemsCompany();
    });
  }

  async created() {
    await CompanyModule.SetDefaultCompanyJobsItems();
    if (this.company) {
      await this.searchCompany();
    }
    this.jobsLoading();
  }
}
</script>
<style scoped lang="scss"></style>
