export interface ISignin {
  id?: number
  username: string
  password: string
  sid: string
}
export interface IRegister {
  username: string
  password?: string
  email: string
  sid: string
  lang: string
  firstname?: string
  lastname?: string
  firmname?: string
}
export interface ILoginRequest {
  expires_in: any
  access_token: string
  refresh_token: string
}
export interface IUser {
  firstName: string
  lastName: string
  email: string
  phone?: string
}
export interface IProfileState {
  user?: IUser
  error: boolean
}

export enum TypeField {
  visible= 'visible',
  mandatory= 'mandatory'
}
export enum RegistrationField {
  username = 'username',
  email = 'email',
  password = 'password',
  password_confirm = 'password_confirm',
  firstname = 'firstname',
  lastname = 'lastname',
  firmname = 'firmname',
  salutation = 'salutation',
  birthdate = 'birthdate',
  country = 'country',
  citizenship = 'citizenship',
  consent_registration_fee = 'consent_registration_fee',
  payment_consent_details = 'payment_consent_details',
  captcha = 'captcha',
  status = 'status',
  consultant_id = 'consultant_id',
  referral_crm_company_id = 'referral_crm_company_id',
  wishes = 'wishes',
  education_employment = 'education_employment',
}

export interface IRegistrationField {
  mandatory?: boolean
  name: RegistrationField
  options?: { name: string, value: string, tooltip?: string }[]
  txt?: number
  type?: string
  visible?: boolean
  value?: string
}
