<template>
  <b-alert variant="secondary" show dismissible class="fs-6 mb-3">
    <p class="mb-2">
      {{ translations[13630] }}
    </p>
    <p class="mb-0">
      <span class="text-aba-secondary-600 cursor-pointer" @click="openAuthModal"
        ><b-icon icon="link45deg" class="mr-1"></b-icon>{{ translations[13631] }}</span
      >
    </p>
  </b-alert>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FiltersAuthAlert extends Vue {
  get translations() {
    return AppModule.translation;
  }

  openAuthModal() {
    UserModule.SET_OPEN_AGENT_PAGE(true);
    this.$bvModal.show('login-modal');
  }
}
</script>
