<template>
  <b-sidebar v-bind="$attrs" v-on="$listeners" @shown="onShown" @hidden="onHidden">
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </b-sidebar>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CoreSidebar extends Vue {
  onShown() {
    document.body.classList.add('overflow-hidden');
  }

  onHidden() {
    document.body.classList.remove('overflow-hidden');
  }
}
</script>
