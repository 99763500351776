import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { UserModule } from '@/store/modules/user';
import { AppModule } from '@/store/modules/app';

const HTTP: AxiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api/v1' : '/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptors
HTTP.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (UserModule.accessTokenApplicant) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${UserModule.accessTokenApplicant}`;
    }
    if (UserModule.accessTokenConsultant) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${UserModule.accessTokenConsultant}`;
    }
    if (UserModule.accessTokenCompany) {
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${UserModule.accessTokenCompany}`;
    }
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  },
);

HTTP.interceptors.response.use(
  (response: any) => response,
  async(error: any) => {
    if (error && error.response.data.error_code === 3) {
      try {
        if (UserModule.refreshTokenApplicant) {
          await UserModule.RefreshTokenApplicant(AppModule.languageSid).catch(() => {
            UserModule.LogOutApplicant(AppModule.languageSid);
            document.location.reload();
          });
        }
        if (UserModule.refreshTokenConsultant) {
          UserModule.RefreshTokenConsultant(AppModule.languageSid);
          document.location.reload();
        }
        if (UserModule.refreshTokenCompany) {
          UserModule.RefreshTokenCompany(AppModule.languageSid);
          document.location.reload();
        }
      } catch (e) {
        return Promise.reject(e);
      }
    }
    if (error && error.response.data.error_code === 4) {
      try {
        UserModule.LogOutApplicant(AppModule.languageSid);
        UserModule.LogOutConsultant(AppModule.languageSid);
        UserModule.LogOutCompany(AppModule.languageSid);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject(error);
  },
);

export default HTTP;
