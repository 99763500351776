export const calculateDaysBetweenTodayAndDate = (targetDate: string): number => {
  const targetDateObj = new Date(targetDate);

  const currentDate = new Date();

  const timeDifference = targetDateObj.getTime() - currentDate.getTime();

  const daysDifference = Math.abs(Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));

  return daysDifference;
};
