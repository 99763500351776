import Cookies from 'js-cookie';

const cookieKey = 'cookieAssess';
export const getCookiesSite = () => Cookies.get(cookieKey);
export const setCookiesSite = (cookie: string) => Cookies.set(cookieKey, cookie, { expires: 364 });

const languageKey = 'language';
export const getLanguage = () => Cookies.get(languageKey);
export const setLanguage = (language: string) => Cookies.set(languageKey, language);

const getNotebookData = (fieldName: string) => window.sessionStorage.getItem(fieldName);
const setNotebookData = (fieldName: string, items: any) => window.sessionStorage.setItem(fieldName, JSON.stringify(items));
