<template>
  <div class="btn btn-tag btn-check d-inline-flex align-items-center fs-6 mb-1 mr-1 text-start">
    <span class="fw-bold" v-html="title"></span>
    <span class="ml-1 fw-light" v-if="count"> ({{ count }})</span>
    <b-button @click="$emit('delete')" variant="link" size="sm" class="lh-1 fs-5 ml-auto p-0">
      <b-icon icon="x"></b-icon>
    </b-button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FilterTag extends Vue {
  @Prop({ default: undefined }) private count: number | undefined;

  @Prop({ required: true }) private title!: string;
}
</script>
