import HTTP from '@/plugins/axios';

type EventLogPayload = {
  event_id: number
  object_id: number
}

export const logEvent = async(params: EventLogPayload) => {
  const response = await HTTP.post('/event_log', params);
  return response.data;
};
