<template>
  <footer class="footer footer-theme position-relative">
    <b-container style="max-width: 1340px;">
      <div class="footer-top">
        <b-row>
          <b-col lg="3" md="6" sm="12" class="footer-column">
            <div class="footer-widget logo-widget">
              <figure class="footer-logo pb-3 m-0">
                <router-link to="/">
                  <img :src="require('@/assets/img/logo/footer-logo-jig.png')" alt="JIG - Jobs in Germany" style="width: 100px">
                </router-link>
              </figure>

              <div class="text">
                <p style="text-align: justify">
                  JIG is aimed specifically at young people and people who are looking for a career abroad, and offers professional advice to those who want to go this way. With our high-quality approach to service, people can be guided at any stage from their choice of destination abroad and even their next steps.
                </p>

                <p>
                  Steven Attard<br>
                  BAB Online Ltd<br>
                  31, Triq IR-Rummien<br>
                  San Gwann SGN 2072<br>
                  Malta<br><br>

                  VAT Number: MT 2694-6025<br>
                  Tax Number: 970 184 634<br>
                  Company Reg. No.: C 92918<br>
                </p>
              </div>

              <ul class="social-links clearfix">
                <li>
                  <a href="https://www.facebook.com/jigdotworld" rel="nofollow" target="_blank" aria-label="facebook">
                    <b-icon icon="facebook" />
                  </a>
                </li>

                <li>
                  <a href="https://www.instagram.com/jigdotworld/" rel="nofollow" target="_blank"  aria-label="instagram">
                    <b-icon icon="instagram" />
                  </a>
                </li>

                <li>
                  <a href="https://www.linkedin.com/company/jig-jobs-in-germany/" rel="nofollow" target="_blank"  aria-label="linkedin-in">
                    <b-icon icon="linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col lg="3" md="6" sm="12" class="footer-column">
            <div class="footer-widget links-widget">
              <div class="widget-title">
                <h3>Corporate</h3>
              </div>
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in corporateLinks" :key="`corporate-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col lg="3" md="6" sm="12" class="footer-column">
            <div class="footer-widget links-widget">
              <div class="widget-title">
                <h3>Services</h3>
              </div>
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in servicesLinks" :key="`services-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col lg="3" md="6" sm="12" class="footer-column">
            <div class="footer-widget links-widget">
              <div class="widget-title">
                <h3>Information</h3>
              </div>
              <div class="widget-content">
                <ul class="list clearfix">
                  <li v-for="(item, index) in informationLinks" :key="`information-${index}`">
                    <a :href="item.link" target="_blank">
                      {{ item.text }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="footer-bottom">
        <b-row>
          <b-col>
            <div class="clearfix">
              <div class="copyright pull-left">
                <p>
                  Copyright 2020 - {{ new Date().getFullYear() }}

                  <router-link to="/" style="text-transform: none">
                    JIG - Jobs in Germany.
                  </router-link>

                  Powered by
                  <a href="" style="text-transform: none">
                    Jobs in Germany.
                  </a>
                </p>
              </div>
              <ul class="footer-nav pull-right clearfix">
                <li>
                  <a :href="`https://jig.world/${lang}/contracts/gizlilik-sozlesmesi`" target="_blank">
                    Confidentiality Agreement
                  </a>
                </li>
                <li>
                  <a :href="`https://jig.world/${lang}/contracts/cerez-politikasi`" target="_blank">
                    Cookie Policy
                  </a>
                </li>
                <li>
                  <a :href="`https://jig.world/${lang}/contracts/kullanim-sartlari`" target="_blank">
                    Terms of use
                  </a>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </footer>
</template>

<script lang="ts">
import { AppModule } from '@/store/modules/app';
import { Component, Vue } from 'vue-property-decorator';

interface LinkItem {
  text: string
  link?: string
  hasIcon?: boolean
}

@Component
export default class FooterComponent extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get lang() {
    return AppModule.language;
  }

  get corporateLinks() {
    const resultArr: LinkItem[] = [
      {
        text: 'About Us',
        link: `https://jig.world/${this.lang}/corporate/about`,
      },
      {
        text: 'Our Partners',
        link: `https://jig.world/${this.lang}/corporate/partners`,
      },
      {
        text: 'Press Room',
        link: `https://jig.world/${this.lang}/corporate/press`,
      },
      {
        text: 'Video Library',
        link: `https://jig.world/${this.lang}/corporate/videos`,
      },
      {
        text: 'We on Social Media',
        link: `https://jig.world/${this.lang}/corporate/social-media`,
      },
      {
        text: 'FAQ',
        link: `https://jig.world/${this.lang}/corporate/faq`,
      },
      {
        text: 'Contact',
        link: `https://jig.world/${this.lang}/contact`,
      },
    ];
    return resultArr;
  }

  get servicesLinks() {
    const resultArr: LinkItem[] = [
      {
        text: 'Work Placement (Diploma) in Europe and Germany',
        link: `https://jig.world/${this.lang}/services/is-bulunmasi-diplomali`,
      },
      {
        text: 'Germany Opportunity Card Application',
        link: `https://jig.world/${this.lang}/services/firsat-karti`,
      },
      {
        text: 'Process Tracking',
        link: `https://jig.world/${this.lang}/services/surec-takibi`,
      },
      {
        text: 'Residence Permit',
        link: `https://jig.world/${this.lang}/services/almanya-da-oturma-izni`,
      },
      {
        text: 'Insurance Entry',
        link: `https://jig.world/${this.lang}/services/sigorta-girisi`,
      },
      {
        text: 'Consultancy Service',
        link: `https://jig.world/${this.lang}/services/danismanlik-hizmeti`,
      },
      {
        text: 'Finding an Internship',
        link: `https://jig.world/${this.lang}/services/staj-bulunmasi`,
      },
      {
        text: 'Work Placement in Europe and Germany (No Diploma)',
        link: `https://jig.world/${this.lang}/services/is-bulunmasi-diplomasiz`,
      },
      {
        text: 'Form Tracking',
        link: `https://jig.world/${this.lang}/services/form-takibi`,
      },
      {
        text: 'Mesleki Eğitim',
        link: `https://jig.world/${this.lang}/services/mesleki-egitim`,
      },
    ];
    return resultArr;
  }

  get informationLinks() {
    const resultArr: LinkItem[] = [
      {
        text: 'Pricing',
        link: `https://jig.world/${this.lang}/subjects/ucretlendirme`,
      },
      {
        text: 'Processing Times',
        link: `https://jig.world/${this.lang}/subjects/islem-sureleri`,
      },
      {
        text: 'German Tax System',
        link: `https://jig.world/${this.lang}/subjects/almanya-vergi-sistemi`,
      },
      {
        text: 'Sick Leave in Germany',
        link: `https://jig.world/${this.lang}/subjects/almanyada-hastalik-izni`,
      },
      {
        text: 'Minijob in Germany',
        link: `https://jig.world/${this.lang}/subjects/almanyada-minijob`,
      },
      {
        text: 'Germany Cities',
        link: `https://jig.world/${this.lang}/cities`,
      },
      {
        text: 'Occupations',
        link: `https://jig.world/${this.lang}/professions/index`,
      },
      {
        text: 'Blog',
        link: `https://jig.world/${this.lang}/blog`,
      },
      {
        text: 'Contracts',
        link: `https://jig.world/${this.lang}/contracts/index`,
      },
    ];
    return resultArr;
  }
}
</script>
<style lang="scss" scoped>
[theme="jig"] {
  .footer-theme {
    background: #3a3a3a;
    .pull-left {
      float: left;
    }
    .pull-right {
      float: right;
    }
    a {
      text-decoration: none;
      transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -webkit-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
    }
    ul {
      padding: 0;
    }
    h3 {
      color: #ffffff;
    }
    .footer-top {
      position: relative;
      display: block;
      padding: 0px 0px 50px 0px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      p {
        color: #ffffff;
        line-height: 26px;
        margin-bottom: 26px;
        font-weight: 300;
      }
      .logo-widget {
        position: relative;
        @media (min-width: 780px) {
          margin-right: -35px;
        }
        .footer-logo {
          position: relative;
          margin-bottom: 28px;
        }
      }
      .social-links li {
        position: relative;
        display: inline-block;
        margin-right: 15px;
        a {
          display: inline-block;
          font-size: 15px;
          font-weight: 300;
          color: #7b8a9a;
          &:hover {
            color: #e30613;
          }
        }
      }
      .widget-content li {
        a {
          position: relative;
          display: inline-block;
          font-size: 15px;
          line-height: 26px;
          font-weight: 300;
          margin-bottom: 10px;
          color: #ffffff;

          &:hover {
            color: #e30613;
          }
        }
      }
      .footer-column:nth-child(2) .links-widget {
        margin-left: 60px;
      }
    }
    .footer-bottom {
      position: relative;
      padding-top: 15px;
      .copyright p {
        color: #ffffff;
        line-height: 26px;
        a {
          display: inline-block;
          color: #ffffff;
          text-transform: uppercase;
          &:hover {
            color: #e30613;
          }
        }
      }
      .footer-nav li {
        position: relative;
        display: inline-block;
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
          &:before {
            display: none;
          }
        }
        a {
          position: relative;
          display: inline-block;
          font-size: 15px;
          line-height: 26px;
          font-weight: 300;
          color: #ffffff;
          &:hover {
            color: #e30613;
          }
        }
        &:before {
          position: absolute;
          content: '';
          background: #4c5b6c;
          width: 2px;
          height: 16px;
          top: 8px;
          right: -12px;
        }
      }
    }
  }
}
</style>
