<template>
  <div class="position-relative">
    <b-row align-v="center" no-gutters id="subheader">
      <b-col cols="auto" class="p-0">
        <!-- Logo -->
        <a href="https://aba.gv.at/">
          <div class="p-0 bg-aba-primary h-100">
            <img :src="require('@/assets/img/logo-ABA-work.svg')" alt="ABA" class="img img-fluid logo-header" />
          </div>
        </a>
        <!-- /Logo -->
      </b-col>
      <b-col cols="auto" class="position-static">
        <nav class="main-nav d-none d-md-inline-block">
          <ul class="main-nav-list list-unstyled">
            <li
              v-for="(link, index) in generalLinks"
              :key="index"
              class="main-nav-item sub"
            >
              <div class="main-nav-link-wrapper">
                <a target="_blank" :href="link.link()" tabindex="0" class="main-nav-link" aria-current="page">
                  {{ link.text }}
                  <div class="main-nav-link-toggle-desktop d-none d-lg-inline-block">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" class="icon icon-arrow-navigation">
                      <path d="M15.5,5.9,9.88,11.59a2.63,2.63,0,0,1-3.76,0L.5,5.9,2.75,3.62,8,8.93l5.25-5.3Z" style="fill-rule: evenodd"></path>
                    </svg>
                    <span class="sr-only">Toggle sub navigation</span>
                  </div>
                </a>
                <div tabindex="0" class="main-nav-link-toggle">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16" class="icon icon-arrow-navigation">
                    <path d="M15.5,5.9,9.88,11.59a2.63,2.63,0,0,1-3.76,0L.5,5.9,2.75,3.62,8,8.93l5.25-5.3Z" style="fill-rule: evenodd"></path>
                  </svg>
                  <span class="sr-only">Toggle sub navigation</span>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </b-col>
      <b-col cols="auto" class="d-none d-md-flex ml-auto">
        <a
          :href="immigrationGuideLink"
          target="_blank"
          rel="noopener"
          class="btn btn-tertiary header-btn-get-in-touch btn-sm d-none d-lg-block m-0 ml-half"
        >
          Immigration Guide
        </a>
      </b-col>
      <b-col cols="auto" class="d-flex ml-auto ml-md-0 mr-3 mr-md-3">
        <language-header />
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LanguageHeader from '@/components/Header/LanguageHeader.vue';
import { AppModule } from '@/store/modules/app';

interface GeneralLink {
  text: string
  link_en?: string
  link_de: string
  link?: () => string
}

@Component({
  components: {
    LanguageHeader,
  },
})
export default class InformationNavigation extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get lang() {
    return AppModule.language;
  }

  get generalLinks() {
    const resultArr: GeneralLink[] = [
      {
        text: this.translations[13996],
        link_de: 'https://www.workinaustria.com/warum-oesterreich',
        link_en: 'https://www.workinaustria.com/en/why-austria',
      },
      {
        text: this.translations[13997],
        link_de: 'https://www.workinaustria.com/aufenthalt-beschaeftigung',
        link_en: 'https://www.workinaustria.com/en/residence-employment',
      },
      {
        text: this.translations[13998],
        link_de: 'https://www.workinaustria.com/leben-arbeiten',
        link_en: 'https://www.workinaustria.com/en/living-working',
      },
      {
        text: this.translations[13999],
        link_de: 'https://www.workinaustria.com/unsere-services',
        link_en: 'https://www.workinaustria.com/en/our-services',
      },
    ];

    return resultArr.map(({ ...rest }) => ({
      link: () => (rest[`link_${this.lang}` as keyof GeneralLink] as string) || rest.link_de,
      ...rest,
    }));
  }

  get immigrationGuideLink() {
    return `https://immigration-guide.workinaustria.com/${this.lang === 'en' ? '' : '/de/'}`;
  }
}
</script>
<style scoped lang="scss">
.main-nav-list {
  background: #f6f9fb;
  padding: 0 0 0 3rem;
  margin: 0;
}

.main-nav-link-wrapper {
  >a, >a:visited, >a:active, >a:hover {
    color: rgb(var(--aba-secondary-600));
    text-decoration: none;
  }
}
</style>
