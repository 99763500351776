<template>
  <core-sidebar id="auth-sidebar" title="" right shadow header-class="bg-aba-primary" text-variant="light" backdrop>
    <template #default="{ hide }">
      <div class="px-3 py-2">
        <b-row no-gutters>
          <b-col>
            <p class="m-0 text-dark">
              <span class="fs-6">{{ translations[2438] }}</span><br />
              <span
                class="fw-bold"
                v-if="
                        accessTokenApplicant &&
                          userInfoApplicant &&
                          userInfoApplicant.applicant.firstname
                      "
              >
                      {{
                  `${userInfoApplicant.applicant.firstname} ${
                    userInfoApplicant.applicant.lastname
                      ? userInfoApplicant.applicant.lastname
                      : ''
                  }`
                }}
                    </span>
              <span
                class="fw-bold"
                v-else-if="accessTokenConsultant && userInfoConsultant"
              >
                      {{
                  `${userInfoConsultant.consultant.firstname} ${
                    userInfoConsultant.consultant.lastname
                      ? userInfoConsultant.consultant.lastname
                      : ''
                  }`
                }}
                    </span>
              <span
                v-else-if="accessTokenCompany && userInfoCompany"
                class="fw-bold"
              >
                {{ userInfoCompany.company?.company_name || '' }}
              </span>
            </p>
          </b-col>
        </b-row>
      </div>
      <div class="px-3 py-2 d-flex flex-column">
        <b-button variant="light" class="border mb-2 text-left" @click="$router.push({name: 'Cabinet'}).catch(() => {})">
          <b-icon icon="person-circle" class="mr-1" scale="0.8"></b-icon>
          {{ translations[13797] }} ({{ headerText }})
        </b-button>
        <b-button variant="light"
                  v-b-toggle.notebook-sidebar
                  class="border mb-2 w-100 text-left d-flex align-items-center"
                  v-b-tooltip.hover  title="Notizbuch öffnen (8 Einträge)">
          <b-icon icon="journal-bookmark" class="mr-1" scale="0.8"></b-icon>
          {{ translations[10966] }}
          <b-badge variant="danger" class="ml-auto">{{ notebookLength }}</b-badge>
        </b-button>
        <b-button variant="light"
                  disabled
                  class="border mb-2 w-100 text-left d-flex align-items-center"
                  v-b-tooltip.hover title="Nachrichtencenter öffnen (22 neue Nachrichten)">
          <b-icon icon="chat-square-text" class="mr-1" scale="0.8"></b-icon>
          {{ translations[10997] }}
          <b-badge variant="danger" class="ml-auto"></b-badge>
        </b-button>
        <b-button variant="light" class="border text-left" @click.prevent="LogOut(hide)">
          <b-icon icon="power" class="mr-1" scale="0.8"></b-icon>{{ translations[13575] }}
        </b-button>
      </div>
    </template>
  </core-sidebar>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { NotebookModule } from '@/store/modules/notebook';

@Component
export default class AuthSidebar extends Vue {
  get sid() {
    return AppModule.sid;
  }

  get translations() {
    return AppModule.translation;
  }

  // TokenApplicant
  get accessTokenApplicant() {
    return UserModule.accessTokenApplicant;
  }

  // TokenConsultant
  get accessTokenConsultant() {
    return UserModule.accessTokenConsultant;
  }

  // TokenCompany
  get accessTokenCompany() {
    return UserModule.accessTokenCompany;
  }

  get userInfoApplicant() {
    return UserModule.userInfoApplicant;
  }

  get userInfoConsultant() {
    return UserModule.userInfoConsultant;
  }

  get userInfoCompany() {
    return UserModule.userInfoCompany;
  }

  get notebookLength() {
    return Number(NotebookModule.notebookJobs.length)
      + Number(NotebookModule.notebookWorkers.length)
      + Number(NotebookModule.notebookCompany.length);
  }

  get headerText() {
    if (this.accessTokenApplicant) return this.translations[1135];
    if (this.accessTokenCompany) return this.translations[3764];
    if (this.accessTokenConsultant) return this.translations[13766];
    return '';
  }

  LogOut(hideEvent: () => void) {
    if (this.accessTokenApplicant) {
      UserModule.LogOutApplicant(this.sid);
    }
    if (this.accessTokenConsultant) {
      UserModule.LogOutConsultant(this.sid);
    }
    if (this.accessTokenCompany) {
      UserModule.LogOutCompany(this.sid);
    }
    hideEvent();
    this.$router.push({ path: '/' }).catch(() => {});
  }
}
</script>
