<template>
  <div>
    <multiselect
      :class="[{'multiselect-sm': sm, 'multiselect-fixed': fixed, 'multiselect-x2': x2}]"
      label="name"
      track-by="id"
      v-model="selectedProfession"
      :options="listProfession"
      @search-change="searchProfession"
      :placeholder="placeholder ? placeholder : `${translations[13588]}...`"
      group-values="libs"
      group-label="group"
      :group-select="true"
      :multiple="true"
      :searchable="true"
      :preserveSearch="true"
      :loading="load"
      :internal-search="false"
      :clear-on-select="false"
      :close-on-select="false"
      :options-limit="300"
      open-direction="bottom"
      :showNoOptions="false"
      selectLabel=""
      selectGroupLabel=""
      deselectLabel=""
      deselectGroupLabel=""
      :showLabels="false"
      @remove="loadSimilarProfession"
      @select="loadSimilarProfession"
    >
      <template slot="tag" slot-scope="{ option, remove }">
      <span class="multiselect__tag">
        <span v-html="option.name"></span>
        <i class="multiselect__tag-icon" @click="remove(option)"></i>
      </span>
      </template>
      <template slot="option" slot-scope="props">
        <div class="option__group">
          <b>{{ props.option.$groupLabel }}</b>
        </div>
        <div class="option__desc" v-if="props.option.name">
          <span class="option__title" v-html="props.option.name"></span>
          <span> - {{ props.option.count }}
            <template v-if="!isApplicantSearch">
              {{ translations[2983] }}
            </template>
            <template v-else>
              {{ +props.option.count === 1 ? translations[13768] : translations[13769] }}
            </template>
          </span>
        </div>
      </template>
      <template slot="noResult">
        <b>{{ translations[9506] }}</b>
      </template>
    </multiselect>
    <b-alert v-if="similarProfessions && similarProfessionItems.length" show fade dismissible variant="warning" class="border-0 fs-6 lh-sm p-2 mt-2" role="alert">
      <b-row class="g-2 mt-0">
        <b-col cols="auto"><b-icon icon="info-circle"></b-icon></b-col>
        <b-col><span v-html="translations[14019]" class="d-inline-block pr-5"></span>
          <ul class="list-unstyled pt-2 m-0">
            <li v-for="item in similarProfessionItems" :key="item.id">
              <a href="#" @click.prevent="margeProfessions(item)" class="fw-bold"><b-icon icon="arrow-repeat" class="mr-1"></b-icon>
                {{ item.name }} ({{ item.count }})</a>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-alert>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import axios from 'axios';
import { AppModule } from '@/store/modules/app';

import Multiselect from 'vue-multiselect';
import { similarProfession } from '@/api/Profession';

@Component({
  components: {
    Multiselect,
  },
})
export default class SelectProfessionUpdate extends Vue {
  @Prop({ default: () => ({} as any) }) value!: any;

  @Prop({ default: '' }) placeholder!: string;

  @Prop({ default: false }) sm!: boolean;

  @Prop({ default: false }) fixed!: boolean;

  @Prop({ default: false }) x2!: boolean;

  @Prop({ default: false }) similarProfessions!: boolean;

  @Prop({ default: false }) isApplicantSearch!: boolean;

  similarProfessionItems = [] as any;

  cancel: any = null;

  load: any = false;

  searchText: string | undefined = '';

  listProfession = [];

  get translations() {
    return AppModule.translation;
  }

  get storeSid() {
    return AppModule.sid;
  }

  get storeLanguageSid() {
    return AppModule.languageSid;
  }

  get selectedProfession() {
    if (!this.value) return [];
    // fix case when id is string...
    return this.value.map((el: any) => ({
      ...el,
      id: +el.id,
    }));
  }

  set selectedProfession(value: any) {
    this.$emit('input', value);
    this.$emit('change');
  }

  async loadLocationOptions(query: string) {
    try {
      const { CancelToken } = axios;
      // let cancel;
      await axios
        .get(
          `${
            process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '/v1'
          }/jobnews/profession_query_new`,
          {
            params: {
              sid: this.storeLanguageSid,
              q: query,
              ...(this.isApplicantSearch && { applicant_search: 1 }),
            },
            cancelToken: new CancelToken((c) => {
              this.cancel = c;
            }),
          },
        )
        .then((res: any) => {
          let transformedData = [] as any;
          res.data.forEach((item: any) => {
            const group = item.parent_name || this.translations[11483];
            let groupObj = transformedData.find((groupItem: any) => groupItem.group === group);

            if (!groupObj) {
              groupObj = { group, libs: [] };
              transformedData.push(groupObj);
            }
            if (!item.message) {
              groupObj.libs.push({
                id: parseInt(item.id, 10),
                name: item.name,
                count: parseInt(item.count, 10),
                title: item.title,
              });
            } else {
              transformedData = [];
            }
          });
          this.listProfession = transformedData;
          this.load = false;
        })
        .catch((thrown: any) => {
          if (axios.isCancel(thrown)) {
            this.load = true;
          }
        });
    } catch (error) {
      console.error(error);
    }
  }

  searchProfession(searchText: string) {
    this.load = true;
    if (searchText.trim().length > 2) {
      this.searchText = searchText;
      this.load = true;
      if (this.cancel) this.cancel();

      this.loadLocationOptions(searchText).then((r: any) => {
        if (this.searchText === searchText) {
          this.load = false;
        }
      });
    } else {
      if (this.cancel) this.cancel();
      this.listProfession = [];
      this.load = false;
    }
  }

  loadSimilarProfession() {
    if (!this.similarProfessions) return;
    this.$nextTick(async() => {
      const ids = this.selectedProfession?.map((v: any) => v.id).join(',');
      this.similarProfessionItems = await similarProfession({ sid: this.storeLanguageSid, id: ids, ...(this.isApplicantSearch && { applicants_search: 1 }) });
    });
  }

  margeProfessions(item: any) {
    const similar = [{
      id: +item.id, count: item.count, title: item.name, name: item.name,
    }];
    this.selectedProfession = [...this.selectedProfession, ...similar];
    this.loadSimilarProfession();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
  ::v-deep.multiselect-fixed {
    @media (min-width: 576px) {
      position: absolute;
    }
    inset: 0;
    .multiselect__tags {
      //min-height: 100%;
    }
    .multiselect__tag {
      white-space: nowrap;
    }
  }
</style>
