<template>
  <b-modal id="login-modal" :title="translations[14408]" centered dialog-class="modal-dialog-md-center" @hide="handleHideModal">
    <template #default="{ hide }">
      <div class="accordion" role="tablist">
        <div v-for="tab in tabList" :key="tab.value" class="accordion-item">
          <template v-if="tab.visible">
            <button :class="{ collapsed: selectedAuthTab !== tab.value }" class="accordion-button p-2 fw-bolder" @click="toggleTab(tab.value)">
              {{ tab.title }}
            </button>
            <b-collapse :visible="selectedAuthTab === tab.value" accordion="my-accordion">
              <div class="accordion-body p-2">
                <component :is="tab.component" :ref="tab.value" :alpha="tab.alpha" @close="hide" @submit="sumbitLogin" />
              </div>
            </b-collapse>
          </template>
        </div>
      </div>
      <div class="mt-3 fs-6 lh-sm">
        {{ translations[13730] }}
        <a v-b-modal.register-modal href="#" class="text-aba-primary-800" v-html="translations[13731]" @click.prevent="hide"></a>
      </div>
    </template>
    <template #modal-footer>
      <b-row class="gx-2 w-100" align-v="center">
        <b-col cols="auto">
          <b-button variant="primary" :disabled="!selectedAuthTab" @click="sumbitLogin">{{ translations[13726] }}</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LoginApplicantForm from '@/components/Authorization/LoginForms/LoginApplicantForm.vue';
import LoginCompanyForm from '@/components/Authorization/LoginForms/LoginCompanyForm.vue';
import LoginConsultantForm from '@/components/Authorization/LoginForms/LoginConsultantForm.vue';
import { AppModule, AuthTab } from '@/store/modules/app';

type TabItem = {
  title: string
  value: AuthTab
  component: 'LoginApplicantForm' | 'LoginCompanyForm' | 'LoginConsultantForm'
  alpha?: boolean
  visible: boolean
};

interface RefWithLogin {
  onLogin: () => void
}

interface RefsInterface {
  [key: string]: Vue[] & RefWithLogin[]
}

const group_new_cabinet = [
  'aba', 'abatest', 'talentpool', 'jig', 'edunet', 'edunetke', 'edunetasia', 'edunetma', 'wconat', 'ncph', 't4a',
  'kh', 'rhh', 'he',
];

@Component({
  components: {
    LoginApplicantForm,
    LoginCompanyForm,
    LoginConsultantForm,
  },
})
export default class LoginModal extends Vue {
  get sid() {
    return AppModule.sid;
  }

  get tabList(): TabItem[] {
    return [
      {
        title: this.translations[11115],
        value: AuthTab.applicant,
        component: 'LoginApplicantForm',
        visible: true,
      },
      {
        title: this.translations[13937],
        value: AuthTab.firm,
        component: 'LoginCompanyForm',
        visible: !group_new_cabinet.includes(this.sid),
      },
      {
        title: this.translations[13937],
        value: AuthTab.firmAlpha, // new cabinet
        component: 'LoginCompanyForm',
        alpha: true,
        visible: group_new_cabinet.includes(this.sid),
      },
      {
        title: this.translations[13727],
        value: AuthTab.support,
        component: 'LoginConsultantForm',
        visible: true,
      },
    ];
  }

  get translations() {
    return AppModule.translation;
  }

  get selectedAuthTab() {
    return AppModule.selectedAuthTab;
  }

  set selectedAuthTab(val) {
    AppModule.SET_SELECTED_AUTH_TAB(val);
  }

  toggleTab(value: AuthTab) {
    this.selectedAuthTab = value === this.selectedAuthTab ? AuthTab.default : value;
  }

  sumbitLogin() {
    const refs = this.$refs as RefsInterface;
    if (this.selectedAuthTab && refs[this.selectedAuthTab]) {
      refs[this.selectedAuthTab][0].onLogin();
    }
  }

  handleHideModal() {
    AppModule.SET_SELECTED_AUTH_TAB(AuthTab.default);
  }
}
</script>
