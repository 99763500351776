import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { group } from '@/config/configuration';
import { ThemesModule } from '@/store/modules/themes';

@Component
export default class extends Vue {
  get sid() {
    return AppModule.sid;
  }

  get templateGroup() {
    return group(this.sid);
  }

  get template() {
    if (this.templateGroup) {
      return this.sid;
    }
    return 'default';
  }

  get defaultColor() {
    return ThemesModule.sample.colors;
  }

  jigCreateFontsUrl() {
    const link0: any = document.createElement('link');
    link0.rel = 'preconnect';
    link0.href = 'https://fonts.googleapis.com';
    document.getElementsByTagName('head')[0].appendChild(link0);
    const link1: any = document.createElement('link');
    link1.rel = 'preconnect';
    link1.href = 'https://fonts.gstatic.com';
    link1.crossorigin = 'crossorigin';
    document.getElementsByTagName('head')[0].appendChild(link1);
    const link2: any = document.createElement('link');
    link2.rel = 'stylesheet';
    link2.href = 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap';
    document.getElementsByTagName('head')[0].appendChild(link2);
  }

  talentpoolCreateFontsUrl() {
    const link0: any = document.createElement('link');
    link0.rel = 'preconnect';
    link0.href = 'https://fonts.googleapis.com';
    document.getElementsByTagName('head')[0].appendChild(link0);
    const link1: any = document.createElement('link');
    link1.rel = 'preconnect';
    link1.href = 'https://fonts.gstatic.com';
    link1.crossorigin = 'crossorigin';
    document.getElementsByTagName('head')[0].appendChild(link1);
    const link2: any = document.createElement('link');
    link2.rel = 'stylesheet';
    link2.href = 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap';
    document.getElementsByTagName('head')[0].appendChild(link2);
  }

  createIconUrl() {
    const link0: any = document.createElement('link');
    link0.rel = 'shortcut icon';
    link0.href = `./favicons/${this.template}/favicon.gif`;
    document.getElementsByTagName('head')[0].appendChild(link0);
    const link2: any = document.createElement('link');
    link2.type = 'image/png';
    link2.rel = 'apple-touch-icon';
    link2.sizes = '57x57';
    link2.href = `./favicons/${this.template}/app-icon-57x57.png`;
    document.getElementsByTagName('head')[0].appendChild(link2);
    const link3: any = document.createElement('link');
    link3.type = 'image/png';
    link3.rel = 'apple-touch-icon';
    link3.sizes = '72x72';
    link3.href = `./favicons/${this.template}/app-icon-72x72.png`;
    document.getElementsByTagName('head')[0].appendChild(link3);
    const link4: any = document.createElement('link');
    link4.type = 'image/png';
    link4.rel = 'apple-touch-icon';
    link4.sizes = '144x144';
    link4.href = `./favicons/${this.template}/app-icon-144x144.png`;
    document.getElementsByTagName('head')[0].appendChild(link4);
  }

  hexToRgb(hex: string): { r: number, g: number, b: number } {
    const bigint: number = parseInt(hex.slice(1), 16);
    // eslint-disable-next-line no-bitwise
    const r: number = (bigint >> 16) & 255;
    // eslint-disable-next-line no-bitwise
    const g: number = (bigint >> 8) & 255;
    // eslint-disable-next-line no-bitwise
    const b: number = bigint & 255;

    return { r, g, b };
  }

  darken(color: string, percent: number): string {
    const rgb = this.hexToRgb(color);
    const factor = 1 - percent / 100;
    const darkened = {
      r: Math.round(rgb.r * factor),
      g: Math.round(rgb.g * factor),
      b: Math.round(rgb.b * factor),
    };
    return `rgb(${darkened.r}, ${darkened.g}, ${darkened.b})`;
  }

  updateColor() {
    const primaryColor = this.defaultColor.primary;
    const primaryRgb = this.hexToRgb(primaryColor);
    const secondaryColor = this.defaultColor.secondary;
    const secondaryRgb = this.hexToRgb(secondaryColor);

    const hoverColor = this.darken(primaryColor, 10);
    document.documentElement.style.setProperty('--aba-primary', this.defaultColor.primary);
    document.documentElement.style.setProperty('--aba-primary-rgb', `${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}`);
    document.documentElement.style.setProperty('--aba-secondary', this.defaultColor.secondary);
    document.documentElement.style.setProperty('--aba-secondary-rgb', `${secondaryRgb.r}, ${secondaryRgb.g}, ${secondaryRgb.b}`);

    document.documentElement.style.setProperty('--theme-color-50-rgb', `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, 0.5)`);
    document.documentElement.style.setProperty('--theme-color-25-rgb', `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, 0.25)`);
    document.documentElement.style.setProperty('--base-color-hover', hoverColor);
  }
}
