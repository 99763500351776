<template>
  <div class="captcha-widget mb-1 d-flex" style="margin-left: 37px">
    <div class="captcha-widget_content">
      <div class="captcha-widget_img mb-1">
        <b-img class="img-fluid" :src="imageUrl"  v-if="imageUrl" />
      </div>
      <div class="captcha-widget_input">
        <b-form-group class="mb-0">
          <b-form-input
            class="border-0"
            v-model="model"
            type="text"
            required
          ></b-form-input>
        </b-form-group>
      </div>
    </div>
    <div>
      <b-button variant="link" class="m-1 mt-2" @click="fetchImage">
        <b-icon icon="arrow-clockwise" />
      </b-button>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getCaptchaGenerate } from '@/api/Captcha';

@Component
export default class Captcha extends Vue {
  @Prop({ default: '' }) value!: string;

  imageUrl = '';

  filename = '';

  get model() {
    return this.value;
  }

  set model(val: string) {
    this.$emit('input', val);
  }

  async fetchImage() {
    const response = await getCaptchaGenerate();
    const disposition = response.disposition || '';
    const matches = /filename="([^"]*)"/.exec(disposition);
    if (matches && matches[1]) {
      this.filename = matches[1].replace(/\.[^/.]+$/, '') as string;
    }
    this.imageUrl = URL.createObjectURL(response.data);
    this.$emit('change', this.filename);
  }

  async mounted() {
    await this.fetchImage();
    this.model = '';
  }
}
</script>
<style scoped lang="scss">
.captcha-widget {
  &_content{
    max-width: 200px;
  }
}
</style>
