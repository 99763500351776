import HTTP from '@/plugins/axios';

export type FederalState = {
  id: string
  jobs: number
  location_name: string
}

export const professionItems = async(params: any) => {
  const response = await HTTP.get('/jobnews/profession', {
    params,
  });
  return response.data;
};
export const jobListItems = async(params: any) => {
  const response = await HTTP.get('/jobnews/job_list', {
    params,
  });
  return response.data;
};
export const jobListItemsSearch = async(params: any) => {
  const response = await HTTP.get('/jobnews/form_job_list', {
    params,
  });
  return response.data;
};
export const professionStateFilter = async(params: any): Promise<FederalState[]> => {
  const response = await HTTP.get('/jobnews/state_filter', {
    params,
  });
  return response.data;
};
export const professionStateFilterCompany = async(params: any): Promise<FederalState[]> => {
  const response = await HTTP.get('/jobnews/employer_state_filter', {
    params,
  });
  return response.data;
};
export const similarProfession = async(params: { sid: string, id: number[] }): Promise<any[]> => {
  const response = await HTTP.get('/jobnews/relative_professions', {
    params,
  });
  return response.data;
};
