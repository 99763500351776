<template>
  <core-sidebar sidebar-class="w-100 w-xl-75 w-xxl-50" lazy backdrop shadow id="company-sidebar" title="" right>
    <template #header="{ hide }">
      <b-button variant="link" v-b-tooltip.hover.bottom :title="translations[10908]" @click="hide">
        <b-icon icon="arrow-right" />
      </b-button>
    </template>
    <b-tabs class="h-90" nav-class="mb-0 bg-aba-secondary" no-nav-style content-class="h-100">
      <b-tab lazy title-link-class="text-white" class="h-100 bg-white">
        <template #title>
          <b-icon icon="building" scale="0.8" />
          <span class="d-none d-md-inline ml-1">{{ translations[13803] }}</span>
        </template>
        <div class="component-widget p-3 content-color">
          <company-info />
        </div>
      </b-tab>
      <b-tab title-link-class="text-white" active>
        <template #title>
          <b-icon icon="briefcase" scale="0.8" />
          <span class="d-none d-md-inline ml-1">{{ translations[10940] }}</span>
        </template>
        <div class="component-widget p-3">
          <job-company-component :is-jobs-sidebar="true" />
        </div>
      </b-tab>
      <b-tab v-if="isNewsletterVisible" lazy title-link-class="text-white">
        <template #title>
          <b-icon icon="envelope" scale="0.8" />
          <span class="d-none d-md-inline ml-1">{{ translations[13255] }}</span>
        </template>
        <company-newsletter />
      </b-tab>
    </b-tabs>
  </core-sidebar>
</template>
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import CompanyInfo from '@/components/Company/CompanyInfo.vue';
import JobCompanyComponent from '@/components/Company/JobCompanyComponent.vue';
import CompanyNewsletter from '@/components/Company/CompanyNewsletter.vue';
import { CompanyModule } from '@/store/modules/company';

@Component({
  components: {
    JobCompanyComponent,
    CompanyNewsletter,
    CompanyInfo,
  },
})
export default class CompanySidebar extends Vue {
  get translations() {
    return AppModule.translation;
  }

  get languageSid() {
    return AppModule.languageSid;
  }

  get isAuth() {
    return UserModule.isUserAuth;
  }

  // TokenApplicant
  get accessTokenApplicant() {
    return UserModule.accessTokenApplicant;
  }

  // TokenConsultant
  get accessTokenConsultant() {
    return UserModule.accessTokenConsultant;
  }

  // TokenCompany
  get accessTokenCompany() {
    return UserModule.accessTokenCompany;
  }

  get isNewsletterVisible() {
    return this.accessTokenApplicant || !this.isAuth;
  }
}
</script>
<style lang="scss" scoped>
.content-color {
  color: rgb(var(--bs-primary-rgb));
}
</style>
