import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { calculateDaysBetweenTodayAndDate } from '@/helpers/dateHelpers';

@Component
export default class extends Vue {
  get translations() {
    return AppModule.translation;
  }

  datePeriodText(initialDate: string): string {
    const daysBetweenDates = calculateDaysBetweenTodayAndDate(initialDate);
    const weeksBetweenDates = Math.ceil(daysBetweenDates / 7);
    return weeksBetweenDates > 1 ? `${weeksBetweenDates} ${this.translations[13778]}` : `${daysBetweenDates} ${this.translations[13777]}`;
  }
}
