import HTTP from '@/plugins/axios';
import { Applicant } from './Workers';

export type NotepadJobs = {
  id: number
  position: string
  location: string
  lastdate: string
  company_name: string
  shortview: 1 | 0
}

export type NotepadCompany = {
  id: number
  company_id: number
  last_modified: string
  name: string
}

export type NotepadJobsResponse = {
  records: NotepadJobs[]
  count: number
}

export type NotepadApplicantsResponse = {
  records: Applicant[]
  count: number
}

export type NotepadCompaniesResponse = {
  records: NotepadCompany[]
  count: number
}

export type NotepadItemType = 'job' | 'applicant' | 'company'

export const getNotepadJobsItems = async(): Promise<NotepadJobsResponse> => {
  const response = await HTTP.get('/notepad/list?portal=1&type=job');
  return response.data;
};

export const getNotepadApplicantsItems = async(): Promise<NotepadApplicantsResponse> => {
  const response = await HTTP.get('/notepad/list?portal=1&type=applicant');
  return response.data;
};

export const getNotepadCompanyItems = async(): Promise<NotepadCompaniesResponse> => {
  const response = await HTTP.get('/notepad/list?portal=1&type=company');
  return response.data;
};

export const addNotepadItem = async(type: NotepadItemType, id: number) => {
  const response = await HTTP.post('/notepad', {
    id,
  }, {
    params: {
      type,
    },
  });
  return response.data;
};

export const removeNotepadItem = async(type: NotepadItemType, id: number) => {
  const response = await HTTP.delete('/notepad/delete', {
    params: {
      type,
      id,
    },
  });
  return response.data;
};

export const cleanNotepad = async(type: NotepadItemType) => {
  const response = await HTTP.delete('/notepad/clean', {
    params: {
      type,
    },
  });
  return response.data;
};
