<template>
  <b-card no-body class="information-box border-0 position-relative z-1" :class="[{ 'my-2 my-md-4': !isWelcome }, { 'mb-2 mb-md-3': isWelcome }]">
    <b-row no-gutters align-h="center" class="g-2">
      <b-col cols="12" :md="gridLaptopValue" v-for="(item, index) in clustersList" :key="`news-${index}`">
        <b-card no-body class="border-0 rounded-lg overflow-hidden h-100">
          <div class="d-flex flex-column h-100 w-100">
            <div
              class="information-box__text-wrapper d-flex flex-column flex-grow-1 p-3 pt-5"
              :style="{ 'background-image': `linear-gradient(0deg, rgba(0,0,0,0.8029412448573179) 0%, rgba(0,0,0,0) 50%), url(${SERVER_URL}${item.picture})` }"
            >
              <p class="display-1 mb-0 mt-auto text-center timer text-white pt-2">
                <ICountUp :delay="300" :endVal="item.count" :options="options" />
              </p>
              <p class="mb-0 text-center text-white lh-sm fs-5 fw-bolder">{{ categoryName }}</p>
            </div>
            <b-button variant="primary" class="w-100 fs-5 font-weight-bold rounded-0" @click="searchCluster(item.id, item.name)">
              {{ item.name }}
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ICountUp from 'vue-countup-v2';
import { SERVER_URL } from '@/config/constant';
import { AppModule } from '@/store/modules/app';

@Component({
  components: {
    ICountUp,
  },
})
export default class InformationBox extends Vue {
  @Prop({ type: Array }) clustersList!: any[];

  @Prop({ default: false }) private isWelcome!: boolean;

  @Prop({ required: true }) private categoryName!: string;

  SERVER_URL = SERVER_URL;

  options = {
    useEasing: true,
    useGrouping: true,
    separator: AppModule.language === 'en' ? ',' : '.',
    decimal: '.',
    prefix: '',
    suffix: '',
  };

  searchCluster(id: number, title: string) {
    this.$emit('search-cluster', { id, title });
  }

  get gridLaptopValue() {
    if (this.clustersList.length === 1) {
      return 12;
    }
    if (this.clustersList.length % 3 === 0) {
      return 4;
    }
    if (this.clustersList.length % 2 === 0) {
      return 6;
    }
    return 4;
  }
}
</script>
<style scoped lang="scss">
.timer.text-white {
  text-shadow: 1px 1px 9px rgba(0, 0, 0, 0.6);
}
.information-box__text-wrapper {
  background-position: top center;
  background-size: cover;
  min-height: 200px;

  @media (min-width: 768px) {
    min-height: auto;
  }
}
</style>
