import HTTP from '@/plugins/axios';

export const getPortalSetting = async(params: any) => {
  const response = await HTTP.get('/portal/settings', {
    params,
  });
  return response.data;
};
export const getPortalJobCount = async(params: any) => {
  const response = await HTTP.get('/portal/job_count', {
    params,
  });
  return response.data;
};
export const getSocialNetworkLink = async() => {
  const response = await HTTP.get('/consultant/social_network_link');
  return response.data;
};
