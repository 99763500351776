export interface ISetToken {
  access_token: string
  refresh_token: string
  exp: string
}

export interface IGroupToken {
  applicant?: ISetToken
  consultant?: ISetToken
  company?: ISetToken
}

export interface IServerToken {
  [index: string]: IGroupToken | null
}

export enum UserType {
  applicant,
  consultant,
  company,
}
